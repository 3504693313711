.healthcare__tabContainer{
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
    position: relative;
    height: fit-content;
}

.healthcare__PreBtn{
    position: absolute;
    left: -30px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #4E2AAD;
    color: #FFF;
    font-size: 22px;
    top: 50%;
}

.healthcare__nextBtn{
    position: absolute;
    right: -30px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #4E2AAD;
    color: #FFF;
    font-size: 22px;
    top: 50%;
}

.swiper-slide{
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.housing__tabButtons{
    margin: auto 0;
}

.healthcare__tabImgContent{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
}

.healthcare__tabImg{
    width: 75%;
    margin-top: 32px;
    gap: 104px;
}

@media only screen and (max-width: 768px) {
    .healthcare__tabImg{
        width: 75%;
        margin-top: 42px;
        gap: 54px;
    }
}

.react-tabs__tab{
    border: none;
    font-size: 17px;
    font-weight: 500;
    width: 213px;
    height: 72px;
    border: none;
    outline: none;
    padding: 10px 20px;
    text-align: center;
}

.active-tab {
    border-radius: 5px;
    background: #4E2AAD;    
    color: #FFF;
    font-size: 17px;
    font-weight: 500;
    width: 213px;
    height: 72px;
    border: none;
    outline: none;
    padding: 10px 20px;
    text-align: center;
}

.tab__list{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .react-tabs__tab, .active-tab{
        font-size: 13px;
        font-weight: 500;
        width: 171px;
        height: 62px;
        padding: 10px 16px;
    }
    .healthcare__PreBtn, .healthcare__nextBtn{
        width: 20px;
        height: 35px;
        font-size: 22px;
        display: grid;
        place-content: center;
        font-size: 14px;
    }
    .healthcare__PreBtn{
        left: -20px;
    }
    .healthcare__nextBtn{
        right: -20px;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {
    .react-tabs__tab, .active-tab{
        font-size: 13px;
        font-weight: 500;
        width: 181px;
        height: 62px;
        padding: 10px 16px;
    }
    .healthcare__PreBtn, .healthcare__nextBtn{
        width: 50px;
        height: 50px;
        font-size: 22px;
        display: grid;
        place-content: center;
        font-size: 18px;
    }
}


@media (min-width: 1280px) and (max-width:1535px) {
    .react-tabs__tab, .active-tab{
        font-size: 15px;
        font-weight: 500;
        width: 181px;
        height: 72px;    
    }
}