.pricing__bgImage{
  /* background-image: url(../../../public/images/pricingBannerBg.png); */
  width: 100%;
  background-size: 100% 100%;
  background-position: cover;
  background-position: center;
  margin-top: 68px;
}

.upiprincing__heading{
  color: #131319;
  font-size: 40px;
  font-weight: 500;
}

.upiprincing___heading{
  color: #131319;
  font-size: 22px;
  font-weight: 500;
}

.upiprincing___content{
  color: #131319;
  font-size: 18px;
  font-weight: 400;
}

@media only screen and (max-width: 640px) {
  .upiprincing__heading{
    font-size: 32px;
  }
}

@media (min-width: 640px) and (max-width: 768px) {
  .upiprincing__heading{
    font-size: 36px;
  }
}

.pricing__heading {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 56px;
    color: #e4e4e7;
    line-height: 60px;
  }
  .pricing__body_text {
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    color: #e4e4e7;
}
.body_pricing__heading1 {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 40px;
    color: #131319;
    line-height: 50px;
  }
  .body_pricing__heading2 {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 26px;
    color: #131319;
    line-height: 50px;
  }
  .pricing__body_text_yellow {
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    color: #1d2130;
}

.Yellow_purple_page {
    background: rgb(244,185,54);
background: linear-gradient(90deg, rgba(244,185,54,1) 50%, rgba(57,38,117,1) 50%);
}
@media all and (max-width:1023px) {
.Yellow_purple_page {background: rgb(244,185,54);
    background: linear-gradient(180deg, rgba(244,185,54,1) 50%, rgba(57,38,117,1) 50%);
}
}
.PricingImage-1 {
    width: 405px;
    height: 405px;
    object-fit: cover;
}
.yellow-page {
    background-color: #f4b936;
}
.Purple-page {
    background-color: #392675;
}
.profit-image {
    width: 500px;
}
@media all and (max-width:364px) {
    .yellow-page {
        @apply py-10
    }
}
@media all and (max-width:1900px) {
    .Banner-page {
        @apply pl-40
    }
   }@media all and (max-width:1730px) {
    .Banner-page {
        @apply pl-20
    }
   }
   @media all and (max-width:1570px) {
    .Banner-page {
        @apply pl-10
    }
   }
   @media all and (max-width:767px) {
       .purple-page {
        @apply pt-52
       }
   }
   @media all and (max-width:465px) {
    .purple-page {
     @apply pt-32
    }
}
   @media all and (max-width:1490px) {
    .pricing__pricing__heading {
        font-family: "Poppins", serif;
        font-weight:500;
        font-style: normal;
        font-size: 50px;
        color: #e4e4e7;
        line-height: 60px;
      }
   }
   @media all and (max-width:13400px) {
    .pricing__pricing__heading {
        font-family: "Poppins", serif;
        font-weight:500;
        font-style: normal;
        font-size: 45px;
        color: #e4e4e7;
        line-height: 60px;
      }
   }  @media all and (max-width:1213px) {
    .pricing__pricing__heading {
        font-family: "Poppins", serif;
        font-weight:500;
        font-style: normal;
        font-size: 40px;
        color: #e4e4e7;
        line-height: 50px;
      }
   }  @media all and (max-width:1087px) {
    .pricing__pricing__heading {
        font-family: "Poppins", serif;
        font-weight:500;
        font-style: normal;
        font-size: 38px;
        color: #e4e4e7;
        line-height: 48px;
      }
      .Banner-page {
        @apply pl-5
    }
   }
   @media all and (max-width:1023px) {
    .pricing__heading {
        font-family: "Poppins", serif;
        font-weight:500;
        font-style: normal;
        font-size: 56px;
        color: #e4e4e7;
        line-height: 56px;
      }
      .Banner-page {
        @apply pl-0 px-5
    }
}  @media all and (max-width:1023px) {
    .pricing__heading {
        font-family: "Poppins", serif;
        font-weight:500;
        font-style: normal;
        font-size: 50px;
        color: #e4e4e7;
        line-height: 56px;
      }
      .Banner-page {
        @apply pr-5 pl-5 
    }
    }
    @media all and (max-width:670px) {
        .pricing__heading {
            font-family: "Poppins", serif;
            font-weight:500;
            font-style: normal;
            font-size: 45px;
            color: #e4e4e7;
            line-height: 50px;
          }
          .Banner-page {
            @apply pr-5 pl-5 
        }
        }
 @media all and (max-width:607px) {
            .pricing__heading {
                font-family: "Poppins", serif;
                font-weight:500;
                font-style: normal;
                font-size: 40px;
                color: #e4e4e7;
                line-height: 50px;
              }
              .Banner-page {
                @apply pr-5 pl-5 
            }
            }
            @media all and (max-width:544px) {
                .pricing__heading {
                    font-family: "Poppins", serif;
                    font-weight:500;
                    font-style: normal;
                    font-size: 35px;
                    color: #e4e4e7;
                    line-height: 45px;
                  }
                  .Banner-page {
                    @apply pr-5 pl-5 
                }
                .body_pricing__heading1 {
                    font-family: "Poppins", serif;
                    font-weight:500;
                    font-style: normal;
                    font-size: 35px;
                    color: #131319;
                    line-height: 50px;
                  }
                  .body_pricing__heading2 {
                    font-family: "Poppins", serif;
                    font-weight:500;
                    font-style: normal;
                    font-size: 20px;
                    color: #131319;
                    line-height: 50px;
                  }
                }
                @media all and (max-width:450px) {
                    .body_pricing__heading1 {
                        font-family: "Poppins", serif;
                        font-weight:500;
                        font-style: normal;
                        font-size: 30px;
                        color: #131319;
                        line-height: 50px;
                      }
                      .pricing__body_text_yellow {
                        font-family: 'Lora', serif;
                        font-size: 14px;
                        font-weight: 400;
                        font-style: normal;
                        color: #1d2130;
                    }
                   }
                   @media all and (max-width:481px) {
                    .pricing__heading {
                        font-family: "Poppins", serif;
                        font-weight:500;
                        font-style: normal;
                        font-size: 30px;
                        color: #e4e4e7;
                        line-height: 40px;
                      }
                      .pricing__body_text {
                        font-family: 'Lora', serif;
                        font-size: 14px;
                        font-weight: 400;
                        font-style: normal;
                        color: #e4e4e7;
                    }
                   }