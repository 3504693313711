.career__bgImg{
    width: 100%;
    /* background: url(../../../public/images/CareerBanner__bgImg.png); */
    background-size: 100% 100%;
}

.careerMain__title{
    color: #000;
    font-size: 56px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.careerMain__para{
    color: #000;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Lora', serif;
}

.careerMain__img img{
    width: 500px;
    height: 500px;
    object-fit: cover;
}

/* Role section style */

.roles__heading{
    color: #1D2130;
    font-size: 56px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.role__content{
    border-radius: 8px;
    border: 0.2px solid #BCBCBC;
    background: #fff;
    height: 180px;
    padding: 0px 40px;
}

/* .role__contentArrow{
    width: 60px;
    height: 60px;
    background-color: #4E2AAD;
    border-radius: 50%;
} */

.MdOutlineArrowOutward__career{
    font-size: 24px;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
}

.role__contentRemote{
    color: #929292;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Lora', serif;
}

.role__name{
    color: #000;
    font-size: 32px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}


.role__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }

  .role__content:hover{
    background: #f0f0f0;
    transform: scale(1.05);
  }
  
  .role__contentArrow {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #FFF;
    border: 1px solid #d1cfcf;
    position: relative;
    overflow: hidden; 

  }

  .MdOutlineArrowOutward__career {
    color: #000;
    font-size: 18px;
    z-index: 2;
    transition: color 0.5s ease; /* Smooth color transition */
  }
  
  .role__contentArrow::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #4E2AAD;
    transition: top 0.5s ease;
    color: #fff;
    z-index: 1;
  }
  
  .role__contentArrow.hover::before {
    top: 0;
  }
  
  .role__contentArrow.hover .MdOutlineArrowOutward__career {
    color: #fff; 
  }

@media only screen and (max-width: 640px) {
  .careerMain__title, .roles__heading{
    font-size: 30px;
  }
  .careerMain__para{
    font-size: 16px;
  }
  .role__name{
    font-size: 20px;
  }
  .role__content{
    height: 150px;
    padding: 0px 20px;
  }
  .role__contentArrow{
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 640px) and (max-width: 768px) {
  .careerMain__title, .roles__heading{
    font-size: 40px;
  }
  .careerMain__para{
    font-size: 18px;
  }
  .role__name{
    font-size: 26px;
  }
  .role__content{
    height: 160px;
  }
  .role__contentArrow{
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .careerMain__title, .roles__heading{
    font-size: 46px;
  }
  .careerMain__para{
    font-size: 18px;
  }
  .role__name{
    font-size: 30px;
  }
  .role__content{
    height: 180px;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .role__name{
    font-size: 28px;
  }
}

@media only screen and (max-width: 320px) {
  .careerMain__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 768px) {
  .careerMain__img img{
    width: 300px;
    height: 300px;
    object-fit: cover;
  }
}
  

@media (min-width: 768px) and (max-width: 1280px) {
    .careerMain__img img{
      width: 400px;
      height: 400px;
      object-fit: cover;
    }
}
  
@media only screen and (max-width: 550px) {
    .jobroles__container{
      padding: 0px 20px;
    }
}
