.payment_gateway_bgImg {
  background: #140C2C;
  background-image: url("../../../public/images/Payment gateway background image 1.png");
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.haoda_every_industry_bgImg {
  background-image: url("../../../public/images/Payment gateway background image 2.png") ;
  height: 500px;
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Gateway_Solutions_bgImg {
  background-image: url('../../../public/images/Payment gateway background image 3.png');
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.Gateway_Solutions_bgImg::before {
    content: "";
    width: 100%;
    height: 100%;
    inset: 0;
    position: absolute;
    background-color: #140c2c80;
}

.deticated_support_Img {
  background-image: url('../../../public/images/Payment gateway background image 4 (2).png');
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.gateway__banner__heading {
  color: #FFF;
  font-family: 'Poppins', sans-serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 120%;
}

.gateway__banner__bgColor {
     background-color: #140C2C;
}

.gateway__banner {
    padding: 80px 114px;
    display: flex;
}

.Payment_Gateway_image {
  overflow: hidden;
  border-radius: 14px;
  width: 505px;
  height: 505px;
}

.Payment_Gateway_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 1.6s ease-in-out;
  border-radius: 14px;
}

.Payment_Gateway_image img:hover {
  transition: 1.6s ease-in-out;
  transform:scale(1.2);
  cursor: pointer;
}

.gateway__banner__text {
  color: #FFF;
  font-family: 'Lora', serif;
  font-size: 18px;
  font-weight: 400;
}

@media (min-width:1280px) and (max-width:1535px) {
  .gateway__banner {
       padding: 80px 10px;
  }  
}

@media (min-width:1023px) and (max-width:1280px) {
  .gateway__banner {
       padding: 80px 10px;
  }
  .gateway__banner__heading {
    font-size: 40px;
  }  
  .Payment_Gateway_image {
    width: 450px;
    height: 450px;
  }
}

@media (min-width:768px) and (max-width:1023px) {
  .gateway__banner {
    padding: 80px 50px;
    display:block;
  }
}

@media (min-width:640px) and (max-width:768px) {
  .gateway__banner {
    padding: 80px 50px;
    display: block;
  }
  .gateway__banner__heading {
    font-size: 40px;
  }  
  .Payment_Gateway_image {
    width: 450px;
    height: 450px;
  }
}

@media all and (max-width:640px) {
  .gateway__banner {
    padding: 40px 10px;
    display: block;
  }
  .gateway__banner__heading {
    font-size: 32px;
  }  
  .Payment_Gateway_image {
    width: 405px;
    height: 405px;
  }
  .gateway__banner__text {
     font-size: 16px;
  }
  .Payment_Gateway_image {
    width: 300px;
    height: 300px;
  }
}


.register__btn {
  border-radius: 4px;
  background: #4E2AAD;
  box-shadow: 0px -2.6px 0px 0px #4E2AAD inset;
  display: flex;
  padding: 16px 40px;
  color: #FFF;
  text-align: center;
  font-family: 'Poppins',serif;
  font-size: 16px;
  font-weight: 600;
}

.payment_second_page {
  padding: 80px 114px;
  gap: 64px;
  display: grid;
}

.payments_effort_heading {
  color: #FFF;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 56px;
  font-weight: 500;
}

.payments_effort_subHeading {
  color: #FFF;
  font-family: 'Poppins',sans-serif;
  font-size: 22px;
  font-weight: 600;
}

.payments_effort_text {
  color: #FFF;
  font-family: 'Lora',serif;
  font-size: 16px;
  font-weight: 400;
}

.payments_effort_box {
  display: flex;
  /* width: 388px; */
  height: 268px;
  padding: 20px;
  flex-direction: column;
  gap: 24px;
  border-radius: 14px;
  background: rgba(68, 68, 68, 0.30);
  backdrop-filter: blur(10px);
}

@media (min-width:1280px) and (max-width:1535px) {
  .payment_second_page {
    padding: 80px 50px;
    
  }
}

@media (min-width:1023px) and (max-width:1280px) {
  .payment_second_page {
    padding: 80px 10px;
  }
  .payments_effort_box {
    /* width: 320px; */
    height: 210px;
    gap: 18px;
  }
  .payments_effort_subHeading {
    font-size: 20px;
  }
  
  .payments_effort_text {
    font-size: 14px;
  }
  .payments_effort_heading {
     font-size: 40px;
  }
}

@media (min-width:768px) and (max-width:1023px) {
  .payment_second_page {
    padding: 80px 10px;
  }
  .payments_effort_box {
    display: flex;
    /* width: 360px; */
    height: 250px;
  }
  .payments_effort_heading {
    font-size: 40px;
 }
}

@media (min-width:640px) and (max-width:768px) {
  .payment_second_page {
    padding: 80px 10px;
  }
  .payments_effort_box {
    /* width: 300px; */
    height: 230px;
    gap: 10px;
    padding: 10px;
  }
  .payments_effort_subHeading {
    font-size: 20px;
  }
  .payments_effort_text {
    font-size: 14px;
  }
  .payments_effort_heading {
    font-size: 30px;
  }
}

@media all and (max-width:640px) {
  .payment_second_page {
    padding: 80px 80px;
  }
  .payments_effort_box {
    width: 100%;
    height: fit-content;
    gap: 10px;
    padding: 20px;
  }
  .payments_effort_subHeading {
    font-size: 20px;
  }
  .payments_effort_text {
    font-size: 14px;
  }
  .payments_effort_heading {
    font-size: 30px;
  }
}

@media all and (max-width:470px) {
  .payment_second_page {
    padding: 60px 20px;
  }
  .payments_effort_box {
    width: 100%;
    height: fit-content;
    gap: 10px;
    padding: 20px 32px;
  }
  .payments_effort_subHeading {
    font-size: 18px;
  }
  .payments_effort_text {
    font-size: 14px;
  }
}


.haoda_every_industry_bgColor {
  background: 100px 100px repeat, #000;
  background-blend-mode: lighten, normal;
}

.haoda_every_industry_page {
  display: flex;
  width: 100%;
  height: 500px;
  padding: 80px 124px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.haoda_every_industry_heading {
  color: #FFF;
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  font-weight: 500;
}

.haoda_every_industry_text {
  color: #FFF;
  text-align: center;
  font-family: 'Lora';
  font-size: 16px;
  font-weight: 400;

}

@media (min-width:640px) and (max-width:768px) {
  .haoda_every_industry_page {
       padding: 80px 20px;
  }
  .haoda_every_industry_heading {
      font-size: 30px;
  }
}

@media all and (max-width:640px) {
  .haoda_every_industry_bgImg {
     height: 380px;
     display: flex;
     justify-content: center;
     align-items: center;
  }
  .haoda_every_industry_page {
       padding: 20px;
  }
  .haoda_every_industry_heading {
      font-size: 26px;
  }
}


/* .Gateway_Solutions_bgImg {
  background: #140C2C;
} */

.Gateway_Solutions_heading {
  color: #FFF;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 56px;
  font-weight: 500;
}

.Gateway_Solutions_box {
  display: flex;
  width: 408px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 14px;
  background: rgba(68, 68, 68, 0.30);
  backdrop-filter: blur(10px);
}

.Gateway_Solutions_subHeading {
  color: #FFF;
  font-family: 'Poppins',sans-serif;
  font-size: 22px;
  font-weight: 600;
}

.Gateway_Solutions_text {
  color: #FFF;
  font-family: 'Lora',serif;
  font-size: 16px;
  font-weight: 400;
}

.Gateway_Solutions_page {
  padding: 80px 114px;
  display: grid;
  gap: 64px;
  position: relative;
}

@media (min-width:1280px) and (max-width:1535px) {
  .Gateway_Solutions_page {
    padding: 80px 20px;
  }
  .Gateway_Solutions_box {
    width: 390px;
  }
}

@media (min-width:1024px) and (max-width:1280px) {
  .Gateway_Solutions_page {
    padding: 80px 10px;
  }
  .Gateway_Solutions_box {
    display: flex;
    width: 300px;
    padding: 20px;
    gap: 10px;
  }
  .Gateway_Solutions_subHeading {
    font-size: 20px;
  }
  
  .Gateway_Solutions_text {
    font-size: 14px;
  }
  .Gateway_Solutions_heading {
    font-size: 40px;
  }
}

@media (min-width:768px) and (max-width:1024px) {
  .Gateway_Solutions_page {
    padding: 80px 10px;
  }
  .Gateway_Solutions_box {
    display: flex;
    width: 360px;
    padding: 20px;
    gap: 10px;
  }
  .Gateway_Solutions_subHeading {
    font-size: 20px;
  }
  
  .Gateway_Solutions_text {
    font-size: 14px;
  }
  .Gateway_Solutions_heading {
    font-size: 40px;
  }
}

@media (min-width:640px) and (max-width:768px) {
  .Gateway_Solutions_page {
    padding: 40px 20px;
  }
  .Gateway_Solutions_box {
    display: flex;
    width: 280px;
    padding: 20px;
    gap: 10px;
  }
  .Gateway_Solutions_subHeading {
    font-size: 18px;
  }
  
  .Gateway_Solutions_text {
    font-size: 12px;
  }
  .Gateway_Solutions_heading {
    font-size: 40px;
  }
  
}

@media (min-width:470px) and (max-width:640px) {
  .Gateway_Solutions_heading {
    font-size: 32px;
  }
  .Gateway_Solutions_page {
    padding: 40px 80px;
  }
  .Gateway_Solutions_box {
    width: 100%;
    gap: 10px;
    padding-left: 10px;
    padding: 20px;
  }
}

@media all and (max-width:470px) {
  .Gateway_Solutions_heading {
    font-size: 32px;
  }
  .Gateway_Solutions_page {
    padding: 40px 20px;
  }
  .Gateway_Solutions_box {
    width: 100%;
    height: fit-content;
    gap: 10px;
    padding-left: 10px;
    padding: 20px;
  }
  .Gateway_Solutions_subHeading {
    font-size: 18px;
  }
  
  .Gateway_Solutions_text {
    font-size: 14px;
  }
}

.why_choose_gateway_bgColor {
  background: #D2E3E5;
}

.why_choose_gateway_page {
  padding: 80px 124px;
  gap: 40px;
  display: grid;
}

.why_choose_gateway_box {
  width: 581px;
  height: 260px;
  border-radius: 22px;
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  padding-left: 40px;
  padding-right: 26px ;
}

.why_choose_gateway_heading {
  color: #131319;
  text-align: center;
  font-family: 'Poppins',sans-serif;
  font-size: 48px;
  font-weight: 500;
}

.why_choose_gateway_subHeading {
  color: #131319;
  font-family: 'Poppins',sans-serif;
  font-size: 22px;
  font-weight: 500;
}

.why_choose_gateway_text {
  color: #131319;
  font-family: 'Lora',serif;
  font-size: 15px;
  font-weight: 400;
}

.why_choose_gateway_svg svg{
    width: 200px;
    height: 200px;
    /* opacity: 30%; */
    filter: blur(0.5px);
}

@media (min-width:1280px) and (max-width:1438px) {
  .why_choose_gateway_box {
    width: 500px;
    height: 260px;
  }
  .why_choose_gateway_subHeading {
    font-size: 20px;
  }
}

@media (min-width:1024px) and (max-width:1280px) {
  .why_choose_gateway_box {
    width: 480px;
    height: 260px;
  }
  .why_choose_gateway_subHeading {
    font-size: 18px;
  }
  .why_choose_gateway_page {
    padding: 80px 10px;
  }
  .why_choose_gateway_text {
    font-size: 12px;
  }
  .why_choose_gateway_heading {
    font-size: 40px;
    width: 80%;
  }
}

@media (min-width:832px) and (max-width:1024px) {
  .why_choose_gateway_box {
    width: 400px;
    height: 260px;
  }
  .why_choose_gateway_subHeading {
    font-size: 18px;
  }
  .why_choose_gateway_page {
    padding: 80px 10px;
  }
  .why_choose_gateway_text {
    font-size: 12px;
  }
  .why_choose_gateway_heading {
    font-size: 40px;
    width: 80%;
  }
  .why_choose_gateway_svg svg{
    width: 100px;
    height: 150px;
}
}

@media (min-width:767px) and (max-width:832px) {
  .why_choose_gateway_box {
    width: 350px;
    height: 260px;
  }
  .why_choose_gateway_subHeading {
    font-size: 16px;
  }
  .why_choose_gateway_page {
    padding: 80px 10px;
  }
  .why_choose_gateway_text {
    font-size: 12px;
  }
  .why_choose_gateway_heading {
    font-size: 40px;
    width: 80%;
  }
  .why_choose_gateway_svg svg{
    width: 100px;
    height: 150px;
}
}

@media (min-width:640px) and (max-width:767px) {
  .why_choose_gateway_heading {
    font-size: 30px;
    width: 80%;
  }
  .why_choose_gateway_page {
    padding: 40px 80px;
  }
  .why_choose_gateway_box {
    width: 100%;
    height: fit-content;
    padding: 20px;
    padding-right: 0px ;
  }
  
}

@media (min-width:470px) and (max-width:640px) {
  .why_choose_gateway_heading {
    font-size: 26px;
    width: 100%;
  }
  .why_choose_gateway_page {
    padding: 40px 80px;
  }
  .why_choose_gateway_box {
    width: 100%;
    height: fit-content;
    padding: 20px 32px;
  }
  .why_choose_gateway_subHeading {
    font-size: 15px;
  }
  .why_choose_gateway_text {
    font-size: 14px;
  }
  .why_choose_gateway_svg svg{
    width: 100px;
    height: 150px;
}
}

@media all and (max-width:470px) {
  .why_choose_gateway_page {
    padding: 40px 20px;
  }
  .why_choose_gateway_subHeading {
    font-size: 15px;
  }
  .why_choose_gateway_text {
    font-size: 14px;
  }
  .why_choose_gateway_heading {
    font-size: 26px;
    width: 100%;
  }
  .why_choose_gateway_box {
    width: 100%;
    height: fit-content;
    padding: 20px;
    padding-right: 10px ;
  }
  .why_choose_gateway_svg svg{
    width: 100px;
    height: 150px;
}
}

.deticated_support_heading {
  color: #FFF;
  text-align: center;
  font-family: 'Poppins',sans-serif;
  font-size: 48px;
  font-weight: 500;
}

.deticated_support_text {
  color: #FFF;
  text-align: center;
  font-family: 'Lora',serif;
  font-size: 16px;
  font-weight: 400;
}

.deticated_support_page {
  display: grid;
  padding: 80px 114px;
  gap: 40px;
}

@media (min-width:640px) and (max-width:768px) {
  .deticated_support_page {
    padding: 80px 50px;
  }
}

@media only screen and (max-width:640px) {
  .deticated_support_page {
    padding: 80px 20px;
  }
  .deticated_support_heading {
    font-size: 30px;
  }
  .deticated_support_text {
     font-size: 14px;
  }
}



/* next-step */

.ready__title{
  color: #131319;
  font-size: 48px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.ready__titleNext{
  color: #392675;
  font-size: 48px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.ready__titleStep{
  color: #F4B936;
  font-size: 48px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.ready__para{
  color: #131319;
  font-size: 22px;
  font-weight: 400;
  font-family: "Lora", serif;
}

.ready__btn button{
  border-radius: 4px;
  background: #4E2AAD;
  color: #FFF;
  font-size: 25px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  font-family: "Lora", serif;
}

.register__btn1 button{
  border-radius: 4px;
  background: #4E2AAD;
  padding: 16px 40px;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  font-family: "poppins", sans-serif;
}

.next__arrow1{
  display: block;
  font-size: 18px;
  position: absolute;
}

@media only screen and (max-width:640px) {
  .register__btn1 button{
    padding: 10px 30px;
    font-size: 16px;
  }
  .next__arrow1{
    display: block;
    font-size: 15px;
    position: absolute;
  }
}

.register__btn1 button:hover {
     box-shadow: 0px -2.6px 0px 0px #210077 inset; 
}

.ready__btn button:hover{
  box-shadow: 0px -2.6px 0px 0px #210077 inset; 
}

.ready__container{
  background-image: url(../../../public/images/reachout__img.png);
  width: 100%;
  height: 370px;
  background-size: 100% 100%;
  background-position: center;
}

.arrow__container{
  width: 30px;
  height: 28px;
  position: relative;
  overflow: hidden;
}

.arrow__container1{
  width: 20px;
  height: 28px;
  position: relative;
  overflow: hidden;
}

.arrow__back{
  width: 20px;
  display: block;
  font-size: 22px;
}

.next__arrow{
  display: block;
  font-size: 18px;
  position: absolute;
}


.register__btn1 button:hover .next__arrow1{
  animation: moveArrow 0.6s ease;
}

.ready__btn button:hover .next__arrow{
  animation: moveArrow 0.6s ease;
}

@keyframes moveArrow {
  0%{
      transform: translateX(0px);
  }
  30%{
     transform: translateX(20px);
     display: none;
  }
  60%{
      transform: translateX(-15px);
      display: none;
  }
  100%{
      transform: translateX(0px);
  }
}

@media only screen and (max-width: 420px) {
  .ready__container{
      height: 400px;
  }
  .next__arrow{
      font-size: 18px;
  }
  .ready__para{
      font-size: 18px;
  }
}

@media only screen and (max-width: 640px) {
  .ready__btn button{
      border-radius: 4px;
      background: #4E2AAD;
      color: #FFF;
      font-size: 18px;
      font-weight: 600;
  }
  .next__arrow{
      font-size: 18px;
  }
  .ready__para{
      font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .ready__title, .ready__titleNext, .ready__titleStep{
      font-size: 32px;
  }
  .ready__para{
      font-size: 16px;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .ready__title, .ready__titleNext, .ready__titleStep{
      font-size: 40px;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .ready__title, .ready__titleNext, .ready__titleStep{
      font-size: 46px;
  }
}

/* footer */

.footer_bgColor1 {
  background: #140C2C;
}

.footer__title1{
  color: #FFF;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.footer__content1 {
     text-align: left;
}

.footer_para1 {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Lora', serif;
  opacity: 0.8;
}

.footer__link1{
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Lora', serif;
  opacity: 0.8;
}

.footer_subheading1 {
  color: #FFF;
  font-family: 'Poppins',sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.footer__link1:hover{
  width: fit-content;
  text-decoration: underline;
  text-decoration-color: #FFFFFF;
}

.footer__email1{
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background: #F7F7F7;
}

.footer__email1 input{
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}

.footer__btn1{
  width: 160px;
  height: 40px;
  border-radius: 4px;
  background: #4E2AAD;
  box-shadow: 0px -2.4px 0px 0px #210077 inset;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
}

.footer__logo1 img{
  width: 184px;
  height: 35px;
  object-fit: cover;
}

.footer__line1{
  width: 30%;
  height: 1px;
  background: #FFFFFF;
}

.footerRights1 {
  color: #FFFFFF;
  font-family: 'Roboto',sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.email_input1 {
  border-radius: 8px;
  background: #F7F7F7;
  width: 290px;
  height: 40px;
  outline: none;
}

.dark_footer_page {
  padding: 54px 80px;
}

.subscribe_btn1 {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #4E2AAD;
  height: 40px;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 10;
  color: #FFF;
  font-family: 'Poppins',sans-serif;
  font-size: 16px;
  right: 20px;
}

.subscribe_btn1:hover {
  box-shadow: 0px -2.4px 0px 0px #210077 inset;
}

@media (min-width: 640px) and (max-width: 1024px) {
  .subscribe_btn1    {
    padding: 16px 20px;
    right: 0px;
  }
}

@media all and (max-width: 640px) {
  .subscribe_btn1   {
    padding: 16px 20px;
    right: 0px;
  }
}

.secureImg1 img{
  width: 220px;
  height: 54px;
  object-fit: cover;
}

.secureImg__container1{
  border-top: 1px solid #787474;
  display: flex;
  gap: 20px;
}



@media (min-width: 1280px) and (max-width: 1535px) {
  .footer__line1{
      width: 15%;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .footer__line1{
      width: 15%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .footer__line1{
      width: 17%;
  }
}

@media (min-width: 640px) and (max-width: 768px) {
  .footer__line1{
      width: 12%;
  }
  .footerRights1{
      font-size: 12px;
  }
}

@media (min-width: 550px) and (max-width: 640px) {
  .footer__line1{
      width: 12%;
  }
  .footerRights1{
      font-size: 10px;
  }
}

@media only screen and (max-width:550px) {
  .footer__line1{
      width: 0%;
  }
  .footerRights1{
      font-size: 12px;
  }
}
/* navbar */

.payColor {
   color: #F4B936;
}



.headerMain__navcontainers1{
  position: fixed;
  top: 0;
  z-index: 5;
  background: #140C2C;
}

.haodaPay__logo1 img{
  width: 202px;
  height: 78px;
  object-fit: contain;
}

.navLink__name1{
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  line-height: 60px;    
}

.navLink__subName1{
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.free__btn1 button{
  border-radius: 4px;
  background: #4E2AAD;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
}

.free__btn1 button:hover{
  box-shadow: 0px -2.6px 0px 0px #210077 inset;
}

.free__btn1 button:hover .headerBannerNav__arrow1{
  animation: moveArrow 0.6s ease;
}

.headerBannerNav__arrow1{
  font-size: 12px;
  margin-top: 2px;
}

.headerBanner__arrowNav1{
  width: 20px;
  overflow: hidden;
}

@keyframes moveArrow {
  0%{
      transform: translateX(0px);
  }
  30%{
     transform: translateX(20px);
     display: none;
  }
  60%{
      transform: translateX(-15px);
      display: none;
  }
  100%{
      transform: translateX(0px);
  }
}

.navLink__name1 {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s all ease;
  position: relative;
}

.navLinks__name1{
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s all ease;
  position: relative;
}

.navLinks__name1::before{
  content: "";
  position: absolute;
  pointer-events: none;
  width: 0%;
  height: 3px;
  top: 105%;
  background-color: #F4B936;
  border-radius: 8px;
  transition: all 0.5s ease-in-out;
}

.navLinks__name1:hover::before{
  content: "";
  position: absolute;
  pointer-events: none;
  width: 70%;
  transform: translateX(50%);
  transition: all 0.5s ease-in-out;
}

.dropDown1{
  width: max-content;
  height: fit-content;
  background: rgba(255, 255, 255, 0.30);
  backdrop-filter: blur(30px);
  color: #FFF;
  border-radius: 20px;
  position: absolute;
  top: 100%;
  left: 0;
  transition: width 0.3s ease, height 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: none;
  z-index: 4;
}

.navLink__name1:hover .dropDown1{
  opacity: 1;
  display: block;
}

.navLink__product1{
  padding: 0px 12px 0px 12px;
  line-height: 60px;
}

.navLink__product1:hover{
  background-color: #FFF;
  border-radius: 6px;
}

/* MINI HEADER NAV SECTION */

/* .navLink__name1{
  position: relative;
  color: #FFF;
} */

.navLink__Mininame1:hover {
opacity: 1;
visibility: visible;
}

.navMenu__list1 ul{
  list-style: none;
}


.navHeader__menuBtn1{
  display: none;
}

.navLink__name1:hover .dropDown1{
  opacity: 1;
  display: block;
  color: #131319;
}


@media only screen and (max-width: 701px)  {
  .haodaPay__logo1 img{
      width: 160px;
      height: 68px;
      object-fit: contain;
  }
}

@media (min-width: 700px) and (max-width: 800px) {
  .haodaPay__logo1 img{
      width: 140px;
      height: 68px;
      object-fit: contain;
  }

}

@media (min-width: 801px) and (max-width: 1023px) {
  .haodaPay__logo1 img{
      width: 160px;
      height: 68px;
      object-fit: contain;
  }

}

@media (min-width: 767px) and (max-width: 910px) {
  .free__btn1 button{
      height: 40px;
      font-size: 14px;
  }
}  

@media only screen and (max-width: 600px) {
  .navLink__Mininame1{
      font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .navMenu__list1, .navHeader__btn1{
      display: none;
  }
}

.product__name__color1 {
  color: #FFFFFF;
}

.mobileNav__container1{
  color: #FFFFFF;
  width: 300px;
  position: fixed;
  top: 0;
  right: -300px; 
}

@media only screen and (max-width: 768px) {
  
  .mobileNav__container1 {
      width: 300px;
      min-height: min-content;
      overflow-y: auto;
      height: 100vh;
      position: fixed;
      top: 0;
      right: -300px; 
      background-color: #140C2C ;
      color:  #FFFFFF;
      z-index: 15;
      transition: right 0.5s ease-in-out; 
  }

  .navHeader__menuBtn1{
      display: inline-block;
      color: #FFFFFF;
  }
  
  .mobileNav__container1.open {
      right: 0; 
  }

  .mobileNav__close1{
      width: 24px;
      height: 24px;
      border-radius: 6px;
      color: #ECEAFD;
      font-size: 18px;
      cursor: pointer;
  }

  .navLink__MobileContainer1{
      list-style: none;
  }

  .navLink__Mobilename1{
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 500;  
      position: relative;
      width: fit-content;
  }

  .navLink__Mobilena1{
      color: #131319;
      font-size: 16px;
      font-weight: 500;  
  }

  .navLink__Mobilename1::before{
      content: "";
      position: absolute;
      pointer-events: none;
      width: 0%;
      height: 3px;
      top: 110%;
      background-color: #210077;
      border-radius: 8px;
      transition: all 0.5s ease-in-out;
  }

  .navLink__Mobilename1:hover::before{
      content: "";
      position: absolute;
      pointer-events: none;
      width: 70%;
      transform: translateX(50%);
      transition: all 0.5s ease-in-out;
  }

  .navLink__MobileBtn1 button{
      width: 100%;
      height: 42px;
      border-radius: 4px;
      outline: none;
      background-color: #4E2AAD;
      color: #FFF;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1px;
  }

  .mobileNav__subMenu1{
      list-style: none;
  }
  
  .mobileNav__subMenu1 li{
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 500;  
  }

  .mobileNav__subMenu1{
      display: none;
      transition: all 0.5s cubic-bezier(0,1,0,1);
  }

  .mobileNav__subMenu1.showNav{
    color: #FFFFFF;
      display: block;
      transition: all 0.5s cubic-bezier(0,1,0,1);
  }

  .arrowDown__nav1{
      transform: scale(0deg);
  }

  .arrowDown__navRoto1{
      transform: rotate(90deg);
  }
}