@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.Payment_banner_bgImage {
  /* background-image: url(../../../public/images/term__bannerImg.png); */
  width: 100%;
  background-size: 100% 100%;
  background-position: cover;
}

.recurring__bannerBg{
  position: relative;
  width: 100%;
  height: 650px;
}

.Payment_body_bgImage {
  background-image: url("../../../public/images/Recurring\ payments\ image\ 1\ \(1\).jpg");
  width: 100%;
  background-size: 100% 100%;
  background-position: cover;
  height: 600px;
  position: relative;
}

.Payment_banner_heading  {
  font-family: 'Poppins';
  font-size: 56px;
  font-weight: 500;
  line-height: 70px;
  color: #fff;
}

.Payment_para_text {
  font-family: "Lora", serif;
  font-size: 18px;
  font-weight: 400;
  margin-top: 32px;
  margin-bottom: 32px;
  color: #fff;
}

.Payment_banner_pageTop {
   padding-top: 80px;
   padding-bottom: 80px;
   padding-left: 124px;
   padding-right: 124px;
   display: flex;
}

@media (min-width: 1023px) and (max-width: 1280px) {
  .recurring__bannerBg{
      position: relative;
      width: 100%;
      height: 530px;
  }
}


@media (min-width: 767px) and (max-width: 1023px) {
  .recurring__bannerBg{
      position: relative;
      width: 100%;
      height: 880px;
  }
}
@media  (min-width: 640px) and (max-width: 767px) {
  .recurring__bannerBg{
      position: relative;
      height: 780px;
  }
  
}

@media  (min-width: 430px)  and (max-width: 640px) {
  .recurring__bannerBg{
      position: relative;
      height: 880px;
  }
}

@media  only screen  and (max-width: 430px) {
  .recurring__bannerBg{
      position: relative;
      height: 900px;
  }
}

.Payment_banner_page {
   display: flex;
   align-items: center;
   margin-right: 90px;
}

.payment_Banner_image {
  width: 405px;
  display: flex;
  align-items: center;
}

.payment_bannerBtn {
  font-family: "Poppins", serif;
  font-weight:500;
  font-size: 16px;
  color: #e4e4e7;
  border-radius:  4px;
  background: #4E2AAD;
  padding: 10px 20px;
}

.payment_bannerBtn:hover{
    box-shadow: 0px -2.6px 0px 0px #4E2AAD inset;
}


.first_box {
  margin-right: 15px;
}

.second_box {
 margin-left: 15px;
}

.Payment_model {
padding-top: 30px;
padding-bottom: 30px;
padding-left: 60px;
padding-right: 80px;
}

.payments_box {
  width: 500px;
  border-radius: var(4px);
background: #F2F2F2;
margin-top: 64px;
}

.Payment_2box_heading {
  color: #050B20;
font-family: 'Poppins', sans-serif;
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: 50px; /* 150% */
text-transform: capitalize;

}


.Payment_2boxs_text {
color: #050B20;
font-size: 15px;
font-style: normal;
font-family: 'Lora', serif;
font-weight: 400;
margin-top: 14px; /* 185% */
}

.Payment_body_heading1 {
  color: #131319;
text-align: center;
font-family: 'Poppins', sans-serif;
font-size: 40px;
font-style: normal;
font-weight: 500;
line-height: 110%; /* 44px */
width: 842px;
}

.payment_business_heading {
  color: #131319;
text-align: center;
font-family: 'Poppins' serif;
font-size: 48px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 57.6px */
width: 1188px;
margin-bottom: 80px;

}

.payment_logo {
  width: 56px;
height: 56px;
}

.payment_5box_heading {
  font-family: "Poppins", serif;
  font-weight:500;
  font-style: normal;
  font-size: 22px;
  color: #131319;
  padding-top: 16px;
  padding-bottom: 16px;
}

.Logo_box {
  width: 450px;
  padding: 50px;
 }

 .Payment_5para_text {
  font-family: "Lora", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.payment_4box_img{
  width: 260px;
  height: 198px;
  overflow: hidden;
  border-radius: 12px;
  cursor: pointer;
}

.payment_4box_img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.6s ease-in-out;
  border-radius: 12px;
}

.payment_4box_img img:hover{
  transform: scale(1.1);
  transition: 0.6s ease-in-out;
}


.Payment_4box_heading {
  font-family: "Poppins", serif;
  font-weight:500;
  font-style: normal;
  font-size: 22px;
  color: #131319;
}

.benefit_card_Oppertunity {
  background-color: #f2edff;
  width: 300px;
  height: 400px;
  box-shadow: 4px 6px 13px 0px rgba(215, 215, 215, 0.25);
}

.benefit_card_integration {
  background-color: #ffedf8;
  width: 300px;
  height: 400px;
  box-shadow: 4px 6px 13px 0px rgba(215, 215, 215, 0.25);
}

.benefit_card_solutions {
  background-color: #edf6ff;
  width: 300px;
  height: 400px;
  box-shadow: 4px 6px 13px 0px rgba(215, 215, 215, 0.25);
}

.benefit_card_support {
  background-color: #f3ffed;
  width: 300px;
  height: 400px;
  box-shadow: 4px 6px 13px 0px rgba(215, 215, 215, 0.25);
}

.question_box {
  width: 600px;
  border-radius: 14px;
border: 1px solid #EAEAEA;
background: linear-gradient(180deg, #FFF 0%, #FFF 100%);
}

.Question_btn {
  font-family: "Poppins", serif;
  font-weight:500;
  font-style: normal;
  font-size: 22px;
  color: #e4e4e7;
  border-radius: var(--radius-card-radius, 4px);
  background: #4E2AAD;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.yellow_box {
  display: inline-flex;
transform: rotate(-12.756deg);
padding: 20px 30px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 12px;
background: #FFE59C;
color: #050B20;
font-family: 'Lora';
font-size: 20px;
font-style: normal;
font-weight: 500;
position: absolute;
top: 38%;
left: 15%;



}
.green_box {
  display: inline-flex;
transform: rotate(3.167deg);
padding: 20px 30px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 12px;
background: #99FF90;
color: #050B20;
font-family: 'Lora';
font-size: 20px;
font-style: normal;
font-weight: 500;
position: absolute;
top: 65%;
left: 35%;
}
.blue_box{
  display: inline-flex;
transform: rotate(2.068deg);
padding: 20px 30px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 12px;
background: #81FFF9;
color: #050B20;
font-family: 'Lora';
font-size: 20px;
font-style: normal;
font-weight: 500;
position: absolute;
top: 39%;
left: 54%;
}
.pink_box {
  display: inline-flex;
transform: rotate(-8.128deg);
padding: 20px 30px;
justify-content: center;
align-items: center;
gap: 10px;
display: inline-flex;
transform: rotate(-8.128deg);
padding: 20px 30px;
justify-content: center;
align-items: center;
gap: 10px;
color: #050B20;
border-radius: 12px;
background: #FA96FF;
font-family: 'Lora';
font-size: 20px;
font-style: normal;
font-weight: 500;
position: absolute;
top: 59%;
left: 65%;
}
.orange_box {
  display: inline-flex;
transform: rotate(2.742deg);
padding: 20px 30px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: var(12px);
background: #FF9294;
color: #050B20;
font-family: 'Lora';
font-size: 20px;
font-style: normal;
font-weight: 500;
position: absolute;
top: 41%;
left: 78%;
border-radius: 12px;
}

.payment_question_subHeading {
  font-family: "Poppins", serif;
  font-weight:500;
  font-style: normal;
  font-size: 16px;
  color: #131319;
}

.Payment_qsText_text {
    font-family: "Lora", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
}

.Payment_4box_text {
   font-family: "Lora", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.payment_4box_bigHeading {
  color: #131319;
text-align: center;
font-family: 'Poppins';
font-size: 40px;
font-style: normal;
font-weight: 500;
line-height: 110%; 
}
.payment_question_heading {
  color: #131319;
font-family: 'Poppins';
font-size: 48px;
font-style: normal;
font-weight: 500;
line-height: 48px; 
letter-spacing: -1.6px;
margin-bottom: 50px;
}

.subscriptions {
  color: #050B20;
font-family: 'Poppins';
font-size: 32px;
font-style: normal;
font-weight: 500;
padding: 20px 30px;
gap: 10px;
background:  #FFF;
position: absolute;



}
.haoda_heading {
  color: #3B2974;
font-family: Poppins;
font-size: 32px;
font-style: normal;
font-weight: 500;
}
.pay_heading {
  color: #F4B936;
font-family: Poppins;
font-size: 32px;
font-style: normal;
font-weight: 500;

}

.payment_4box_img img:hover{
  transform: scale(1.1);
  transition: 0.6s ease-in-out;
}

@media only screen and (max-width: 640px) {
    .haoda_heading, .pay_heading{
        font-size: 16px;
    }
    .Payment_body_heading1 {
      font-size: 30px;
    }
}

.Haoda_pay_subscriptions {
  padding: 20px 30px;
  color: #050B20;
font-family: 'Poppins';
background: #FFF;
font-size: 32px;
font-style: normal;
font-weight: 500;
width: fit-content;
}

@media only screen and (max-width: 640px) {
   .Haoda_pay_subscriptions{
    font-size: 22px;
   } 
}

.Reacurring_payments_page {
  background-color: #F4F4F4;
}



@media all and (max-width:1535px) {
  
 .Payment_banner_pageTop {
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
}
}

@media all and (max-width:1279px) {
  
  .Payment_banner_pageTop {
   padding-top: 80px;
   padding-bottom: 80px;
   padding-left: 10px;
   padding-right: 10px;
   display: flex;
 }
 .Payment_banner_page {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.payment_Banner_image {
  width: 350px;
  display: flex;
  align-items: center;
}

.Payment_banner_heading  {
  font-family: Poppins;
font-size: 50px;
font-style: normal;
font-weight: 500;
line-height: 60px;
}
.Payment_para_text {
  font-family: 'Lora';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 25px;
}
 }

 @media all and (max-width:1030px) {
  .Payment_banner_pageTop {
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 20px;
    padding-right: 20px;
    display: block;
 }
 .Payment_banner_page {
  display: flex;
  align-items: center;
  margin-right: 0px;
}
 
 .Payment_banner_heading  {
  font-family: 'Poppins';
font-size: 56px;
font-style: normal;
font-weight: 500;
line-height: 70px;
text-align: center;
}

.Payment_para_text {
  font-family: 'Lora';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;
}
.payment_Banner_image {
  width: 405px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.Payment_para_text {
  @apply pr-0 px-5
}
 }
 
 @media all and (max-width:767px) {
  .Payment_banner_heading  {
    font-family: 'Poppins';
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  }
 }

 @media all and (max-width:589px) {
  .Payment_banner_heading  {
    font-family: 'Poppins';
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 55px;
  }
  .payment_Banner_image {
    width: 350px;
    display: flex;
    align-items: center;
  }
 }

 @media all and (max-width:483px) {
  .Payment_banner_heading  {
    font-family: 'Poppins';
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  }
 }

 @media all and (max-width:430px) {
  .Payment_banner_heading  {
    font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
  }
  .payment_Banner_image {
    width: 300px;
    display: flex;
    align-items: center;
  }
  .Payment_banner_pageTop {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    display: block;
 }
 }

 @media all and (max-width:377px) {
  .Payment_banner_heading  {
    font-family: 'Poppins';
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  
  }
  .payment_Banner_image {
    width: 300px;
    display: flex;
    align-items: center;
  }
  .Payment_banner_pageTop {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    display: block;
 }
 .payment_Banner_image {
  width: 260px;
  display: flex;
  align-items: center;
}
.payments_box {
  width: 500px;
  border-radius: 4px;
background: #F2F2F2;
margin-top: 30px;
}
.Payment_para_text {
  font-family: "Lora", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 15px;
}
 }

 @media all and (max-width:1080px) {
  .payments_box {
    width: 400px;
} 
}
@media all and (max-width:890px) {
  .payments_box {
    width: 350px;
} 
}

@media all and (max-width:770px) {
  .payments_box {
    width: 500px;
} 
  .payment_double_box {
    
    @apply flex flex-wrap
  }
  .second_box {
    margin-left: 0px;
   }
   .first_box {
    margin-right: 0px;
  }
   
}
@media all and (max-width:548px) {
  .payments_box {
    width: 400px;
} 

.Payment_body_heading {
  color: #131319;
text-align: center;
font-family: 'Poppins' serif;
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: 110%; /* 44px */
width: 842px;
}


}

@media all and (max-width:460px) {
  .payments_box {
    width: 350px;
} 
.Payment_model {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  }
  .Payment_2box_heading {
    color: #050B20;
  font-family: 'Poppins' serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px; /* 150% */
  text-transform: capitalize;
  
  }

  .Payment_2box_text {
    color: #050B20;
  font-family: 'Lora';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-top: 2px; /* 185% */
  }
}

@media all and (max-width:380px) {
  .Paayment_2box_heading {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 18px;
    color: #131319;
}

.Payment_2box_text {
  font-family: "Lora", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}
.payments_box {
  width: 300px;
} 
.Payment_model {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 0px;
  }
}

@media all and (max-width:1080px) {
  .Payment_model {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 50px;
    padding-right: 20px;
    }
}

@media all and (max-width:360px) {
  .Payment_body_heading {
    font-size: 26px;
  }
  .payment_business_heading {
    font-size: 26px;
  }
}

@media all and (max-width:360px) {
  .payment_business_heading {
    font-size: 20px;
  }
}

@media all and (max-width:1080px) {
  .Logo_box {
    width: 350px;
    padding: 30px;
   }

}

@media all and (max-width:767px) {
  .Logo_box {
    width: 300px;
    padding: 10px;
    margin-top: 50px;
   }

}

@media all and (max-width:599px) {
  .Logo_box {
    width: 450px;
    padding: 50px;
    margin-top: 10px;
   }
   .payment_logo {
      display: flex;
      justify-items: center;
      justify-self: center;
   }
   .payment_5box_heading {
    text-align: center;
   }
   .Payment_5para_text {
    text-align: center;
   }

}

@media all and (max-width:440px) {
  .Logo_box {
    width: 350px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
   }
   .payment_5box_heading {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 22px;
    color: #131319;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .payment_business_heading {
    color: #131319;
  text-align: center;
  font-family: 'Poppins';
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 57.6px */
  width: 1188px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  
  }
}
@media all and (max-width:380px) {
  .payment_business_heading {
    color: #131319;
  text-align: center;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 57.6px */
  width: 1188px;
  margin-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
  
  }
}



@media all and (max-width:370px) {
  .Logo_box {
    width: 300px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
   }
}

@media all and (max-width:1023px) {
  .payment_business_heading {
    color: #131319;
  text-align: center;
  font-family: 'Poppins';
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 57.6px */
  width: 1188px;
  margin-bottom: 80px;
  padding-left: 10px;
  padding-right: 10px;
  
  }
  
}

@media all and (max-width:653px) {
  .payment_business_heading {
    color: #131319;
  text-align: center;
  font-family: 'Poppins';
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 57.6px */
  width: 1188px;
  margin-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
  
  }
}

@media all and (max-width:420px)  {    
  .question_box {
    width: 300px;
  }
}

@media all and (max-width:660px)  {
     
  .question_box {
    width: 400px;
  }
}

@media all and (max-width:420px)  {
 
.question_box {
width: 300px;
 }
}
@media all and (max-width:672px) {
  .payment_4box_bigHeading {
    color: #131319;
  text-align: center;
  font-family: 'Poppins';
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; 
  }
  .payment_question_heading {
    color: #131319;
  font-family: 'Poppins';
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px; 
  letter-spacing: -1.6px;
  margin-bottom: 50px;
  }
}

@media all and (max-width:503px) {
  .payment_question_heading {
    color: #131319;
  font-family: 'Poppins';
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px; 
  letter-spacing: -1.6px;
  margin-bottom: 50px;
  }
}

@media all and (max-width:632px) {
  .card {
      @apply px-0
  }
  .benefit_card_Oppertunity {
      background-color: #f2edff;
      width: 300px;
      height: 380px;
  }
  
  .benefit_card_integration {
      background-color: #ffedf8;
      width: 300px;
      height: 380px;
  }
  
  .benefit_card_solutions {
      background-color: #edf6ff;
      width: 300px;
      height: 380px;
  }
  
  .benefit_card_support {
      background-color: #f3ffed;
      width: 300px;
      height: 380px;
  }
}

@media all and (max-width:390px) {
  .card {
      @apply px-0
  }
  .benefit_card_Oppertunity {
      background-color: #f2edff;
      width: 300px;
      height: 380px;
  }
  
  .benefit_card_integration {
      background-color: #ffedf8;
      width: 300px;
      height: 380px;
  }
  
  .benefit_card_solutions {
      background-color: #edf6ff;
      width: 300px;
      height: 380px;
  }
  
  .benefit_card_support {
      background-color: #f3ffed;
      width: 300px;
      height: 380px;
  }
}

@media all and (max-width:320px) {
  .card {
      @apply px-0
  }
  .benefit_card_Oppertunity {
      background-color: #f2edff;
      width: 270px;
      height: 400px;
  }
  
  .benefit_card_integration {
      background-color: #ffedf8;
      width: 270px;
      height: 400px;
  }
  
  .benefit_card_solutions {
      background-color: #edf6ff;
      width: 270px;
      height: 400px;
  }
  
  .benefit_card_support {
      background-color: #f3ffed;
      width: 270px;
      height: 400px;
  }
}

@media all and (max-width:1280px) {
  .card {
    @apply px-0
}
.benefit_card_Oppertunity {
    background-color: #f2edff;
    width: 270px;
    height: 400px;
}

.benefit_card_integration {
    background-color: #ffedf8;
    width: 270px;
    height: 400px;
}

.benefit_card_solutions {
    background-color: #edf6ff;
    width: 270px;
    height: 400px;
}

.benefit_card_support {
    background-color: #f3ffed;
    width: 270px;
    height: 400px;
}
.payment_4box_img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  transition: 0.6s ease-in-out;
}
}

@media all and (max-width:1148px) {
  .card {
    @apply px-0
}
.benefit_card_Oppertunity {
    background-color: #f2edff;
    width: 250px;
    height: 410px;
}

.benefit_card_integration {
    background-color: #ffedf8;
    width: 250px;
    height: 410px;
}

.benefit_card_solutions {
    background-color: #edf6ff;
    width: 250px;
    height: 410px;
}

.benefit_card_support {
    background-color: #f3ffed;
    width: 250px;
    height: 410px;
}
.payment_4box_img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  transition: 0.6s ease-in-out;
}
}

@media all and (max-width:1066px) {
  .card {
    @apply px-0
}
.benefit_card_Oppertunity {
    background-color: #f2edff;
    width: 230px;
    height: 360px;
}

.benefit_card_integration {
    background-color: #ffedf8;
    width: 230px;
    height: 360px;
}

.benefit_card_solutions {
    background-color: #edf6ff;
    width: 230px;
    height: 360px;
}

.benefit_card_support {
    background-color: #f3ffed;
    width: 230px;
    height: 360px;
}

.payment_4box_img{
  width: 260px;
  height: 150px;
  overflow: hidden;
  transition: 0.6s ease-in-out;
}

.payment_4box_img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  transition: 0.6s ease-in-out;
}

.Payment_4box_heading {
  font-family: "Poppins", serif;
  font-weight:500;
  font-style: normal;
  font-size: 16px;
  color: #131319;
}
.Payment_4box_text{
  font-size: 14px;
}
}

@media all and (max-width:1023px) {
  .benefit_card_Oppertunity {
    background-color: #f2edff;
    width: 300px;
    height: 380px;
}

.benefit_card_integration {
    background-color: #ffedf8;
    width: 300px;
    height: 380px;
}

.benefit_card_solutions {
    background-color: #edf6ff;
    width: 300px;
    height: 380px;
}

.benefit_card_support {
    background-color: #f3ffed;
    width: 300px;
    height: 380px;
}
.payment_4box_img{
  width: 260px;
  height: 198px;
}

.Payment_4box_heading {
  font-family: "Poppins", serif;
  font-weight:500;
  font-style: normal;
  font-size: 22px;
  color: #131319;
}
}

@media all and (max-width:1299px) {
  .yellow_box {
    display: inline-flex;
  transform: rotate(-12.756deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #FFE59C;
  color: #050B20;
  font-family: 'Lora';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 36%;
  left: 16%;
  
  
  
  }
  .green_box {
    display: inline-flex;
  transform: rotate(3.167deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #99FF90;
  color: #050B20;
  font-family: 'Lora';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 66%;
  left: 35%;
  }
  .blue_box{
    display: inline-flex;
  transform: rotate(2.068deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #81FFF9;
  color: #050B20;
  font-family: 'Lora';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 38%;
  left: 54%;
  }
  .pink_box {
    display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #050B20;
  border-radius: 12px;
  background: #FA96FF;
  font-family: 'Lora';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 60%;
  left: 65%;
  }
  .orange_box {
    display: inline-flex;
  transform: rotate(2.742deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(12px);
  background: #FF9294;
  color: #050B20;
  font-family: 'Lora';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 40%;
  left: 78%;
  border-radius: 12px;
  }
}

@media all and (max-width:1000px) {
  .yellow_box {
    display: inline-flex;
  transform: rotate(-12.756deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #FFE59C;
  color: #050B20;
  font-family: 'Lora';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 35%;
  left: 14%;
  
  
  
  }
  .green_box {
    display: inline-flex;
  transform: rotate(3.167deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #99FF90;
  color: #050B20;
  font-family: 'Lora';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 66%;
  left: 32%;
  }
  .blue_box{
    display: inline-flex;
  transform: rotate(2.068deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #81FFF9;
  color: #050B20;
  font-family: 'Lora';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 37%;
  left: 51%;
  }
  .pink_box {
    display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #050B20;
  border-radius: 12px;
  background: #FA96FF;
  font-family: 'Lora';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 60%;
  left: 61%;
  }
  .orange_box {
    display: inline-flex;
  transform: rotate(2.742deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(12px);
  background: #FF9294;
  color: #050B20;
  font-family: 'Lora';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 39%;
  left: 75%;
  border-radius: 12px;
  }
  .Payment_body_bgImage {
    background-image: url("../../../public/images/Recurring\ payments\ image\ 1\ \(1\).jpg");
    width: 100%;
    background-size: 100% 100%;
    background-position: center;
    height: 550px;
    position: relative;
  }
  .Haoda_pay_subscriptions {
    font-size: 28px;
  }
}

@media all and (max-width:854px) {
  .yellow_box {
    display: inline-flex;
  transform: rotate(-12.756deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #FFE59C;
  color: #050B20;
  font-family: 'Lora';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 34%;
  left: 12%;
  
  
  
  }
  .green_box {
    display: inline-flex;
  transform: rotate(3.167deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #99FF90;
  color: #050B20;
  font-family: 'Lora';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 66%;
  left: 32%;
  }
  .blue_box{
    display: inline-flex;
  transform: rotate(2.068deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #81FFF9;
  color: #050B20;
  font-family: 'Lora';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 36%;
  left: 51%;
  }
  .pink_box {
    display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #050B20;
  border-radius: 10px;
  background: #FA96FF;
  font-family: 'Lora';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 60%;
  left: 60%;
  }
  .orange_box {
    display: inline-flex;
  transform: rotate(2.742deg);
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #FF9294;
  color: #050B20;
  font-family: 'Lora';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 38%;
  left: 73%;
  border-radius: 12px;
  }
  .Payment_body_bgImage {
    background-image: url("../../../public/images/Recurring\ payments\ image\ 1\ \(1\).jpg");
    width: 100%;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
    position: relative;
  }
}

@media all and (max-width:854px) {
  .yellow_box {
    display: inline-flex;
  transform: rotate(-12.756deg);
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #FFE59C;
  color: #050B20;
  font-family: 'Lora';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 36%;
  left: 14%;
  
  
  
  }
  .green_box {
    display: inline-flex;
  transform: rotate(3.167deg);
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #99FF90;
  color: #050B20;
  font-family: 'Lora';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 66%;
  left: 33%;
  }
  .blue_box{
    display: inline-flex;
  transform: rotate(2.068deg);
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #81FFF9;
  color: #050B20;
  font-family: 'Lora';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 37%;
  left: 52%;
  }

.pink_box {
      display: inline-flex;
      transform: rotate(-8.128deg);
      padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #050B20;
  border-radius: 10px;
  background: #FA96FF;
  font-family: 'Lora';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 59%;
  left: 62%;
  }
  .orange_box {
    display: inline-flex;
  transform: rotate(2.742deg);
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #FF9294;
  color: #050B20;
  font-family: 'Lora';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 39%;
  left: 75%;
  border-radius: 12px;
  }
  .Payment_body_bgImage {
    background-image: url("../../../public/images/Recurring\ payments\ image\ 1\ \(1\).jpg");
    width: 100%;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
    height: 500px;
    position: relative;
  }
  .Haoda_pay_subscriptions {
    font-size: 24px;
  }
}

@media all and (max-width:694px) {
  .yellow_box {
    display: inline-flex;
  transform: rotate(-12.756deg);
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #FFE59C;
  color: #050B20;
  font-family: 'Lora';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 36%;
  left: 14%;
  
  
  
  }
  .green_box {
    display: inline-flex;
  transform: rotate(3.167deg);
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #99FF90;
  color: #050B20;
  font-family: 'Lora';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 66%;
  left: 33%;
  }
  .blue_box{
    display: inline-flex;
  transform: rotate(2.068deg);
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #81FFF9;
  color: #050B20;
  font-family: 'Lora';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 38%;
  left: 52%;
  }
  .pink_box {
    display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #050B20;
  border-radius: 10px;
  background: #FA96FF;
  font-family: 'Lora';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 59.5%;
  left: 61%;
  }
  .orange_box {
    display: inline-flex;
  transform: rotate(2.742deg);
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #FF9294;
  color: #050B20;
  font-family: 'Lora';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 39.5%;
  left: 75%;
  border-radius: 12px;
  }
  .Payment_body_bgImage {
    background-image: url("../../../public/images/Recurring\ payments\ image\ 1\ \(1\).jpg");
    width: 100%;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
    height: 460px;
    position: relative;
  }
  .Haoda_pay_subscriptions {
    font-size: 20px;
  }
}

@media all and (max-width:620px) {
  .yellow_box {
    display: inline-flex;
  transform: rotate(-12.756deg);
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #FFE59C;
  color: #050B20;
  font-family: 'Lora';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 35%;
  left: 13%;
  }
  .green_box {
    display: inline-flex;
  transform: rotate(3.167deg);
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #99FF90;
  color: #050B20;
  font-family: 'Lora';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 67%;
  left: 33%;
  }
  .blue_box{
    display: inline-flex;
  transform: rotate(2.068deg);
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #81FFF9;
  color: #050B20;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 37%;
  left: 52%;
  }
  .pink_box {
    display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #050B20;
  border-radius: 10px;
  background: #FA96FF;
  font-family: 'Lora';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 60%;
  left: 60%;
  }
  .orange_box {
    display: inline-flex;
  transform: rotate(2.742deg);
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #FF9294;
  color: #050B20;
  font-family: 'Lora';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 38.5%;
  left: 74%;
  border-radius: 12px;
  }
  .Payment_body_bgImage {
    background-image: url("../../../public/images/Recurring\ payments\ image\ 1\ \(1\).jpg");
    width: 100%;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
    height: 430px;
    position: relative;
  }
}

@media all and (max-width:530px) {
  .yellow_box {
    display: inline-flex;
  transform: rotate(-12.756deg);
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #FFE59C;
  color: #050B20;
  font-family: 'Lora';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 33%;
  left: 11%;
  }
  .green_box {
    display: inline-flex;
  transform: rotate(3.167deg);
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #99FF90;
  color: #050B20;
  font-family: 'Lora';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 66%;
  left: 31%;
  }
  .blue_box{
    display: inline-flex;
  transform: rotate(2.068deg);
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #81FFF9;
  color: #050B20;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 36%;
  left: 52%;
  }
  .pink_box {
    display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #050B20;
  border-radius: 10px;
  background: #FA96FF;
  font-family: 'Lora';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 60%;
  left: 58%;
  }
  .orange_box {
    display: inline-flex;
  transform: rotate(2.742deg);
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #FF9294;
  color: #050B20;
  font-family: 'Lora';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 37.5%;
  left: 73%;
  border-radius: 12px;
  }
  .Payment_body_bgImage {
    background-image: url("../../../public/images/Recurring\ payments\ image\ 1\ \(1\).jpg");
    width: 100%;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
    height: 400px;
    position: relative;
  }
  .Haoda_pay_subscriptions {
    font-size: 18px;
    padding: 15px 10px;
  }
}

@media all and (max-width:496px) {
  .yellow_box {
    display: inline-flex;
  transform: rotate(-12.756deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #FFE59C;
  color: #050B20;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 36.5%;
  left: 12%;
  }
  .green_box {
    display: inline-flex;
  transform: rotate(3.167deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #99FF90;
  color: #050B20;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 66%;
  left: 31%;
  }
  .blue_box{
    display: inline-flex;
  transform: rotate(2.068deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #81FFF9;
  color: #050B20;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 38%;
  left: 51%;
  }
  .pink_box {
    display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  transform: rotate(-8.128deg);
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #050B20;
  border-radius: 2px;
  background: #FA96FF;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 60%;
  left: 58%;
  }
  .orange_box {
    display: inline-flex;
  transform: rotate(2.742deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #FF9294;
  color: #050B20;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 40.5%;
  left: 73%;
  border-radius: 2px;
  }
  .Payment_body_bgImage {
    background-image: url("../../../public/images/Recurring\ payments\ image\ 1\ \(1\).jpg");
    width: 100%;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
    height: 380px;
    position: relative;
  }
}

@media all and (max-width:396px) {
  .yellow_box {
    display: inline-flex;
  transform: rotate(-12.756deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #FFE59C;
  color: #050B20;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 35%;
  left: 12%;
  }
  .green_box {
    display: inline-flex;
  transform: rotate(3.167deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #99FF90;
  color: #050B20;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 66%;
  left: 31%;
  }
  .blue_box{
    display: inline-flex;
  transform: rotate(2.068deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #81FFF9;
  color: #050B20;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 37%;
  left: 47%;
  }
  .pink_box {
    display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  transform: rotate(-8.128deg);
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #050B20;
  border-radius: 2px;
  background: #FA96FF;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 60%;
  left: 58%;
  }
  .orange_box {
    display: inline-flex;
  transform: rotate(2.742deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #FF9294;
  color: #050B20;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 39%;
  left: 70%;
  border-radius: 2px;
  }
  .Payment_body_bgImage {
    background-image: url("../../../public/images/Recurring\ payments\ image\ 1\ \(1\).jpg");
    width: 100%;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
    height: 370px;
    position: relative;
  }
  .Haoda_pay_subscriptions {
    font-size: 16px;
    padding: 10px 5px;
  }
}

@media all and (max-width:396px) {
  .yellow_box {
    display: inline-flex;
  transform: rotate(-12.756deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #FFE59C;
  color: #050B20;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 36%;
  left: 9%;
  }
  .green_box {
    display: inline-flex;
  transform: rotate(3.167deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #99FF90;
  color: #050B20;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 66%;
  left: 25%;
  }
  .blue_box{
    display: inline-flex;
  transform: rotate(2.068deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #81FFF9;
  color: #050B20;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 37%;
  left: 43%;
  }
  .pink_box {
    display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  transform: rotate(-8.128deg);
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #050B20;
  border-radius: 2px;
  background: #FA96FF;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 60%;
  left: 58%;
  }
  .orange_box {
    display: inline-flex;
  transform: rotate(2.742deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #FF9294;
  color: #050B20;
  font-family: 'Lora';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 39%;
  left: 67%;
  border-radius: 2px;
  }
  .Payment_body_bgImage {
    background-image: url("../../../public/images/Recurring\ payments\ image\ 1\ \(1\).jpg");
    width: 100%;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
    height: 350px;
    position: relative;
  }
}

@media all and (max-width:327px) {
  .yellow_box {
    display: inline-flex;
  transform: rotate(-12.756deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #FFE59C;
  color: #050B20;
  font-family: 'Lora';
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 36%;
  left: 9%;
  }
  .green_box {
    display: inline-flex;
  transform: rotate(3.167deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #99FF90;
  color: #050B20;
  font-family: 'Lora';
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 66%;
  left: 25%;
  }
  .blue_box{
    display: inline-flex;
  transform: rotate(2.068deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #81FFF9;
  color: #050B20;
  font-family: 'Lora';
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 37%;
  left: 43%;
  }
  .pink_box {
    display: inline-flex;
  transform: rotate(-8.128deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  transform: rotate(-8.128deg);
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #050B20;
  border-radius: 2px;
  background: #FA96FF;
  font-family: 'Lora';
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 60%;
  left: 58%;
  }
  .orange_box {
    display: inline-flex;
  transform: rotate(2.742deg);
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #FF9294;
  color: #050B20;
  font-family: 'Lora';
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  top: 39%;
  left: 67%;
  border-radius: 2px;
  }
  .Payment_body_bgImage {
    background-image: url("../../../public/images/Recurring\ payments\ image\ 1\ \(1\).jpg");
    width: 100%;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
    height: 350px;
    position: relative;
  }
  .Haoda_pay_subscriptions {
    font-size: 12px;
    padding: 10px 5px;
  }
}




























