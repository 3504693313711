.navBar__miniHeader{
    position: fixed;
    top: 20px;
    z-index: 2;
}

.haodapay__certificateBanner{
    margin-top: 68px;
}

.headerMain__navcontainers{
    position: fixed;
    top: 0;
    z-index: 5;
    background-color: #fff;
}

.navBar__session{
    height: 100px;
}

.haodaPay__logo img{
    width: 202px;
    height: 78px;
    object-fit: contain;
}

.navLink__name{
    color: #131319;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    line-height: 60px;    
}

.navLink__subName{
    color: #131319;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.free__btn button{
    border-radius: 4px;
    background: #4E2AAD;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
}

.free__btn button:hover{
    box-shadow: 0px -2.6px 0px 0px #210077 inset;
}

.free__btn button:hover .headerBannerNav__arrow{
    animation: moveArrow 0.6s ease;
}

.headerBannerNav__arrow{
    font-size: 12px;
    margin-top: 2px;
}

.headerBanner__arrowNav{
    width: 20px;
    overflow: hidden;
}

@keyframes moveArrow {
    0%{
        transform: translateX(0px);
    }
    30%{
       transform: translateX(20px);
       display: none;
    }
    60%{
        transform: translateX(-15px);
        display: none;
    }
    100%{
        transform: translateX(0px);
    }
}

.navLink__name {
    color: #131319;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s all ease;
    position: relative;
}

.navLinks__name{
    color: #131319;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s all ease;
    position: relative;
}

.navLinks__name::before{
    content: "";
    position: absolute;
    pointer-events: none;
    width: 0%;
    height: 3px;
    top: 105%;
    background-color: #210077;
    border-radius: 8px;
    transition: all 0.5s ease-in-out;
}

.navLinks__name:hover::before{
    content: "";
    position: absolute;
    pointer-events: none;
    width: 70%;
    transform: translateX(50%);
    transition: all 0.5s ease-in-out;
}
  
.dropDown{
    width: max-content;
    height: fit-content;
    background: rgba(255, 255, 255, 0.30);
    backdrop-filter: blur(30px);
    border-radius: 20px;
    position: absolute;
    top: 100%;
    left: 0;
    transition: width 0.3s ease, height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: none;
    z-index: 4;
}

.navLink__name:hover .dropDown{
    opacity: 1;
    display: block;
}

.navLink__product{
    padding: 0px 12px 0px 12px;
    line-height: 60px;
}

.navLink__product:hover{
    background-color: #FFF;
    border-radius: 6px;
}

/* MINI HEADER NAV SECTION */

.navLink__Mininame{
    color: #131319;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    height: 100%;
    line-height: 70px;
    display: block;
}

.navLink__Miniproduct{
    line-height: 44px;
}

.navBar__miniHeader{
    width: 860px;
    height: 70px;
    padding: 0px 12px;
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #dddcdc;
}

.header__miniLogo img{
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.header__miniBtn button{
    width: 130px;
    height: 46px;
    border-radius: 4px;
    background: #4E2AAD;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
}

.navLink__name{
    position: relative;
}

.dropDown__mini {
    width: max-content;
    height: fit-content;
    background: #fff;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    border-radius: 20px;
    position: absolute;
    top: 100%;
    left: 0;
    transition: opacity 0.3s ease;
    opacity: 0;
    visibility: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 1;
}
  
.navLink__Mininame:hover .dropDown__mini {
  opacity: 1;
  visibility: visible;
}

.navMenu__list ul{
    list-style: none;
}

.mini__navBtn{
    display: none;
}

.navHeader__menuBtn{
    display: none;
}


@media only screen and (max-width: 701px)  {
    .haodaPay__logo img{
        width: 160px;
        height: 68px;
        object-fit: contain;
    }
}

@media (min-width: 700px) and (max-width: 800px) {
    .haodaPay__logo img{
        width: 140px;
        height: 68px;
        object-fit: contain;
    }

}

@media (min-width: 801px) and (max-width: 1023px) {
    .haodaPay__logo img{
        width: 160px;
        height: 68px;
        object-fit: contain;
    }

}

@media (min-width: 767px) and (max-width: 910px) {
    .free__btn button{
        height: 40px;
        font-size: 14px;
    }
}  

@media only screen and (max-width: 860px) {
    .navBar__miniHeader{
        width: 95%;
    }
    .header__miniBtn button{
        width: 110px;
        height: 38px;
        border-radius: 4px;
        font-size: 14px;
    }
    .header__miniLogo img{
        width: 40px;
        height: 40px;
        object-fit: cover;
    }
}

@media only screen and (max-width: 600px) {
    .navLink__Mininame{
        font-size: 14px;
    }
}

@media only screen and (max-width: 520px) {
    .header__miniBtn, .navMini__containerSec{
        display: none;
    }
    .mini__navBtn{
        display: inline-block;
    }
}

@media only screen and (max-width: 768px) {
    .navMenu__list, .navHeader__btn{
        display: none;
    }
}

.mobileNav__container{
    width: 300px;
    position: fixed;
    top: 0;
    right: -300px; 
}

@media only screen and (max-width: 768px) {
    
    .mobileNav__container {
        width: 300px;
        min-height: min-content;
        overflow-y: auto;
        height: 100vh;
        position: fixed;
        top: 0;
        right: -300px; 
        background-color: #fff;
        z-index: 6;
        transition: right 0.5s ease-in-out; 
    }

    .navHeader__menuBtn{
        display: inline-block;
    }
    
    .mobileNav__container.open {
        right: 0; 
    }

    .mobileNav__close{
        width: 24px;
        height: 24px;
        border-radius: 6px;
        background-color: #ECEAFD;
        font-size: 18px;
        cursor: pointer;
    }

    .navLink__MobileContainer{
        list-style: none;
    }

    .navLink__Mobilename{
        color: #131319;
        font-size: 16px;
        font-weight: 500;  
        position: relative;
        width: fit-content;
    }

    .navLink__Mobilena{
        color: #131319;
        font-size: 16px;
        font-weight: 500;  
    }

    .navLink__Mobilename::before{
        content: "";
        position: absolute;
        pointer-events: none;
        width: 0%;
        height: 3px;
        top: 110%;
        background-color: #210077;
        border-radius: 8px;
        transition: all 0.5s ease-in-out;
    }

    .navLink__Mobilename:hover::before{
        content: "";
        position: absolute;
        pointer-events: none;
        width: 70%;
        transform: translateX(50%);
        transition: all 0.5s ease-in-out;
    }

    .navLink__MobileBtn button{
        width: 100%;
        height: 42px;
        border-radius: 4px;
        outline: none;
        background-color: #4E2AAD;
        color: #FFF;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1px;
    }

    .mobileNav__subMenu{
        list-style: none;
    }
    
    .mobileNav__subMenu li{
        color: #131319;
        font-size: 16px;
        font-weight: 500;  
    }

    .mobileNav__subMenu{
        display: none;
        transition: all 0.5s cubic-bezier(0,1,0,1);
    }

    .mobileNav__subMenu.showNav{
        display: block;
        transition: all 0.5s cubic-bezier(0,1,0,1);
    }

    .arrowDown__nav{
        transform: scale(0deg);
    }

    .arrowDown__navRoto{
        transform: rotate(90deg);
    }
}
