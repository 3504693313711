@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.PaymentLink__banner__bgImg {
    /* background-image: url(../../../public/images/term__bannerImg.png); */
    width: 100%;
    background-size: 100% 100%;
    background-position: cover / center;
}

.paymentLink__heading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 56px;
    font-weight: 500;
    line-height: 110%;
    margin-right: 160px;
}

.paymentLink__bodyText {
    color: #131319;
    font-family: 'Lora';
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-right: 230px;
}

.paymentLink__banner video{
    width: 100%;
    border: none;
}

.paymentLink__bannerVideo{
    position: relative;
    height: 500px;
}

@media (min-width:768px) and (max-width:1023px) {
    .paymentLink__bannerVideo{
        position: relative;
        height: 850px;
    }
    
}

@media (min-width:640px) and (max-width:768px) {
    .paymentLink__bannerVideo{
        position: relative;
        height: 900px;
    }
}

@media (min-width:581px) and (max-width:640px) {
    .paymentLink__bannerVideo{
        position: relative;
        height: 950px;
    }
}

@media all and (max-width:581px) {
    .paymentLink__bannerVideo{
        position: relative;
        height: 800px;
    }
}


.paymentLink__bannerVideo video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.paymentLink__bannerTitle{
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.EducationBoy {
    width: 100%;
    height: 405px;
  object-fit: cover;
  }

.create__paymentLink__btn {
    display: flex;
    padding:  16px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius:  4px;
    background: #4E2AAD;
    box-shadow: 0px -2.6px 0px 0px #4E2AAD inset;
    color:#FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.PaymentLink__banner__background {
    padding: 0px 124px;
    gap: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.instantPayment_heaidng {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.instantPayment_paraText {
    color: #131319;
    font-family: 'Lora';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; 
}

.create_paymentLink_heading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
}

.payment__link__list {
    color: #131319;
    font-family: Lora;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.create_paymentLink_Img {
    height: 405px;
    width: 100%;
    object-fit: contain;
}

.create_paymentLink_page {
    display: flex;
    padding: 0px 124px 0px 124px;
    justify-content: center;
    align-items: center;
}

.Ensuring_Data_Protection_heading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-align: center;
}

.Ensuring_Data_Protection_subHeading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
}

.Ensuring_Data_Protection_text {
    color: #131319;
    font-family: Lora;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;   
}


.paymentLink_clickMobile {
    width: 100%;
    height: 405px;
    object-fit: contain;
    border-radius: 14px;
    background-color: #fff;
}

.Unlock_instant_payment_page {
    padding: 0px 124px;
}

.haoda {
    color: #392675;
}

.pay {
    color: #F4B936;
}

@media (min-width:1024px) and (max-width:1279px) {
    .Ensuring_Data_Protection_subHeading {
        font-size: 18px;
    }
    .Unlock_instant_payment_page {
        padding: 0px 50px;
    }
}

@media (min-width:768px) and (max-width:1024px) {
    .Ensuring_Data_Protection_subHeading {
        font-size: 16px;
    }
    .Ensuring_Data_Protection_text {
        font-size: 14px;
    }
    .Ensuring_Data_Protection_heading {
        font-size: 26px;
    }
    .Unlock_instant_payment_page {
        padding: 0px 124px;
    }
}

@media (min-width:640px) and (max-width:768px) {
    .Ensuring_Data_Protection_subHeading {
        font-size: 18px;
    }
    .Ensuring_Data_Protection_text {
        font-size: 14px;
    }
    .dataProduction_box {
        width: 400px;
        padding: 10px;
    }
    .Unlock_instant_payment_page {
        padding: 0px 10px;
    }
}

@media (min-width:200px) and (max-width:640px) {
    .Unlock_instant_payment_page {
        padding: 0px 10px;
    }
    .instantPayment_heaidng {
        font-size: 20px;
    }
    .instantPayment_paraText {
        font-size: 14px;
    }
    .paymentLink_clickMobile {
        width: 100%;
        height: 300px;
        object-fit: contain;
    }    
    .Ensuring_Data_Protection_heading {
        font-size: 22px;
    }
}

@media all and (max-width:768px) {
    .Ensuring_Data_Protection_subHeading {
        font-size: 18px;
    }
    .Ensuring_Data_Protection_text {
        font-size: 14px;
    }
    .dataProduction_box {
        width: 300px;
    }
}

@media (min-width:1024px) and (max-width:1279px) {
    .create_paymentLink_page {
        padding: 0px 30px;
    }
}

@media (min-width:200px) and (max-width:768px) {
    .create_paymentLink_page {
        padding: 0px 5px 0px 5px;

    }
}

@media (min-width:300px) and (max-width:471px) {
    .create_paymentLink_heading {
        font-size: 20px;
    }
    .payment__link__list {
        font-size: 14px;
    }
    .create_paymentLink_Img {
        height: 330px;
    }
    .create_paymentLink_page {
        padding: 0px 5px 0px 5px;
    }
}

@media all and (max-width:300px) {
    .create_paymentLink_heading {
        font-size: 16px;
    }
    .payment__link__list {
        font-size: 12px;
    }
    .create_paymentLink_Img {
        height: 250px;
    }
    .create_paymentLink_page {
        padding: 0px 10px 0px 10px;
    }
}

@media all and (max-width:1535px) {
    .paymentLink__heading {
        margin-right: 100px;
    }
    .EducationBoy {
        height: 380px;
        width: 100%;
    }
    .PaymentLink__banner__background {
        padding: 0px 50px;
    }
}

@media all and (max-width:1279px) {
    .PaymentLink__banner__background {
        padding: 0px 40px;
    }
    .paymentLink__heading {
        margin-right: 50px;
        font-size: 48px;
    }
    .EducationBoy {
        height: 320px;
        width: 100%;
    }
}

@media all and (max-width:1023px) {
    .PaymentLink__banner__background {
        padding: 0px 10px;
        display: block;
    }
    .paymentLink__heading {
        margin-right: 0px;
        font-size: 56px;
    }
    .EducationBoy {
        height: 405px;
    }
    .paymentLink__bodyText {
        margin-right:30px;
        margin-left: 30px;
    }
}

@media all and (max-width:580px) {
    .paymentLink__heading {
        font-size: 46px;
    }
    .EducationBoy {
        height: 350px;
    }
}

@media all and (max-width:480px) {
    .paymentLink__heading {
        font-size: 30px;
    }
    .PaymentLink__banner__background {
        padding: 0px 10px;
    }
}

@media all and (max-width:420px) {
    .paymentLink__heading {
        font-size: 36px;
    }
    .paymentLink__bodyText {
         font-size: 15px;
    }
    .EducationBoy {
        height: 320px;
    }
}

@media all and (max-width:380px) {
    .paymentLink__heading {
        font-size: 30px;
    }
    .paymentLink__bodyText {
         font-size: 14px;
    }
    .create__paymentLink__btn {
        padding:  14px 30px;
        font-size: 14px;
    }
    .EducationBoy {
        height: 300px;
    }
}

.faq__ques{
    width: 80px;
    height: 32px;
    border-radius: 8px;
    background: #4E2AAD;
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.12);
}

.faq__quesTitle{
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: "Poppins", sans-serif;
}

.faq__quesIcon{
    color: #FFF;
    font-size: 20px;
    font-weight: bold;
}

.faq__title{
    color: #131319;
    font-size: 48px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.faq__para{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: "Lora", serif;
}

.faq__contentTitle{
    color: #131319;
    font-size: 16px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.faq__container{
    width: 600px;
    border-radius: 14px;
    border: 1px solid rgba(23, 17, 17, 0.08);
    background-color: #fff;
}

.faq__contentPara{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: "Lora", serif;
}

.faq_bgColor {
    background-color: #F4F4F4;
}

.faq__content{
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
}

.faq__content.show{
    height: auto;
    max-height: 500px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
}

@media only screen and (max-width: 600px) {
    .faq__container{
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .faq__title{
        font-size: 32px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .faq__title{
        font-size: 40px;
    }
}
