.retail__heading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 56px;
    font-weight: 500;
    line-height: 110%;
}

.retail__banner__background {
    padding: 0px 124px;
    gap: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.retail__heading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 56px;
    font-weight: 500;
    line-height: 110%;
}

.retail__bodyText {
    color: #131319;
    font-family: 'Lora';
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
}

.retail__banner video{
    width: 100%;
    border: none;
}

.retail__bannerVideo{
    position: relative;
    height: 650px;
}


.retail__bannerVideo video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.retail__bannerTitle{
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.retail_banner_image {
    overflow: hidden;
    border-radius: 14px;
    width: 505px;
    height: 505px;

}

.retail_banner_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 1.6s ease-in-out;
    border-radius: 14px;
}

.retail_banner_image img:hover {
    transition: 1.6s ease-in-out;
    transform:scale(1.2);
    cursor: pointer;
}


.retail__banner__background {
    padding: 0px 124px;
    gap: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width:1279px) and (max-width:1534px) {
    .retail__banner__background {
        padding: 0px 40px;
    }
    .retail__heading {
        margin-right: 0px;
        font-size: 46px;
    }
    .retail_banner_image {
        height: 405px;
        width: 405px;
    }
    .retail__bodyText {
        color: #131319;
        font-family: 'Lora';
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        margin-right: 0px;
    }
    .retail__bannerVideo{
        height: 600px;
    }
}

@media (min-width:1024px) and (max-width:1279px) {
    .retail__banner__background {
        padding: 0px 10px;
    }
    .retail__heading {
        margin-right: 0px;
        font-size: 46px;
    }
    .retail_banner_image {
        height: 350px;
        width: 350px;
    }
    .retail__bodyText {
        font-size: 14px;
    }
    .retail__bannerVideo{
        position: relative;
        height: 500px;
    }
    .retail__bannerVideo{
        height: 500px;
    }
}

@media (min-width:640px) and (max-width:1023px) {
    .retail__banner__background {
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-self: center;
        justify-items: center;
    }
    .retail__bodyText {
        width: 630px;
        margin-right: 0px;
    }
    .retail__bannerVideo{
        position: relative;
        height: 1000px;
    }
    .retail__heading {
        font-size: 40px;
    }
}

@media (min-width:375px) and (max-width:640px) {
    .retail__banner__background {
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-self: center;
        justify-items: center;
    }
    .retail__bodyText {
        width: 100%;
        margin-right: 0px;
        font-size: 14px;
    }
    .retail__bannerVideo{
        position: relative;
        height: 800px;
    }
    .retail_banner_image {
        height: 350px;
        width: 350px;
    }
    .retail__heading {
        font-size: 32px;
    }
}

@media only screen and (max-width:375px) {
    .retail__banner__background {
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-self: center;
        justify-items: center;
    }
    .retail__bodyText {
        width: 100%;
        margin-right: 0px;
        font-size: 12px;
    }
    .retail__bannerVideo{
        position: relative;
        height: 670px;
    }
    .retail_banner_image {
        height: 250px;
        width: 250px;
    }
    .retail__heading {
        font-size: 32px;
    }
    .contact__btn button {
        border-radius: 4px;
        background: #4E2AAD;
        color: #FFF;
        font-size: 16px;
        width: 170px;
    
    }
}


.Retail_bgImage {
    background-image: url(../../../public/images/retailBg__img.png);
    width: 100%;
    background-size: 100% 100%;
    background-position: center / cover;
}

.Retail_Business_heading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
}

.Retail_Business_text {
    color: #131319;
    text-align: center;
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 400;
    width: 650px;
    padding: 0px 10px;
}

.retailOperation__bgColor {
    background-color: #FFFEFF;
}

.retailOperation__heading {
    color: #131319;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: 500;
    width: 875px;
}

.retailOperation__text {
    color: #131319;
    font-family: 'Lora', serif;
    font-size: 18px;
    font-weight: 400;
    width: 640px;
}

.retailOperation__page {
    margin: 0px 124px;
}

.retailOperation__subHeading {
    color: #131319;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 500;
}

.OurSolutions__heading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 500;
}

.OurSolutions__text {
    color: #131319;
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 400;
}

@media (min-width:1280px) and (max-width:1535px) {
    .retailOperation__page {
        margin: 0px 50px;
    }
    
}

@media (min-width:1024px) and (max-width:1280px) {
    .retailOperation__page {
        margin: 0px 20px;
    }
    .OurSolutions__heading {
        font-size: 18px;
    }
    
    .OurSolutions__text {
        font-size: 14px;
    }
}

@media (min-width:768px) and (max-width:1024px) {
    .retailOperation__page {
        margin: 0px 10px;
    }
    .OurSolutions__heading {
        font-size: 16px;
    }    
    .OurSolutions__text {
        font-size: 12px;
    }
    .retailOperation__heading {
        font-size: 40px;
        width: 100%;
    }
    .retailOperation__subHeading {
        font-size: 20px;
    }
}

@media (min-width:500px) and (max-width:768px) {
    .retailOperation__page {
        margin: 0px 5px;
    }
    .OurSolutions__heading {
        font-size: 16px;
    }    
    .OurSolutions__text {
        font-size: 12px;
    }
    .retailOperation__heading {
        font-size: 32px;
        width: 100%;
    }
    .retailOperation__text {
        width: 100%;
    }
    .retailOperation__subHeading {
        font-size: 20px;
    }
    .Retail_Business_text {
        width: 100%;
    }
    .Retail_Business_heading {
        font-size: 22px;
    }
}

@media (min-width:200px) and (max-width:500px) {
    .retailOperation__page {
        margin: 0px 20px;
    }
    .OurSolutions__heading {
        font-size: 20px;
        text-align: center;
    }    
    .OurSolutions__text {
        font-size: 16px;
        text-align: center;
    }
    .OurSolutions_logo {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
    .OurSolutions_box {
        display: block;
    }
    .retailOperation__heading {
        font-size: 28px;
        width: 100%;
    }
    .retailOperation__text {
        width: 100%;
    }
    .retailOperation__subHeading {
        font-size: 20px;
    }
    .Retail_Business_text {
        width: 100%;
        font-size: 14px;
    }
    .Retail_Business_heading {
        font-size: 22px;
    }
}

.Retailers__Choose__HaodaPay__bgColor {
    background: #FFF;
}

.Retailers__Choose__HaodaPay__heading {
    color: #131319;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: 500;
}

.Retailers__Choose__HaodaPay_page {
    margin: 0px 124px;
}

.All-In-One_Payment_box {
    width: 377px;
    height: 300px;
    border-radius: 22px;
    background: #F3EEFF;
    overflow: hidden;
} 

.Speedy_Checkout_box {
    width: 377px;
    height: 300px;
    border-radius: 22px;
    background: #FCFFEE;
    overflow: hidden;
}

.Speedy_Checkout_box:hover { 
    .Retailers__Choose__HaodaPay__logo {
      transition: 0.6s ease-in-out;
      transform:scale(1.2);
      cursor: pointer;
      overflow: hidden;
  }
 }

.Easy_Integration_box {
    width: 377px;
    height: 300px;
    border-radius: 22px;
    background: #FEE;
    overflow: hidden;
}

.Easy_Integration_box:hover { 
  .Retailers__Choose__HaodaPay__logo {
    transition: 0.6s ease-in-out;
    transform:scale(1.2);
    cursor: pointer;
    overflow: hidden;
}
}

.Retailers__Choose__HaodaPay__subHeading {
    color: #050B20;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 500;
}

.Retailers__Choose__HaodaPay__text {
    color: #050B20;
    font-family: 'Lora', serif;
    font-size: 15px;
    font-weight: 400;
}

.Retailers__Choose__HaodaPay__logo{
    width: 203px;
    height: 203px;
    overflow: hidden;
    transition: 0.6s ease-in-out;
}
/* gefwitfe */


@media (min-width:1280px) and (max-width:1535px) {
    .Retailers__Choose__HaodaPay_page {
        margin: 0px 50px;
    }
}

@media (min-width:1024px) and (max-width:1280px) {
    .Retailers__Choose__HaodaPay_page {
        margin: 0px 10px;
    }
    .Retailers__Choose__HaodaPay__subHeading {
        font-size: 26px;
    }
}

@media (min-width:768px) and (max-width:1024px) {
    .Retailers__Choose__HaodaPay_page {
        margin: 0px 10px;
    }
    .Retailers__Choose__HaodaPay__subHeading {
        font-size: 20px;
        padding-top: 10px;
    }
    .Retailers__Choose__HaodaPay__logo {
        width: 150px;
        height: 150px;
    }
    .Retailers__Choose__HaodaPay__text {
        font-size: 12px;
    }
    .Easy_Integration_box {
        height: 250px;
    }
    .Speedy_Checkout_box {
        height: 250px;
    }
    .All-In-One_Payment_box {
        height: 250px;
    }
}

@media (min-width:639px) and (max-width:768px) {
    .Retailers__Choose__HaodaPay_page {
        margin: 0px 10px;
    }
    .Retailers__Choose__HaodaPay__subHeading {
        font-size: 16px;
        padding-top: 5px;
    }
    .Retailers__Choose__HaodaPay__logo {
        width: 120px;
        height: 120px;
    }
    .Retailers__Choose__HaodaPay__text {
        font-size: 10px;
    }
    .Easy_Integration_box {
        height: 220px;
    }
    .Speedy_Checkout_box {
        height: 220px;
    }
    .All-In-One_Payment_box {
        height: 220px;
    }
    .Retailers__Choose__HaodaPay__heading {
        font-size: 40px;
    }
}

@media (min-width:425px) and (max-width:639px) {
    .Retailers__Choose__HaodaPay_page {
        margin: 0px 10px;
    }
    .Retailers__Choose__HaodaPay__subHeading {
        font-size: 20px;
        padding-top: 10px;
    }
    .Retailers__Choose__HaodaPay__heading {
        font-size: 32px;
    }
} 

@media (min-width:200px) and (max-width:425px) {
    .Retailers__Choose__HaodaPay_page {
        margin: 0px 10px;
    }
    .Retailers__Choose__HaodaPay__subHeading {
        font-size: 20px;
        padding-top: 10px;
    }
    .Retailers__Choose__HaodaPay__logo {
        width: 150px;
        height: 150px;
    }
    .Retailers__Choose__HaodaPay__text {
        font-size: 12px;
    }
    .Easy_Integration_box {
        height: 250px;
        width: 280px;
    }
    .Speedy_Checkout_box {
        height: 250px;
        width: 280px;
    }
    .All-In-One_Payment_box {
        height: 250px;
        width: 280px;
    }
    .Retailers__Choose__HaodaPay__heading {
        font-size: 32px;
    }
} 

.reachUs__bgColor {
    background: linear-gradient(180deg, #FFF 0%, #FFFBAB 100%);
    overflow: hidden;
}

.reachUs__page {
    margin: 0px 124px;
    position: relative;
}

.reachUs__heading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: 500;
}

.reachUs__text {
    color: #131319;
    font-family: 'Lora', serif;
    font-size: 16px;
    width: 711px;
    font-weight: 400;
}

.reachUs__img {
    position: absolute;
    bottom: 0%;
}

.joinHaodapay__revoluiton {
    width: 270px;
}

.reachUs__btn {
    padding: 16px 40px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
}

.reachUs__page1 {
    width: 791px;
}
.reachUs__page2 {
    width: 360px;
}
.reachUs__img {
    bottom: 0%;
}

@media (min-width:1023px) and (max-width:1280px) {
    .reachUs__page1 {
        width: 700px;
    }
    .reachUs__page2 {
        width: 320px;
    }
    .reachUs__heading {
        font-size: 26px;
    }
    .reachUs__btn {
        display: flex;
        padding: 16px 40px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius:  4px;
        background: #4E2AAD;
        box-shadow: 0px -2.6px 0px 0px #4E2AAD inset;
        color: #FFF;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
    }
    .joinHaodapay__revoluiton {
        width: 250px;
    }
    .reachUs__page {
        margin: 0px 20px;
    }
}

@media (min-width:768px) and (max-width:1023px) {
    .reachUs__page {
        margin: 0px 20px;
    }
    .reachUs__heading {
        font-size: 30px;
        text-align: center;
    }
    .reachUs__page1 {
        width: 700px;
    }
    .reachUs__text {
        text-align: center;
    }
    .reachUs__page2 {
        width: 100%;
    }
}

@media (min-width:640px) and (max-width:768px) {
    .reachUs__page {
        margin: 0px 10px;
    }
    .reachUs__heading {
        font-size: 30px;
        text-align: center;
    }
    .reachUs__page1 {
        width: 100%;
    }
    .reachUs__text {
        width: 100%;
    }
    .reachUs__page2 {
        width: 100%;
    }
}

@media (min-width:200px) and (max-width:640px) {
    .reachUs__page {
        margin: 0px 10px;
    }
    .reachUs__heading {
        font-size: 24px;
        text-align: center;
    }
    .reachUs__page1 {
        width: 100%;
    }
    .reachUs__page2 {
        width: 100%;
    }
    .reachUs__btn {
        font-size: 10px;
        padding: 12px 30px;
        font-family: 'Poppins', sans-serif;
    }
    .reachUs__text {
        width: 100%;
    }
}

.reachUs__btn button:hover{
    box-shadow: 0px -2.6px 0px 0px #210077 inset; 
}

.reachUs__btn {
    display: flex;
    padding:  16px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius:  4px;
    background: #4E2AAD;
    box-shadow: 0px -2.6px 0px 0px #4E2AAD inset;
    color:#FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.reachUs__button 
    button:hover .right__arrow{
        animation: rightArrow 0.6s ease;
        font-family: 'Poppins', sans-serif;
}


@keyframes rightArrow {
    0%{
        transform: translateX(0px);
    }
    30%{
       transform: translateX(20px);
       display: none;
    }
    60%{
        transform: translateX(-15px);
        display: none;
    }
    100%{
        transform: translateX(0px);
    }
}

html {
    scroll-behavior: smooth;
}

/* .Retailers__Choose__HaodaPay__logo {
    overflow: hidden;
    transition: 1.6s ease-in-out;
} */



/* .Retailers__Choose__HaodaPay__logo:hover {
    transition: 1.6s ease-in-out;
    transform:scale(1.2);
    cursor: pointer;
} */