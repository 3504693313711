.payout__body{
    background-color: #140C2C;
}

.payout__mainContentImg{
    width: 100%;
    height: 497px;
    background-image: url(../../../public/images/Frame\ 19.png);
    background-repeat: no-repeat;
    background-position: center/cover;
    background-size: cover;
}

.payout__mainTitle{
    color: #FFF;
    font-size: 30px;
    font-weight: 600;
}

.payout__mainBtn button{
    width: 160px;
    height: 40px;
    border-radius: 4px;
    background-color: #5E33CF;
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
}

.payoutBanner__mainImg img{
    width: 465px;
    height: 340px;
    object-fit: contain;
}

.payout__mainContentImg{
    position: relative;
}

.payoutBanner__mainImg{
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}



@media only screen and (max-width: 320px) {
    .payout__mainBtn button{
        width: 140px;
        height: 34px;
        font-size: 14px;
    }
    .payout__mainSection{
        height: 500px;
    }
    .payout__mainTitle{
        font-size: 20px;
    }
    .payout__mainImg{
        top: 130%;
    }
    .payout__mainContentImg{
        height: 333px;
    }
    .payoutBanner__mainImg{
        top: 100%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .payout__mainBtn button{
        width: 140px;
        height: 34px;
        font-size: 14px;
    }
    .payout__mainSection{
        height: 520px;
    }
    .payout__mainTitle{
        font-size: 22px;
    }
    .payout__mainContentImg{
        height: 333px;
    }
    .payoutBanner__mainImg{
        top: 100%;
    }
}

@media (min-width: 375px) and (max-width: 500px) {
    .payout__mainBtn button{
        width: 140px;
        height: 34px;
        font-size: 14px;
    }
    .payout__mainSection{
        height: 470px;
    }
    .payout__mainTitle{
        font-size: 22px;
    }
    .payout__mainImg{
        top: 140%;
    }
    .payout__mainContentImg{
        height: 363px;
    }
    .payoutBanner__mainImg{
        top: 100%;
    }
}

@media (min-width: 501px) and (max-width: 580px ) {
    .payout__mainSection{
        height: 450px;
    }
    .payout__mainTitle{
        font-size: 22px;
    }
    .payout__mainImg{
        top: 155%;
    }
    .payout__mainContentImg{
        height: 363px;
    }
    .payoutBanner__mainImg{
        top: 100%;
    }
}

@media (min-width: 581px) and (max-width: 768px ) {
    .payout__mainSection{
        height: 470px;
    }
    .payout__mainTitle{
        font-size: 22px;
    }
    .payout__mainContentImg{
        height: 403px;
    }
    .payoutBanner__mainImg{
        top: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .payout__mainContentImg{
        height: 443px;
    }
    .payoutBanner__mainImg{
        top: 100%;
    }
}


/* Partner Banking style */

.partnerBank__section{
    background-color: #D2E3E5;
    height: 450px;
}

.partnerBank__title{
    color: #1C1C1C;
    font-size: 30px;
    font-weight: 600;
}

.sliderUniversity__container{
    overflow-x: hidden;
    padding: 44px 0px;
    white-space: nowrap;
}

.sliderUniversity__img{
    display: inline-block;
    animation: 48s slide infinite linear;
}

@keyframes slide {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(-100%);
    }
}

.sliderUniversity__img img{
    width: 160px;
    height: 46px;
    margin: 0px 30px;
    object-fit: contain;
    display: inline-block;
}

@media only screen and (max-width: 429px) {
    .partnerBank__section{
        height: 280px;
    }

    .partnerBank__title{
        font-size: 26px;
    }
}

@media (min-width: 430px) and (max-width: 551px) {
    .partnerBank__title{
        margin-top: 50px;
    }
    .partnerBank__section{
        height: 350px;
    }
}

@media (min-width: 550px) and (max-width: 639px) {
    .partnerBank__title{
        margin-top: 100px;
    }
    .partnerBank__section{
        height: 400px;
    }
}


/* Payout capabile style */

.payoutCapabile__section{
    width: 100%;
    height: 580px;
    background-image: url(../../../public/images/PayoutBackground1.png);
    background-repeat: no-repeat;
    background-position: cover;
    background-size: contain;
}

.payoutCapabile__title{
    color: #FFF;
    font-size: 30px;
    font-weight: 600;
}

.payoutCapabile__para{
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
}

.payoutCapabile__content{
    width: 388px;
    height: fit-content;
    border-radius: 14px;
    background: rgba(68, 68, 68, 0.30);
    backdrop-filter: blur(10px);
}

.payoutCapabile__contentTitle{
    color: #FFF;
    font-size: 22px;
    font-weight: 600;
}

.payoutCapabile__contentPara{
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
}

@media only screen and (max-width: 768px) {
    .payoutCapabile__title{
        font-size: 26px;
    }
}

@media only screen and (max-width: 375px) {
    .payoutCapabile__content{
        width: 100%;
        height: 280px;
    }

}

@media (min-width: 376px) and (max-width: 1024px) {
    .payoutCapabile__content{
        width: 370px;
        height: 250px;
    }
}

@media only screen and (max-width: 1280px) {
    .payoutCapabile__section{
        width: 100%;
        height: fit-content;
        background-image: url(../../../public/images/PayoutBackground1.png);
        background-repeat: no-repeat;
        background-position: cover;
        background-size: 100% 100%;
    }
}

/* Payout detail style */

.payoutTitle{
    color: #FFF;
    font-size: 30px;
    font-weight: 600;
}

.payoutPara{
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
}

.payoutPara__payout{
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
}

.payoutPara__payoutPara{
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
}

/* Payout Detail style */

.payoutDetail__title{
    color: #FFF;
    font-size: 28px;
    font-weight: 600;
}

.vertical__line{
    width: 282px;
    stroke-width: 4px;
    stroke: #5E33CF;
}

.payoutDetail__title{
    color: #FFF;
    font-size: 28px;
    font-weight: 600;
}

.payoutDetail__para{
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
}

.payout__img img{
    width: 550px;
    height: 550px;
    object-fit: cover;
}

.payoutDetail__Img img{
    width: 550px;
    height: 550px;
    object-fit: cover;
}

.payoutDetail__Imgg img{
    width: 600px;
    height: 550px;
    margin-top: -120px;
    object-fit: cover;
}

@media only screen and (max-width: 570px) {
    .payout__img img{
        width: 100%;
        height: 400px;
        object-fit: contain;
    }
}

@media only screen and (max-width: 610px) {
    .payoutDetail__Imgg img{
        width: 100%;
        height: 400px;
        margin-top: 0px;
        object-fit: contain;
    }
}

@media (min-width: 611px) and (max-width: 1024px) {
    .payoutDetail__Imgg img{
        width: 500px;
        height: 400px;
        margin-top: 0px;
        object-fit: cover;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .payout__img img{
        width: 450px;
        height: 450px;
        object-fit: contain;
    }
    .payoutDetail__Imgg img{
        width: 500px;
        height: 400px;
        object-fit: cover;
    }
}

@media (min-width: 1281px) and (max-width: 1555px) {
    .payout__img img{
        width: 500px;
        height: 500px;
        object-fit: cover;
    }
    .payoutDetail__Imgg img{
        width: 550px;
        height: 400px;
        object-fit: cover;
    }
}

@media only screen and (max-width: 570px) {
    .payoutDetail__Img img{
        width: 100%;
        height: 400px;
        object-fit: contain;
    }
}

@media (min-width: 1024px) and (max-width :1280px) {
    .payoutDetail__Img img{
        width: 450px;
        height: 500px;
        object-fit: contain;
    }
}

/* Payout Features style */

.payout__features{
    background-image: url(../../../public/images/PayoutBackground2.png);
    background-position: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #D2E3E5;
}

.payoutFeatures__content{
    width: 440px;
    height: 280px;
    border-radius: 14px;
    background: rgba(205, 205, 205, 0.30);
    backdrop-filter: blur(10px);
}

.payout__heading{
    color: #1C1C1C;
    font-size: 30px;
    font-weight: 600;
}

.payout__para{
    color: #1C1C1C;
    font-size: 16px;
    font-weight: 500;
}

.payoutFeatures__contentTitle{
    color: #1C1C1C;
    font-size: 22px;
    font-weight: 600;
}

.payoutFeatures__contentPara{
    color: #1C1C1C;
    font-size: 14px;
    font-weight: 400;
}

@media only screen and (max-width: 445px) {
    .payoutFeatures__content{
        width: 100%;
        height: fit-content;
        padding-bottom: 20px;
    }
}


/* Create financial reports style */

.financialReport__section{
    color: #FFF;
    font-size: 30px;
    font-weight: 600;
}

.financialReport__para{
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
}


/* Popup style */

.popupPay__container{
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
    display: grid;
    place-content: center;
}

.popup__content{
    width: 400px;
    height: fit-content;
    border-radius: 12px;
    background: #F7F7F7;
    box-shadow: 0px 80.6px 105.701px 0px rgba(51, 51, 51, 0.10);
}

.popup__contentImg img{
    width: 184px;
    height: 35px;
    object-fit: contain;
}

.payout__input{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1.5px solid #d5d0d0;
    background:  #FFF;
    outline: none;
}

.payout__inputNo{
    width: 15%;
    height: 48px;
    border-radius: 5px;
    border: 1.5px solid #d5d0d0;
    background:  #FFF;
    outline: none;
}

.payout__inputNUm{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1.5px solid #d5d0d0;
    background:  #FFF;
    outline: none;
}

.payoutInput__btn{
    width: 100%;
    height: 48px;
    border: none;
    outline: none;
    border-radius: 5px;
    background: #5E33CF;
    box-shadow: 0px 24px 34px 0px rgba(32, 70, 100, 0.10);
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
}

.payout__errorMsg{
    color: red;
    font-size: 12px;
}

.loader__btn{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background: #5E33CF;
}

.circleReport, .circleReport1 , .circleReport2{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #FFF;
}

.circleReport{
    animation: download 2s linear infinite;
}

.circleReport1{
    animation: download1 2s linear infinite;
}

.circleReport2{
    animation: download2 2s linear infinite;
}

@keyframes download {
    0%{
        opacity: 0;
    }
    60%{
       opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

@keyframes download1 {
    0%{
        opacity: 1;
    }
    60%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes download2 {
    0%{
        opacity: 0;
    }
    60%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

@media only screen and (max-width: 410px) {
    .popup__content{
        width: 100%;
    }
    .payout__inputNo{
        display: none;
    }
}

/* .payout__input::placeholder{
    color: #0A142F;
    font-size: 16px;
    font-weight: 400;
} */