.contactMain__title{
    color: #131319;
    font-size: 22px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.contactMain__Para{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Lora', serif;
}

.contactForm__input{
    width: 100%;
    height: 56px;
    border-radius: 14px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    background: #F5F5F5;
}

.contactForm__input1 {
    color: #131319;
    font-size: 16px;
    font-weight: 500;
    background: #F5F5F5;
    width: 100%;
    height: 56px;
    border-radius: 14px;
    border: 1px solid rgba(255, 255, 255, 0.08);
}

.contactForm__input::placeholder{
    color: #131319;
    font-size: 16px;
    font-weight: 500;
}

.contactForm__btn{
    width: 190px;
    height: 69px;
    border-radius: 4px;
    background: #4E2AAD;
    color: #FFF;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}



.map__iframe{
    width: 100%;
    height: 500px;
}

.map__iframe > iframe{
    width: 100%;
    height: 100%;
}

.contact__bannerImg{
    width: 505px;
    height: 505px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    cursor: pointer;
    border-radius: 12px;
}

.contact__bannerImg img{
    width: 100%;
    height: 100%;
    border-radius: 12px;
    transition: 0.4s ease-in-out;
}

.contact__bannerImg img:hover{
    transform: scale(1.1);
    transition: 0.4s ease-in-out;
}


@media only screen and (max-width: 430px) {
    .contact__bannerImg{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 14px;
    }
}

@media (min-width: 430px) and (max-width: 640px) {
    .contact__bannerImg{
        width: 355px;
        height: 345px;
        object-fit: cover;
        border-radius: 14px;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .contact__bannerImg{
        width: 355px;
        height: 345px;
        object-fit: cover;
        border-radius: 14px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .contact__bannerImg{
        width: 405px;
        height: 405px;
        object-fit: cover;
        border-radius: 14px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .contact__bannerImg{
        width: 405px;
        height: 405px;
        object-fit: cover;
        border-radius: 14px;
    }
}

.location_box {
    border-radius: 14px;
    background: rgba(215, 207, 207, 0.3);
    backdrop-filter: blur(10px);
    width: 400px;
    height: 228px;
    padding: 20px;
    
}

.location_subHeading {
    color: #4E2AAD;
    font-family: 'Poppins',sans-serif;
    font-weight: 500;
    font-size: 22px;
}

.location_text {
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Lora', serif;
}

.loader__btn{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background: #5E33CF;
}

.location_icon {
    font-size: 30px;
    font-weight: 500;
    margin: 10px;
    text-align: center;
    color: #4E2AAD;
}

.circleReport, .circleReport1 , .circleReport2{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #FFF;
}

.circleReport{
    animation: download 2s linear infinite;
}

.circleReport1{
    animation: download1 2s linear infinite;
}

.circleReport2{
    animation: download2 2s linear infinite;
}