.Restaurant_Transform_heading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: 500;
}

.Restaurant_Transform_paraText {
    color: #131319;
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 400;
}

@media (min-width:500px) and (max-width:768px) {
    .Restaurant_Transform_heading {
        font-size: 22px;
    }
    
    .Restaurant_Transform_paraText {
        font-size: 14px;
    }
    .Restaurant-page-image img {
        /* width: 405px; */
        height: 250px;
   }
}

@media (min-width:100px) and (max-width:500px) {
    .restaurant__transform__page {
         display: block;
    }
    .restaurant__transform__page1 {
        width: 100%;
        text-align: center;
    }
    .Restaurant_Transform_heading {
        font-size: 24px;
    }
}


.Restaurant-page-image img {
     /* width: 405px; */
     height: 327px;
}

.restaurant_bgColor {
    background: #F4F4F4;
}

.restaurant_simplifyHotel_heading {
    color: #131319;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: 500;
}

@media (min-width:640px) and (max-width:768px) {
    .restaurant_simplifyHotel_heading {
        font-size: 40px;
    }
    .Restaurant_Payment_Challenges_heading {
        font-size: 40px;
    }
}

@media only screen and (max-width:640px) {
    .restaurant_simplifyHotel_heading {
        font-size: 26px;
    }
    .Restaurant_Payment_Challenges_heading {
        font-size: 26px;
    }
}

.Restaurant_Payment_Challenges_bgColor {
    background: #FFF;
}

.restaurant_simplifyHotel_text {
    color: #131319;
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 400;
}

.restaurant_simplifyHotel_subHeading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 500;
}

.Restaurant_Payment_Challenges_heading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: 500;
}

.Restaurant_Challenges_subHeading1 {
    color: #050B20;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 500;
}

.Restaurant_Challenges_text1 {
    color: #050B20;
    font-family: 'Lora',serif;
    font-size: 15px;
    font-weight: 400;
    width: 350px;
}

.reataurent_svgBox__1 {
    border-radius: 22px;
    background: #F3EEFF;
    width: 581px;
    height: 260px;
    position: relative;
    overflow: hidden;
}

.reataurent_svgBox__1:hover { 
    .reataurent_svg {
    transition: 0.6s ease-in-out;
    transform:scale(1.2);
    object-fit: cover;
    overflow: hidden;
}
}

.reataurent_svgBox__2 {
    border-radius: 22px;
    background: #FFEEF1;
    width: 581px;
    height: 260px;
    position: relative;
    overflow: hidden;
}

.reataurent_svgBox__2:hover { 
    .reataurent_svg {
    transition: 0.6s ease-in-out;
    transform:scale(1.2);
    object-fit: cover;
    overflow: hidden;
}
}

.reataurent_svgBox__3 {
    border-radius: 22px;
    background: #EEFBFF;
    width: 581px;
    height: 260px;
    position: relative;
    overflow: hidden;
}

.reataurent_svgBox__3:hover { 
    .reataurent_svg {
    transition: 0.6s ease-in-out;
    transform:scale(1.2);
    object-fit: cover;
    overflow: hidden;
}
}

.reataurent_svgBox__4 {
    border-radius: 22px;
    background: #FAFFEE;
    width: 581px;
    height: 260px;
    position: relative;
    overflow: hidden;
}

.reataurent_svgBox__4:hover { 
    .reataurent_svg {
    transition: 0.6s ease-in-out;
    transform:scale(1.2);
    object-fit: cover;
    overflow: hidden;
}
}

@media (min-width:1024px) and (max-width:1280px) {
    .reataurent_svgBox__1 {
        width: 480px;
        height: 260px;
    }
    
    .reataurent_svgBox__2 {
        width: 480px;
        height: 260px;
    }
    
    .reataurent_svgBox__3 {
        width: 480px;
        height: 260px;
    }
    
    .reataurent_svgBox__4 {
        width: 480px;
        height: 260px;
    }
}

@media (min-width:768px) and (max-width:1024px) {
    .reataurent_svgBox__1 {
        width: 360px;
        height: 260px;
    }
    
    .reataurent_svgBox__2 {
        width: 360px;
        height: 260px;
    }
    
    .reataurent_svgBox__3 {
        width: 360px;
        height: 260px;
    }
    
    .reataurent_svgBox__4 {
        width: 360px;
        height: 260px;
    }
    .Restaurant_Challenges_subHeading1 {
        font-size: 22px;
    }

    .Restaurant_Challenges_text1 {
         font-size: 14px;
          width: 100%;
    }
}

@media (min-width:500px) and (max-width:640px) {
    .reataurent_svgBox__1 {
        width: 480px;
        height: 260px;
    }
    
    .reataurent_svgBox__2 {
        width: 480px;
        height: 260px;
    }
    
    .reataurent_svgBox__3 {
        width: 480px;
        height: 260px;
    }
    
    .reataurent_svgBox__4 {
        width: 480px;
        height: 260px;
    }
    .Restaurant_Challenges_subHeading1 {
        font-size: 22px;
    }
}

@media (min-width:400px) and (max-width:500px) {
    .reataurent_svgBox__1 {
        width: 380px;
        height: 260px;
    }
    
    .reataurent_svgBox__2 {
        width: 380px;
        height: 260px;
    }
    
    .reataurent_svgBox__3 {
        width: 380px;
        height: 260px;
    }
    
    .reataurent_svgBox__4 {
        width: 380px;
        height: 260px;
    }
    .reataurent_svg svg {
        width: 150px;
        height: 150px;
    }
    .Restaurant_Challenges_text1 {
        font-size: 14px;
        width: 100%;
    }
    .Restaurant_Challenges_subHeading1 {
        font-size: 20px;
    }
}

@media only screen and (max-width:400px) {
    .reataurent_svgBox__1 {
        width: 100%;
        height: 260px;
    }
    
    .reataurent_svgBox__2 {
        width: 100%;
        height: 260px;
    }
    
    .reataurent_svgBox__3 {
        width: 100%;
        height: 260px;
    }
    
    .reataurent_svgBox__4 {
        width: 100%;
        height: 260px;
    }
    .reataurent_svg svg {
        width: 150px;
        height: 150px;
    }
    .Restaurant_Challenges_text1 {
        font-size: 14px;
        width: 100%;
    }
    .Restaurant_Challenges_subHeading1 {
        font-size: 20px;
    }
}

.reataurent_svg {
    position: absolute;
    bottom: 0%;
    right: 0%;
}

@media (min-width:537px) and (max-width:640px) {
    .restaurant_simplifyHotel_text {
        font-size: 14px;
    }
    
    .restaurant_simplifyHotel_subHeading {
        font-size: 18px;
    }
}

@media only screen and (max-width:537px) {
    .restaurant_simplifyHotel_page {
        display: block;
        text-align: center;
    }
    .restaurant_svg {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
    .restaurant_simplifyHotel_subHeading {
        font-size: 20px;
    }
}

@media (min-width:0px) and (max-width:640px) {
    .Restaurant_Payment_Challenges_heading {
        font-size: 26px;
    }
}
