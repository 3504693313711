@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.AboutUs_bg_img {    
    /* background-image: url(../../../public/images/About\ us\ banner\ image\ 1\ \(1\).png); */

     width: 100%;
     background-size: 100% 100%;
     background-position: cover / center;
}

.ourTeam_background__img {
    background-image: url(../../../public/images/About\ us\ background\ image\ 1.png);
    width: 100%;
    background-size: cover;
    background-position: center;
}

.aboutUs_banner_heading  {
    font-family: 'Poppins', sans-serif;
    font-size: 96px;
    font-style: normal;
    font-weight: 500;
    line-height: 70px;
    color: #FFFFFF;
}

.whatWeDo_page_heading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    width: 30%;
}

@media only screen and (max-width: 640px) {
    .whatWeDo_page_heading{
        width: 100%;
    }
}

.whatWeDo_page_text {
    color: #131319;
    font-family: 'Lora';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    width: 70%;
}

.outTeam__subHeading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    color: #FFFFFF;
    width: 30%;
}

.outTeam__content__text {
    color: #131319;
    font-family: 'Lora';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
    width: 70%;
}

.aboutus_body_background {
    background: #FAFAFA;
}

.guide__value__heading {
    color: #EDE7FF;
    font-family: 'Lora';
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
}

.guide__value__contentText {
    color: #131319;
    font-family: 'Lora';
    font-size: 16px;
    font-style: normal;
    line-height: 150%; 
}

.padding_xAxis {
    padding: 0px 124px;
}

@media all and (max-width:1279px) {
    .padding_xAxis {
        padding: 0px 00px;
    }
}

@media all and (max-width:1023px) {
    .whatWeDo_page_text {
        width: 100%;
        padding-top: 20px;
    }
    .outTeam__subHeading {
        width: 100%;
    }
    .outTeam__content__text {
        width: 100%;
    }
}

@media all and (max-width:767px) {
    .whatWeDo_page_text {
        width: 100%;
        padding-top: 20px;
    }
    .outTeam__subHeading {
        width: 100%;
    }
    .AboutUs_bg_img {
        height: 60vh;
    }
}

@media all and (max-width:655px) {
    .AboutUs_bg_img {
        height: 50vh;
    }
    .aboutUs_banner_heading {
        font-size: 76px;
    }
}

@media all and (max-width:520px) {
    .AboutUs_bg_img {
        height: 40vh;
    }
    .aboutUs_banner_heading {
        font-size: 60px;
    }
}
