
  
  .events {
    font-family: "Poppins", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 96px;
    color: #ffb40d;
    /* display: flex;
    align-items: center;
    justify-content: center;
  */
  }
  .event_image {
    width: 100%;
    height: 300px;
  }
  .paragraph {
    font-family: "Lora", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
  }
  
  .body_heading {
    font-family: "Poppins", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 22px;
    color: #131319;
  }
  .body_heading2 {
    font-family: "Poppins", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    color: #131319;
  }
  .event_card {
    width: 550px;
  }
  .event_image {
    display: flex;
    justify-content: center;
  }
  @media all and (max-width:1170px) {
    .event_card {
      width: 500px;
      padding: 35px;
    }
  }
  @media all and (max-width:1100px) {
    .event_card {
      width: 480px;
      padding: 34px;
    }
    .event_image {
      width: 100%;
      height: 200px;
    }
  }
  @media all and (max-width:1040px) {
    .event_card {
      width: 460px;
      padding: 32px;
    }
    .event_image {
      width: 100%;
      height: 200px;
    }
    .paragraph {
      font-family: "Lora", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
  }
  .style__bgImage {
    height: 60vh;
  }
  .events {
    font-size: 80px;
  }
  }
  @media all and (max-width:995px) {
    .event_card {
      width: 550px;
    }
    .event_image {
      width: 100%;
      height: 200px;
    }
    .paragraph {
      font-family: "Lora", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
  }
  .event_page {
    display: block;
  }
  .style__bgImage {
    height: 50vh;
  }
  .events {
    font-size: 68px;
  }
  }
  @media all and (max-width:650px) {
    .event_card {
      width: 500px;
      padding: 35px;
    }
    .event_image {
      width: 100%;
      height: 200px;
    }
    .style__bgImage {
      height: 43vh;
    }
    .events {
      font-size: 59px;
    }
  }
  @media all and (max-width:540px) {
    .event_card {
      width: 480px;
      padding: 30px;
    }
    .event_image {
      width: 100%;
      height: 200px;
    }
  }
  @media all and (max-width:520px) {
    .event_card {
      width: 460px;
      padding: 30px;
    }
    .event_image {
      width: 100%;
      height: 200px;
    }
    .paragraph {
      font-family: "Lora", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
  }
  }
  @media all and (max-width:500px) {
    .event_card {
      width: 440px;
      padding: 25px;
    }
    .style__bgImage {
      height: 35vh;
    }
    .event_image {
      width: 100%;
      height: 200px;
    }
    .paragraph {
      font-family: "Lora", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
  }
  .body_heading {
    font-family: "Poppins", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    color: #131319;
  }
  .body_heading2 {
    font-family: "Poppins", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    color: #131319;
  }
  }
  @media all and (max-width:470px) {
    .event_card {
      width: 400px;
      padding: 23px;
    }
    .event_image {
      width: 100%;
      height: 200px;
    }
    .paragraph {
      font-family: "Lora", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
  }
  }
  @media all and (max-width:440px) {
    .event_card {
      width: 350px;
      padding: 20px;
    }
    .event_image {
      width: 100%;
      height: 200px;
    }
    .paragraph {
      font-family: "Lora", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 12px;
  }
  .body_heading {
    font-family: "Poppins", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    color: #131319;
  }
  }
  @media all and (max-width:390px) {
    .event_card {
      width: 320px;
      padding: 10px;
    }
    .event_image {
      width: 100%;
      height: 200px;
    }
    .paragraph {
      font-family: "Lora", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
  }
  .body_heading {
    font-family: "Poppins", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    color: #131319;
  }
  .body_heading2 {
    font-family: "Poppins", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    color: #131319;
  }
  }
  @media all and (max-width:360px) {
    .event_card {
      width: 300px;
      padding: 10px;
    }
    .event_image {
      width: 100%;
      height: 200px;
    }
    .paragraph {
      font-family: "Lora", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 12px;
  }
  .body_heading {
    font-family: "Poppins", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    color: #131319;
  }
  .body_heading {
    font-family: "Poppins", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    color: #131319;
  }
  }
  @media all and (max-width:340px) {
    .event_card {
      width: 280px;
      padding: 8px;
    }
    .event_image {
      width: 100%;
      height: 200px;
    }
    .paragraph {
      font-family: "Lora", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 12px;
  }
  
  .body_heading {
    font-family: "Poppins", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    color: #131319;
  }
  }
  @media all and (max-width:320px) {
    .event_card {
      width: 250px;
      padding: 8px;
    }
    .event_image {
      width: 100%;
      height: 200px;
    }
    .paragraph {
      font-family: "Lora", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 10px;
  }
  
  .body_heading {
    font-family: "Poppins", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    color: #131319;
  }
  }
  