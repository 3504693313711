@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.navbar {
    display: flex;
    justify-content: space-between;
    /* justify-content: center; */
}
/* .image-size {
    height: 350%;
    width: 1500%;
} */
.background1 {
    background-image: url(../../../public/images/Banner\ 1.jpg);
}

 .image-card {
    width: 450px;
}
.image-size {
    width: 100%;
    height: 30vh;
    
}
/* .image-size {
    width: 100%;
    height: 35vh; 
 } */
 /* @media all and (max-width: 480px) {
    .image-size {
        width: 100%;
        height: 30vh;
    }
 } */
@media all and (max-width: 1470px) {
    .image-card {
        width: 420px;
    }
} @media all and (max-width: 1380px) {
    .image-card {
        width: 400px;
    }
    /* .image-size {
        height: 30px;
        width: 80px;
    } */
} @media all and (max-width: 1320px) {
    .image-card {
        width: 350px;
    }
    .image-size {
        height: 25vh;
    }
}
@media all and (max-width: 1170px) {
    .image-card {
        width: 400px;
    }
    .image-size {
        height: 25vh;
    }
}
@media all and (max-width: 1880px) {
    .image-card {
        width: 370px;
    }
    .image-size {
        height: 25vh;
    }
}
@media all and (max-width: 355px) {
    .image-card {
        width: 100%;
    }
    .image-size {
        height: 20vh;
    }
    .head4 {
        font-size: 20px;
    }
}
 .style__bgImage{
    background-image: url(../../../public/images/Banner\ 1.jpg);
    width: 100%;
    height: 70vh;
    background-size: 100% 100%;
    background-position: center / cover;
 }
 @media all and (max-width: 770px) {
    .style__bgImage {
        background-image: url(../../../public/images/Banner\ 1.jpg);
        width: 100%;
        height: 50vh;
    }
 }

 @media all and (max-width: 500px) {
    .style__bgImage {
        background-image: url(../../../public/images/Banner\ 1.jpg);
        width: 100%;
        height: 40vh;
    }
 }
 .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 170px;
 }
 .head1 {
    font-family: "Poppins", sans-serif;
    font-weight:500;
    font-style: normal;
    font-size: 96px;
    color: #4e2aad;
  }
  @media all and (max-width: 770px) {
    .head1 {
        font-size: 68px;
    }
    .head2 {
        font-size: 30px;
    }
  }
.head2 {
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: 500;
    line-height: 115%;
    color: #1a1a1a;
}
/* .blogs-all {
    margin-top: -65px;
    margin-left: -150px;
} */
.heading {
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 115%;
    color: #1a1a1a;
}
@media all and (max-width: 700px) {
    .heading {
        font-size: 20px;
    }
}

.heading2 {
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: 500;
    line-height: 115%;
    color: #1a1a1a;
}

@media (min-width: 640px) and (max-width: 1024px) {
    .heading2 {
        font-size: 40px;
    } 
}

@media only screen and (max-width: 640px) {
    .heading2 {
        font-size: 28px;
    } 
}
/* @media all and (max-width: 550px) {
    .heading {
        font-size: 18px;
    }
} */

.next {
    color: #4e2aad;
}
.head3 {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    line-height: 100%;
    color: #1a1a1a;
} @media all and (max-width: 430px) {
    .head3 {
        font-size: 30px;
        font-weight: 500;
    }
}
.para {
    font-family: 'Lora', serif;
    color: #667085;
    font-size: 15px;
}
.head4 {
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
    font-weight: 500; 
    font-style: normal;
    color: #1a1a1a;
}
.head5 {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 500; 
    font-style: normal;
}
.head6 {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500; 
    font-style: normal;
}
.paragraph_blog {
    color: #6941c6;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
}
.paragraph {
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    color: #1d2130;
}
/* @media all and (max-width: 700px) {
    .paragraph {
        font-size: 20px;
    }
}
@media all and (max-width: 400px) {
    .paragraph {
        font-size: 15px;
    }
} */
.arrow-bar {
    margin-top: -31px;
}
@media all and (max-width:450px) {
    .arrow-bar {
        margin-top: -25px;
    }
    .paragraph {
        font-family: 'Lora', serif;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        color: #1d2130;
    }
    .head6 {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500; 
        font-style: normal;
    }
    .head5 {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 500; 
        font-style: normal;
    }
    .head2 {
        font-size: 20px;
     }
     .head4 {
        font-size: 18px;
     }
}
.mobile-nav {
    @apply fixed top-28 bg-slate-50 left-0 w-full; 
}
html {
    scroll-behavior: smooth;
}
