
.instantPayment__heading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 56px;
    font-weight: 500;
    line-height: 110%;
    margin-right: 160px;
}

.instantPayment__bodyText {
    color: #131319;
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-right: 130px;
}

.instantPayment__banner video{
    width: 100%;
    border: none;
}

.instantPayment__bannerVideo{
    position: relative;
    height: 650px;
}

@media (min-width:768px) and (max-width:1023px) {
    .instantPayment__bannerVideo{
        position: relative;
        height: 850px;
    }
    .instantPayment__bodyText  {
        text-align: center;
    }
    .instantPayment__heading {
        text-align: center;
    }
    .contact_us_btn {
        justify-content: center;
    }
}

@media (min-width:640px) and (max-width:768px) {
    .instantPayment__bannerVideo{
        position: relative;
        height: 830px;
    }
}

@media (min-width:581px) and (max-width:640px) {
    .instantPayment__bannerVideo{
        position: relative;
        height: 800px;
    }
}

@media all and (max-width:581px) {
    .instantPayment__bannerVideo{
        position: relative;
        height: 750px;
    }
}


.instantPayment__bannerVideo video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.instantPayment__bannerTitle{
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.instantPayment_img1 {
    width: 100%;
    height: 405px;
  object-fit: cover;
  }

.create__paymentLink__btn {
    display: flex;
    padding:  16px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius:  4px;
    background: #4E2AAD;
    box-shadow: 0px -2.6px 0px 0px #4E2AAD inset;
    color:#FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.instantPayment__banner__background {
    padding: 0px 124px;
    gap: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.instantPayment_heaidng {
    color: #131319;
    font-family: 'Poppins';
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.instantPayment_paraText {
    color: #131319;
    font-family: 'Lora';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; 
}

.haoda {
    color: #392675;
}

.pay {
    color: #F4B936;
   }


@media all and (max-width:1535px) {
    .instantPayment__heading {
        margin-right: 100px;
    }
    .instantPayment_img1 {
        height: 380px;
        width: 100%;
    }
    .instantPayment__banner__background {
        padding: 0px 50px;
    }
}

@media all and (max-width:1279px) {
    .instantPayment__banner__background {
        padding: 0px 40px;
    }
    .instantPayment__heading {
        margin-right: 50px;
        font-size: 46px;
    }
    .instantPayment_img1 {
        height: 320px;
        width: 100%;
    }
}

@media all and (max-width:1023px) {
    .instantPayment__banner__background {
        padding: 0px 10px;
        display: block;
    }
    .instantPayment__heading {
        margin-right: 0px;
        font-size: 56px;
    }
    .instantPayment_img1 {
        height: 405px;
    }
    .instantPayment__bodyText {
        margin-right:30px;
        margin-left: 30px;
    }
}

@media all and (max-width:580px) {
    .instantPayment__heading {
        font-size: 46px;
    }
    .instantPayment_img1 {
        height: 350px;
    }
}

@media all and (max-width:480px) {
    .instantPayment__heading {
        font-size: 40px;
    }
    .instantPayment__banner__background {
        padding: 0px 10px;
    }
}

@media all and (max-width:420px) {
    .instantPayment__heading {
        font-size: 36px;
    }
    .instantPayment__bodyText {
         font-size: 15px;
    }
    .instantPayment_img1 {
        height: 320px;
    }
}

@media all and (max-width:380px) {
    .instantPayment__heading {
        font-size: 30px;
    }
    .instantPayment__bodyText {
         font-size: 14px;
    }
    .create__paymentLink__btn {
        padding:  14px 30px;
        font-size: 14px;
    }
    .instantPayment_img1 {
        height: 300px;
    }
}

 /* nextPage */

.choose_instantPayment_heading {
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 500;
}

.gradient_instantPayment {
    background: linear-gradient(54deg, #5F72BD 15.02%, #9B23EA 47.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.choose_instantPayment_paraText {
    color: #131319;
    font-family: 'Lora', serif ;
    font-size: 16px;
    font-weight: 400;
    margin-right: 100px;
}

.instantPayment_chooseImg {
     width: 400px;
     height: 400px;
}

.ChooseInstantPayments_page {
    margin: 0px 124px;
}

@media (min-width:1280px) and (max-width:1536px) {
    .choose_instantPayment_paraText {
        margin-right: 140px;
    }
}

@media (min-width:1024px) and (max-width:1280px) {
     .choose_instantPayment_paraText {
        margin-right: 50px;
     }
     .ChooseInstantPayments_page {
        margin: 0px 50px;
     }
     .instantPayment_chooseImg {
        width: 350px;
        height: 350px;
     }
}

@media (min-width:640px) and (max-width:1024px) {
     .choose_instantPayment_paraText {
          margin: 30px 50px;
      }
     .ChooseInstantPayments_page {
          text-align: center;
          margin: 0px 10px;
      }
     .instantPayment_chooseImg {
          width: 400px;
          height: 400px;
      }
}

@media (min-width:300px) and (max-width:640px) {
     .choose_instantPayment_paraText {
        margin: 20px 20px;
      }
     .ChooseInstantPayments_page {
        text-align: center;
        margin: 0px 10px;
      }
     .instantPayment_chooseImg {
         width: 350px;
         height: 350px;
      }
}

@media (min-width:300px) and (max-width:410px) {
     .choose_instantPayment_paraText {
        margin: 10px 10px;
        font-size: 14px;
      }
      .ChooseInstantPayments_page {
         text-align: center;
         margin: 0px 10px;
      }
      .instantPayment_chooseImg {
          width: 300px;
          height: 300px;
      }
      .choose_instantPayment_heading {
          font-size: 24px;
      }
}

.InstantPaymentWorks_subHeading {
    color: #131319;
    font-family: 'Poppins';
    font-size: 22px;
    font-weight: 500;
    margin: 16px 0px;
}

.InstantPaymentWorks_paraText {
    color: #131319;
    font-family: 'Lora';
    font-size: 16px;
    font-weight: 400;
}

.InstantPaymentWorks_Heading {
    color: #131319;
    text-align: center;
    font-family: 'Poppins';
    font-size: 48px;
    font-weight: 500;
}
.InstantPaymentWorks_Text {
    color: #131319;
    text-align: center;
    font-family: 'Lora';
    font-size: 18px;
    font-weight: 400;
}

.InstantPaymentWorks_page {
    margin: 0px 80px ;
}

@media (min-width:1024px) and (max-width:1280px) {
    .InstantPaymentWorks_page {
    margin: 0px 10px ;
}
}

@media (min-width:768px) and (max-width:1024px) {
    .InstantPaymentWorks_page {
        margin: 0px 10px ;
    }
}
@media (min-width:640px) and (max-width:768px) {
    .InstantPaymentWorks_page {
    margin: 0px 10px ;
}
.InstantPaymentWorks_subHeading {
    font-size: 20px;
    margin: 16px 0px;
}

.InstantPaymentWorks_paraText {
    font-size: 14px;
}
.InstantPaymentWorks_Heading {
    font-size: 38px;
}
.InstantPaymentWorks_Text {
    font-size: 16px;
}
}

@media (min-width:200px) and (max-width:640px) {
    .InstantPaymentWorks_page {
         margin: 0px 10px ;
    }
.InstantPaymentWorks_paraText {
    font-size: 14px;
}
.InstantPaymentWorks_Heading {
    font-size: 30px;
}
.InstantPaymentWorks_Text {
    font-size: 16px;
}
.InstantPaymentWorks_boxs {
    margin: 20px;
}
}

.Customizable_svg {
    width: 230px;
    height: 230px;
    position: absolute;
    bottom: 0%;
    right: 0%;
    overflow: hidden;
    transition: 0.6s ease-in-out;
    object-fit: cover;
}
/* gefwitfe */


.PaymentTechnology_box:hover { 
    .Customizable_svg {
    transition: 0.6s ease-in-out;
    transform:scale(1.2);
    object-fit: cover;
    overflow: hidden;
}
}

.PaymentTechnology_box {
    border-radius: 22px;
    background: #F3EEFF;
    height: 394px;
    width: 581px;
    position: relative;
    padding: 30px 0px 0px 30px;
    overflow: hidden;
}

.Agile_Services_box {
    border-radius: 22px;
    background: #FFEEF1;
    height: 394px;
    width: 581px;
    position: relative;
    overflow: hidden;
}

.Agile_Services_box:hover { 
    .Customizable_svg {
    transition: 0.6s ease-in-out;
    transform:scale(1.2);
    object-fit: cover;
    overflow: hidden;
}
}

.RobustProcessing_box {
    border-radius: 22px;
    background: #EEFBFF;
    height: 394px;
    position: relative;
    width: 581px;
    overflow: hidden;
}

.RobustProcessing_box:hover { 
    .Customizable_svg {
    transition: 0.6s ease-in-out;
    transform:scale(1.2);
    object-fit: cover;
    overflow: hidden;
}
}

.Customizable_box {
    border-radius: 22px;
    background: #FAFFEE;
    height: 394px;
    position: relative;
    width: 581px;
    overflow: hidden;
}

.Customizable_box:hover { 
    .Customizable_svg {
    transition: 0.6s ease-in-out;
    transform:scale(1.2);
    object-fit: cover;
    overflow: hidden;
}
}

.instantPayment_boxHeading {
    color: #050B20;
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 500;
    width: 349px;
}

.instantPayment_boxText {
    color: #050B20;
    font-family: 'Lora';
    font-size: 15px;
    font-weight: 400;
    width: 349px;
}

.Customizable_svg {
    width: 230px;
    height: 230px;
    position: absolute;
    bottom: 0%;
    right: 0%;
}

.paymentBoxes {
    margin: 0px 114px;
}

@media (min-width:1280px) and (max-width:1536px) {
    .Customizable_svg {
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: 0%;
    right: 0%;
}
.PaymentTechnology_box {
    height: 354px;
    width: 551px;
    position: relative;
    padding: 30px 0px 0px 30px;
}
.Agile_Services_box {
    height: 354px;
    width: 551px;
    position: relative;
}

.RobustProcessing_box {
    height: 354px;
    width: 551px;
    position: relative;
}

.Customizable_box {
    height: 354px;
    width: 551px;
    position: relative;
}
.paymentBoxes {
    margin: 0px 50px;
}
}

@media (min-width:1023px) and (max-width:1280px) {
     .Customizable_svg {
    width: 170px;
    height: 170px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.PaymentTechnology_box {
    height: 304px;
    width: 480px;
    position: relative;
    padding: 30px 0px 0px 30px;
}
.Agile_Services_box {
    height: 304px;
    width: 480px;
    position: relative;
    padding: 30px 0px 0px 30px;
}

.RobustProcessing_box {
   height: 304px;
    width: 480px;
    position: relative;
    padding: 30px 0px 0px 30px;
}

.Customizable_box {
    height: 304px;
    width: 480px;
    position: relative;
    padding: 30px 0px 0px 30px;
}
.paymentBoxes {
    margin: 0px 10px;
}
.instantPayment_boxText {
    font-size: 14px;
    width: 300px;
}
.instantPayment_boxHeading {
    font-size: 24px;
    width: 300px;
}
}

@media (min-width:768px) and (max-width:1024px) {
.Customizable_svg {
    width: 120px;
    height: 120px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.PaymentTechnology_box {
    height: 264px;
    width: 350px;
    position: relative;
    padding: 30px 0px 0px 30px;
}
.Agile_Services_box {
    height: 264px;
    width: 350px;
    position: relative;
    padding: 30px 0px 0px 30px;
}

.RobustProcessing_box {
    height: 264px;
    width: 350px;
    position: relative;
    padding: 30px 0px 0px 30px;
}

.Customizable_box {
    height: 264px;
    width: 350px;
    position: relative;
    padding: 30px 0px 0px 30px;
}
.paymentBoxes {
    margin: 0px 10px;
}
.instantPayment_boxText {
    font-size: 12px;
    width: 220px;
}
.instantPayment_boxHeading {
    font-size: 20px;
     width: 280px;
}

}

@media (min-width:640px) and (max-width:768px) {
    .Customizable_svg {
        width: 120px;
        height: 120px;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    
    .PaymentTechnology_box {
        height: 224px;
        width: 300px;
        position: relative;
        padding: 10px 0px 0px 10px;
    }
    .Agile_Services_box {
        height: 224px;
        width: 300px;
        position: relative;
        padding: 10px 0px 0px 10px;
    }
    
    .RobustProcessing_box {
        height: 224px;
        width: 300px;
        position: relative;
        padding: 10px 0px 0px 10px;
    }
    
    .Customizable_box {
        height: 224px;
        width: 300px;
        position: relative;
        padding: 10px 0px 0px 10px;
    }
    .paymentBoxes {
        margin: 0px 10px;
    }
    .instantPayment_boxText {
        font-size: 10px;
        width: 190px;
    }
    .instantPayment_boxHeading {
        font-size: 18px;
         width: 280px;
    }
}

@media (min-width:500px) and (max-width:639px) {

 .Customizable_svg {
    width: 170px;
    height: 170px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.PaymentTechnology_box {
    height: 304px;
    width: 480px;
    position: relative;
    padding: 30px 0px 0px 30px;
}
.Agile_Services_box {
    height: 304px;
    width: 480px;
    position: relative;
    padding: 30px 0px 0px 30px;
}

.RobustProcessing_box {
   height: 304px;
    width: 480px;
    position: relative;
    padding: 30px 0px 0px 30px;
}

.Customizable_box {
    height: 304px;
    width: 480px;
    position: relative;
    padding: 30px 0px 0px 30px;
}
.paymentBoxes {
    margin: 0px 10px;
}
.instantPayment_boxText {
    font-size: 14px;
    width: 300px;
}
.instantPayment_boxHeading {
    font-size: 24px;
     width: 300px;
}
}

@media (min-width:440px) and (max-width:500px) {

 .Customizable_svg {
    width: 140px;
    height: 140px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.PaymentTechnology_box {
    height: 284px;
    width: 420px;
    position: relative;
    padding: 30px 0px 0px 30px;
}
.Agile_Services_box {
    height: 284px;
    width: 420px;
    position: relative;
    padding: 30px 0px 0px 30px;
}

.RobustProcessing_box {
   height: 284px;
    width: 420px;
    position: relative;
    padding: 30px 0px 0px 30px;
}

.Customizable_box {
    height: 284px;
    width: 420px;
    position: relative;
    padding: 30px 0px 0px 30px;
}
.paymentBoxes {
    margin: 0px 10px;
}
.instantPayment_boxText {
    font-size: 13px;
    width: 280px;
}
.instantPayment_boxHeading {
    font-size: 20px;
     width: 280px;
}
}

@media (min-width:375px) and (max-width:440px) {
     .Customizable_svg {
    width: 130px;
    height: 130px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.PaymentTechnology_box {
   height: 250px;
    width: 350px;
    position: relative;
    padding: 20px 0px 0px 20px;
}
.Agile_Services_box {
    height: 250px;
    width: 350px;
    position: relative;
    padding: 20px 0px 0px 20px;
}

.RobustProcessing_box {
   height: 250px;
    width: 350px;
    position: relative;
    padding: 20px 0px 0px 20px;
}

.Customizable_box {
    height: 250px;
    width: 350px;
    position: relative;
    padding: 20px 0px 0px 20px;
}
.paymentBoxes {
    margin: 0px 0px;
}
.instantPayment_boxText {
    font-size: 12px;
    width: 230px;
}
.instantPayment_boxHeading {
    font-size: 16px;
     width: 200px;
}  
}

@media (min-width:200px) and (max-width:375px) {
    .Customizable_svg {
   width: 100px;
   height: 100px;
   position: absolute;
   bottom: 0;
   right: 0;
}

.PaymentTechnology_box {
  height: 200px;
   width: 280px;
   position: relative;
   padding: 10px 0px 0px 10px;
}
.Agile_Services_box {
    height: 200px;
    width: 280px;
   position: relative;
   padding: 10px 0px 0px 10px;
}

.RobustProcessing_box {
    height: 200px;
    width: 280px;
   position: relative;
   padding: 10px 0px 0px 10px;
}

.Customizable_box {
    height: 200px;
    width: 280px;
   position: relative;
   padding: 10px 0px 0px 10px;
}
.paymentBoxes {
   margin: 0px 0px;
}
.instantPayment_boxText {
   font-size: 10px;
   width: 180px;
}
.instantPayment_boxHeading {
   font-size: 16px;
    width: 200px;
}  
}
