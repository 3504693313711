@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.education__heading {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 40px;
    color: #131319;
    line-height: 60px;
  }

  .education__body_text {
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 400;
    color: #1d2130;
}

.education__img{
  width: 405px;
  height: 405px;
  object-fit: cover;
}

.education__key{
  color: #131319;
  font-family: "Poppins", serif;
  font-size: 30px;
  font-weight: 500;
}

@media only screen and (max-width: 640px) {
  .education__key{
    font-size: 26px;
  }
  .education__keyPara{
    font-size: 14px;
  }
  .education__subKeyPara{
    font-size: 14px;
  }
}

.education__keyPara{
  color: #131319;
  font-size: 16px;
  font-weight: 600;
}

.education__keyPara{
  list-style: none;
}

.education__body_subheading{
  color: #131319;
  font-family: "Poppins", serif;
  font-size: 22px;
  font-weight: 500;
}

.education__subKeyPara{
  color: #131319;
  font-family: 'Lora', serif;
  font-size: 16px;
  font-weight: 400;
}


@media all and (max-width:1540px) {
  .education__heading {
    font-size: 36px;
  }
} @media all and (max-width:1280px) {
  .education__heading {
    font-size: 30px;
  }
  .EducationPhone {
    width: 10%;
    height: 35vh;
  }
}
  /* .button {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 22px;
    color: #e4e4e7;
    background-color: #4e2aad;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 40px;
    padding-left: 40px;
    border-radius: 2px;
    margin-top: 20px;
    box-shadow: 10px;
   
  } */


.education_bannerImage{
  position: relative;
  width: 100%;
  height: 530px;
}

.education_bannerVideo video{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.education_bannerContent{
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 430px) {
  .education_bannerImage{
    height: 750px;
  }
  .education__img{
    width: 100%;
    height: 100%;
  }
  .education__body_subheading {
    font-size: 20px;
  }
  .education__subKeyPara {
    font-size: 14px;
  }
  .education__subKeyPara {
    font-size: 14px;
  }
}

@media (min-width: 430px) and (max-width: 768px) {
  .education_bannerImage{
    position: relative;
    width: 100%;
    height: 800px;
  }
  .education__body_subheading {
    font-size: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .education_bannerImage{
    position: relative;
    width: 100%;
    height: 850px;
  }
}

  @media all and (max-width:1023px) {
    .EducationBoy {
      width: 70%;
      height: 45vh;
    }
  }
  @media all and (max-width:630px) {
    .EducationBoy {
      width: 90%;
      height: 40vh;
    }
    .education__heading {
      font-size: 25px;
      line-height: 40px;
    }
    .EducationPhone {
      width: 50%;
      height: 30px;
    }
    .EducationPhone {
      width: 25%;
      height: 10vh;
    }
  }
  @media all and (max-width:500px) {
    .EducationBoy {
      width: 80%;
      height: 40vh;
    }
    .education__heading {
      font-size: 25px;
      line-height: 40px;
    }
  }
  @media all and (max-width:400px) {
    .EducationBoy {
      width: 80%;
      height: 30vh;
    }
    .button {
      font-family: "Poppins", serif;
      font-weight:500;
      font-style: normal;
      font-size: 22px;
      color: #e4e4e7;
      background-color: #4e2aad;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-right: 20px;
      padding-left: 20px;
      border-radius: 2px;
      margin-top: 20px;
      box-shadow: 10px;
     
    }
    .education__heading {
      font-size: 22px;
      line-height: 30px;
    }
    .education__body_subheading {
      font-size: 18px;
    }
  }
  .EducationBoy {
    width: 405px;
    height: 405px;
  object-fit: cover;
  }
  .submit_button {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 22px;
    color: #e4e4e7;
    background-color: #4e2aad;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 60px;
    padding-left: 60px;
    border-radius: 5px;
    margin-top: 20px;
    box-shadow: 50px;
  }
  .EducationPhone {
    width: 100%;
    height: 405px;
  }
  .purple_page {
    background-color: #392675;
  }
  .education__heading1 {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 32px;
    color: #e4e4e7;
    line-height: 50px;
  }
  @media all and (max-width:900px) {
    .education__heading1 {
      font-size: 34px;
    }
  }
  @media all and (max-width:650px) {
    .education__heading1 {
      font-size: 30px;
    }
    .education__heading1 {
      font-size: 22px;
    }
  }
  @media all and (max-width:500px) {
    .education__heading1 {
      font-size: 26px;
      line-height: 40px;
    }
    .submit_button {
      font-family: "Poppins", serif;
      font-weight:500;
      font-style: normal;
      font-size: 22px;
      color: #e4e4e7;
      background-color: #4e2aad;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 40px;
      padding-left: 40px;
      border-radius: 5px;
      margin-top: 20px;
      box-shadow: 50px;
    }
  }
  .body_education__heading1 {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 40px;
    color: #131319;
    line-height: 50px;
  }
  .body_subeducation__heading {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 26px;
    color: #131319;
    line-height: 50px;
  }
 .list-style-image {
     padding-top: 20px;
 }
 .Education_key_Image {
    width: 405px;
 }
 .Education_reason_image {
     width: 70%;

 }
 .EduLogo_box {
  width: 500px;
  padding: 50px;
  height: fit-content;
}

 @media all and (max-width:500px) {
  .EduLogo_box {
    /* width: 300px; */
    height: fit-content;
    padding: 14px;
  }
  .body_subeducation__heading {
    font-size: 16px;
    padding: 0%;
}
  .EducationPhone {
    width: 100%;
    height: 45vh;
  }
}
@media (min-width: 500px) and (max-width:1535px) {
  .EduLogo_box {
    width: 400px;
    height: fit-content;
    padding: 30px;
  }
  .body_subeducation__heading {
      font-size: 20px;
  }
 }
@media all and (max-width:300px) {
  .EducationPhone {
    width: 100%;
    height: 40vh;
  }
}

.sign__btn button{
  border-radius: 4px;
  background: #4E2AAD;
  color: #FFF;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.sign__btn button:hover{
  box-shadow: 0px -2.6px 0px 0px #210077 inset;
}

.sign__btn button:hover .headerBannerNav__arrow{
  animation: moverightArrow 0.6s ease;
}

@keyframes moverightArrow {
  0%{
      transform: translateX(0px);
  }
  30%{
     transform: translateX(20px);
     display: none;
  }
  60%{
      transform: translateX(-15px);
      display: none;
  }
  100%{
      transform: translateX(0px);
  }
}