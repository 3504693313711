.clubAssociation__bannerBg{
    position: relative;
    width: 100%;
    height: 650px;
}

.clubAssociation__bannerContent{
    position: absolute;
    inset: 0;
}

.clubAssociation__BgVideo{
    width: 100%;
    height: 100%;
}

.clubAssociation__BgVideo video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.clubAssociation__BannerTitle{
    color: #131319;
    font-size: 56px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    line-height: 74px;
}

.clubAssociation__BannerPara{
    color: #131319;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Lora', serif;
}

.clubAssociation__img{
    width: 505px;
    height: 505px;
    border-radius: 14px;
    overflow: hidden;
    cursor: pointer;
}

.clubAssociation__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 14px;
    transition: 0.6s ease-in-out;
}

.clubAssociation__img img:hover{
    transform: scale(1.1);
    transition: 0.6s ease-in-out;
}

.clubPaymentMethod__contentTitle{
    background: linear-gradient(54deg, #5F72BD 15.02%, #9B23EA 47.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 30px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.clubPaymentMethod__contentPara{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Lora', serif;
}

.clubPaymentMethod__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
}

.clubPaymentMethod__img img{
    width: 405px;
    height: 405px;
    border-radius: 12px;
    transition: 0.6s ease-in-out;
}

.clubPaymentMethod__img img:hover{
    transform: scale(1.1);
    transition: 0.6s ease-in-out;
}




@media (min-width: 1024px) and (max-width: 1280px) {
    .clubAssociation__BannerTitle{
        font-size: 40px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .clubAssociation__BannerTitle{
        font-size: 40px;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .clubAssociation__img, .clubPaymentMethod__img{
        width: 355px;
        height: 345px;
        object-fit: cover;
        border-radius: 14px;
    }
    .clubAssociation__BannerTitle{
        font-size: 40px;
        line-height: 48px;
    }
}

@media (min-width: 430px) and (max-width: 640px) {
    .clubAssociation__img, .clubPaymentMethod__img{
        width: 355px;
        height: 345px;
        object-fit: cover;
        border-radius: 14px;
    }
    .clubAssociation__BannerTitle{
        font-size: 40px;
        line-height: 48px;
    }
    .clubAssociation__BannerPara{
        font-size: 14px;
        font-family: 'Lora', serif;
    }
}
@media only screen and (max-width: 620px) {
    .clubAssociation__bannerBg{
        height: 700px;
    }
}


@media only screen and (max-width: 430px) {
    .clubAssociation__img, .clubPaymentMethod__img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 14px;
    }
    .clubAssociation__BannerTitle{
        font-size: 32px;
        line-height: 44px;
    }
    .clubAssociation__BannerPara{
        font-size: 14px;
    }
}


@media (min-width: 1024px) and (max-width: 1280px) {
    .clubAssociation__img{
        width: 405px;
        height: 405px;
        object-fit: cover;
        border-radius: 14px;
    }
    .clubAssociation__BannerTitle{
        font-size: 40px;
        line-height: 64px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .clubAssociation__img{
        width: 405px;
        height: 405px;
        object-fit: cover;
        border-radius: 14px;
    }
    .clubAssociation__BannerTitle{
        font-size: 40px;
        line-height: 64px;
    }
}



@media (min-width: 1023px) and (max-width: 1280px) {
    .clubAssociation__bannerBg{
        position: relative;
        width: 100%;
        height: 530px;
    }
}


@media (min-width: 768px) and (max-width: 1023px) {
    .clubAssociation__bannerBg{
        position: relative;
        width: 100%;
        height: 850px;
    }
}

@media (min-width: 480px) and (max-width: 768px) {
    .clubAssociation__bannerBg {
        position: relative;
        width: 100%;
        height: 800px;
    }
}

@media (min-width: 390px) and (max-width: 480px) {
    .clubAssociation__bannerBg{
        position: relative;
        width: 100%;
        height: 850px;
    }
}


@media only screen and (max-width: 390px) {
    .clubAssociation__bannerBg{
        position: relative;
        width: 100%;
        height: 850px;
    }
}



/* Club society section style */

.clubSociety__title{
    color: #131319;
    font-size: 40px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.clubSociety__contentTitle{
    color: #131319;
    font-size: 22px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.clubSociety__contentPara{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Lora', serif;
}

.clubSociety__Img img{
    width: 387px;
    height: 285px;
    object-fit: cover;
}

@media only screen and (max-width: 640px) {
    .clubSociety__title{
        font-size: 30px;
        text-align: center;
    }
    .clubSociety__contentTitle {
        font-size: 20px;
        text-align: center;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .clubSociety__title{
        font-size: 38px;
    }
}

/* clubassociation payments method */

.clubchoosingPayment__heading{
    color: #131319;
    font-size: 40px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.clubchoosingPayment__headingHaoda{
    color: #392675;
    font-size: 40px;
    font-weight: 500;
}

.clubchoosingPayment__headingPay{
    color: #F4B936;
    font-size: 40px;
    font-weight: 500;
}

.clubchoosingPayment__box{
    width: 300px;
    border-radius: 12px;
    background: #F2EDFF;
    box-shadow: 4px 6px 13px 0px rgba(215, 215, 215, 0.25);
}

.clubchoosingPayment__box1{
    width: 300px;
    border-radius: 12px;
    background: #FFEDF8;
    box-shadow: 4px 6px 13px 0px rgba(215, 215, 215, 0.25);
}

.clubchoosingPayment__box2{
    width: 300px;
    border-radius: 12px;
    background: #EDF6FF;
    box-shadow: 4px 6px 13px 0px rgba(215, 215, 215, 0.25);
}

.clubchoosingPayment__box3{
    width: 300px;
    border-radius: 12px;
    background: #F3FFED;
    box-shadow: 4px 6px 13px 0px rgba(215, 215, 215, 0.25);
}

.clubchoosingPayment__boxTitle{
    color: #131319;
    font-size: 22px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.clubchoosingPayment__boxPara{
    color: #131319;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Lora', serif;
}

.clubchoosingPayment__boxImg{
    width: 100%;
    height: 200px;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
}

.clubchoosingPayment__boxImg img{
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
    transition: 0.6s ease-in-out;
}

.clubchoosingPayment__boxImg img:hover{
    transform: scale(1.1);
    transition: 0.6s ease-in-out;
}

@media only screen and (max-width: 310px) {
    .clubchoosingPayment__box1{
        width: 100%;
    }
}

@media only screen and (max-width: 640px) {
    .clubchoosingPayment__heading, .clubchoosingPayment__headingHaoda, .clubchoosingPayment__headingPay{
        font-size: 30px;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .clubchoosingPayment__heading, .clubchoosingPayment__headingHaoda, .clubchoosingPayment__headingPay{
        font-size: 38px;
    }
}
