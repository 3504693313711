
.heading1_event {
    font-family: "Poppins", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 48px;
    color: #131319;
  }
  .heading2_event {
    font-family: "Poppins", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 22px;
    color: #131319;
  }
  .paragraph_event {
    font-family: "Lora", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
  }
.paragraphsub_event{
    font-family: "Lora", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
}

.paragraphHeading_event{
    font-family: "Lora", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 17px;
}

.close__sliderEvent{
    color: #fff;
    font-size: 26px;
    position: fixed;
}

@media only screen and (max-width: 640px) {
    .close__sliderEvent{
        font-size: 20px;
        top: 18%;
        right: 2%;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .close__sliderEvent{
        top: 10%;
        right: 2%;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {
    .close__sliderEvent{
        top: 20%;
        right: 2%;
    }

}

  @media all and (max-width:900px) {
       .heading1_event {
        font-size: 40px;
       }
  }
  @media all and (max-width:700px) {
    .heading1_event {
     font-size: 35px;
    }
    .all_events {
        font-size: 40px;
        }
}
@media all and (max-width:900px) {
    .heading1_event {
     font-size: 30px;
    }
    .all_events {
        font-size: 40px;
        }
}
@media all and (max-width:570px) {
    .heading2_event {
     font-size: 20px;
    }
    
}
@media all and (max-width:470px) {
        .heading2_event {
         font-size: 18px;
        }
    }
.all_events {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #1D2130;
    font-size: 56px;
    }
.iamge1 {
    width: 100%;
    height: 80vh;
    object-fit: cover;
}
.paragraph_event {
    list-style-type:disc;

}
.paragraph_event li {
    color: #4e2aad;
}
@media all and (max-width:1100px) {
    .iamge1 {
        width: 100%;
        height: 60vh;
    }
}
@media all and (max-width:1000px) {
    .iamge1 {
        width: 100%;
        height: 40vh;
    }
}
@media all and (max-width:500px) {
    .iamge1 {
        width: 100%;
        height: 35vh;
    }
}
@media all and (max-width:400px) {
    .iamge1 {
        width: 100%;
        height: 30vh;
    }
}

/* Slider style */

.slick-prev{
    left: 0px !important;
    z-index: 1; 
}

.slick-next{
    right: 25px !important;
}

.slick-prev:before{
    font-size: 40px !important;
}

.slick-next:before{
    font-size: 40px !important;
}

.slider__eventImg img{
    width: 100%;
    height: 480px;
    object-fit: cover;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .slider__eventImg img{
        height: 340px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .slider__eventImg img{
        height: 400px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .slider__eventImg img{
        height: 350px;
    }
}

/* Slider Popup */

.sliderPopup__container{
    width: 100%;
    height: 100vh;
    position: fixed;
    inset: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
}

.sliderPopup__content{
    width: 100%;
    height: 100%;
    display: flex;
}

.sliderPopup__containerImg img{
    width: 100%;
    height: 600px;
    object-fit: cover;
}

.eventPage__video iframe{
    height: 720px;
}

@media only screen and (max-width: 640px) {
    .sliderPopup__containerImg img{
        width: 100%;
        height: 400px;
        object-fit: cover;
    }
   
}

@media (min-width: 640px) and (max-width: 768px) {
    .sliderPopup__containerImg img{
        width: 100%;
        height: 500px;
        object-fit: cover;
    }
    .eventPage__video iframe{
        height: 400px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .sliderPopup__containerImg img{
        width: 100%;
        height: 400px;
        object-fit: cover;
    }
    .eventPage__video iframe{
        height: 600px;
    }
}

@media only screen and (max-width: 425px) {
    .eventPage__video iframe{
        height: 250px;
    }
}

@media (min-width: 425px) and (max-width: 640px) {
    .eventPage__video iframe{
        height: 300px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .eventPage__video iframe{
        height: 500px;
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .eventPage__video iframe{
        height: 550px;
    }
}