.healthcare__bannerBg{
    position: relative;
    width: 100%;
    height: 650px;
}

.healthcareBanner__img{
    position: relative;
    height: 550px;
}

.healthcareBanner__bannerBg video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.healthcareBanner__bannerContent{
    position: absolute;
    inset: 0;
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .healthcare__bannerBg{
        position: relative;
        width: 100%;
        height: 530px;
    }
}


@media (min-width: 768px) and (max-width: 1024px) {
    .healthcare__bannerBg{
        position: relative;
        width: 100%;
        height: 730px;
    }
}

/* @media (min-width: 480px) and (max-width: 768px) {
    .healthcare__bannerBg{
        position: relative;
        width: 100%;
        height: 800px;
    }
} */

/* @media (min-width: 390px) and (max-width: 480px) {
    .healthcare__bannerBg{
        position: relative;
        width: 100%;
        height: 850px;
    }
}


@media only screen and (max-width: 390px) {
    .healthcare__bannerBg{
        position: relative;
        width: 100%;
        height: 850px;
    }
} */

@media  only screen and (max-width: 768px) {
    .healthcare__bannerBg{
        position: relative;
        height: 650px;
    }
    
}

@media (min-width: 768px) and (max-width: 1024px) {
    .healthcareBanner__img{
        position: relative;
        height: 700px;
    }
    
}

.healthCare__title{
    color: #FFFFFF;
    font-size: 56px;
    font-weight: 500;
    line-height: 74px;
    font-family: 'Poppins', sans-serif;
}

.healthCare__img img{
    width: 405px;
    height: 405px;
    object-fit: cover;
    border-radius: 14px;
}

@media only screen and (max-width: 430px) {
    .healthCare__img img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 14px;
    }
    .healthCare__title{
        font-size: 32px;
        line-height: 44px;
    }
}

@media (min-width: 430px) and (max-width: 640px) {
    .healthCare__img img{
        width: 355px;
        height: 345px;
        object-fit: cover;
        border-radius: 14px;
    }
    .healthCare__title{
        font-size: 32px;
        line-height: 48px;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .healthCare__img img{
        width: 355px;
        height: 345px;
        object-fit: cover;
        border-radius: 14px;
    }
    .healthCare__title{
        font-size: 40px;
        line-height: 54px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .healthCare__img img{
        width: 405px;
        height: 405px;
        object-fit: cover;
        border-radius: 14px;
    }
    .healthCare__title{
        font-size: 40px;
        line-height: 64px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .healthCare__img img{
        width: 405px;
        height: 405px;
        object-fit: cover;
        border-radius: 14px;
    }
    .healthCare__title{
        font-size: 48px;
        line-height: 64px;
    }
}

/* healthcare service style */

.tab__heading{
    color: #1D2130;
    font-size: 48px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.tab__headingHaoda{
    color: #392675;
    font-size: 48px;
    font-weight: 500;
}

.tab__headingPay{
    color: #F4B936;
    font-size: 48px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.healthCare__serviceTitle{
    color: #131319;
    font-size: 48px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.healthCare__serviceHaodaTitle{
    color: #392675;
    font-size: 48px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.healthCare__servicePayTitle{
    color: #F4B936;
    font-size: 48px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.healthCare__serviceContentTitle{
    color: #131319;
    font-size: 22px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.healthCare__servicePara{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: 'lora' , serif;
}

@media only screen and (max-width: 640px) {
    .healthCare__serviceTitle, .healthCare__serviceHaodaTitle, .healthCare__servicePayTitle{
        font-size: 30px;
    }
    .healthCare__serviceContentTitle {
        text-align: center;
    }

}

@media (min-width: 640px) and (max-width: 768px) {
    .healthCare__serviceTitle, .healthCare__serviceHaodaTitle, .healthCare__servicePayTitle{
        font-size: 30px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .healthCare__serviceTitle, .healthCare__serviceHaodaTitle, .healthCare__servicePayTitle{
        font-size: 40px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .tab__heading, .tab__headingHaoda, .tab__headingPay{
        font-size: 40px;
    }
}

@media only screen and (max-width: 768px) {
    .tab__heading, .tab__headingHaoda, .tab__headingPay{
        font-size: 30px;
    }
}

/* Tab section style */

.healthcare__tabContainer{
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
    position: relative;
    height: fit-content;
}

.healthcare__PreBtn{
    position: absolute;
    left: -30px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #4E2AAD;
    color: #FFF;
    font-size: 22px;
    top: 50%;
}

.healthcare__nextBtn{
    position: absolute;
    right: -30px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #4E2AAD;
    color: #FFF;
    font-size: 22px;
    top: 50%;
}

.swiper-slide{
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.housing__tabButtons{
    margin: auto 0;
}

.healthcare__tabImgContent{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Lora', serif;
}

.healthcare__tabImg{
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 52px;
    gap: 104px;
}

.react-tabs__tab{
    border: none;
    font-size: 17px;
    font-weight: 500;
    width: 213px;
    height: 72px;
    border: none;
    outline: none;
    padding: 10px 20px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.active-tab {
    border-radius: 5px;
    background: #4E2AAD;    
    color: #FFF;
    font-size: 17px;
    font-weight: 500;
    width: 213px;
    height: 72px;
    border: none;
    outline: none;
    padding: 10px 20px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

@media only screen and (max-width: 640px) {
    .tab__heading, .tab__headingHaoda, .tab__headingPay{
        font-size: 32px;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .tab__heading, .tab__headingHaoda, .tab__headingPay{
        font-size: 36px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .tab__heading, .tab__headingHaoda, .tab__headingPay{
        font-size: 38px;
    }
}

@media (min-width: 1280px) and (max-width:1535px) {
    .react-tabs__tab, .active-tab{
        font-size: 15px;
        font-weight: 500;
        width: 181px;
        height: 72px;    
    }
}

