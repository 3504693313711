@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');


.backend__banner{
    /* background: url(../../../public/images/CareerBanner__bgImg.png); */
    width: 100%;
    height: 70vh;
    background-size: 100% 100%;
    background-position: center;
    position: relative;
}

.backend__banner video{
    width: 100%;
    border: none;
}

.backend__bannerVideo{
    position: relative;
    z-index: -1;
}

.backend__bannerTitle{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.backend__bannerTitle span{
    color: #000;
    font-size: 96px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.career__form input{
    width: 100%;
    height: 56px;
    border: none;
    outline: none;
    border-radius: 14px;
    background: #F0F0F0;
}

.career__form input::placeholder{
    color: #B3B3B3;
    font-size: 16px;
    font-weight: 500;
}

.career__file{
    width: 100%;
    height: 180px;
    border-radius: 14px;
    border: 0.2px solid #4E2AAD;
    background: #F0F0F0;
}

.career__upload{
    color: #4E2AAD;
    font-size: 16px;
    font-weight: 500;
}

.career__document{
    color: #B3B3B3;
    font-size: 16px;
    font-weight: 500;

}

.career__documentFile{
    color: #B3B3B3;
    font-size: 10px;
    font-weight: 500;
    font-family: 'lora', serif;
}

.careerForm__btn button{
    width: 198px;
    height: 69px;
    border-radius: 4px;
    background: #4E2AAD;
    color: #FFF;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.careerPage__img img{
    width: 400px;
    height: 400px;
    object-fit: cover;
}

.content{
    display: none;
}

.non__active{
    color: #1D2130;
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
}

.careerActive{
    color: #4E2AAD;
    font-size: 22px;
    font-weight: 500;
    display: inline-block;
    border-bottom: 2px solid #4E2AAD;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
}

.show__content{
    display: block;
    
}

.job__applyBtn{
    width: 245px;
    height: 69px;
    border-radius: 4px;
    background: #4E2AAD;
    color: #FFF;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.career__ShareBtn{
    width: 245px;
    height: 69px;
    border-radius: 4px;
    border: 0.5px solid #4E2AAD;
    color: #4E2AAD;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.career__remote{
    color: #929292;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Lora', serif;
}

.careerContent{
    border-top: 1px solid #dedddd;;
}

.careerContent__para{
    color: #1D2130;
    font-family: "Lora", serif;
    font-size: 16px;
    font-weight: 400;
}

.careerContent__subTitle{
    color:#1D2130;
    font-family: "Lora", serif;
    font-size: 16px;
    font-weight: 600;
}

.careerContent__list li{
    list-style: disc;
    font-family: "Lora", serif;
    font-size: 16px;
}

.careerPage__applyNow{
    color: #131319;
    font-size: 22px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.jobDetails__contentLeft{
    position: sticky;
    top: 12%;
    height: 100%;
}

@media only screen and (max-width: 500px)  {
    .backend__bannerTitle span{
        font-size: 32px;
    }  
    .job__applyBtn, .career__ShareBtn{
        width: 166px;
        height: 51px;
        font-size: 18px;
    }
    .non__active, .careerActive{
        font-size: 16px;
    }
    .careerForm__btn button{
        width: 158px;
        height: 56px;
        font-size: 18px;
        font-weight: 600;
    }
    .backend__banner{
        height: 30vh;
    }
    .careerContent__subTitle{ 
        font-size: 14px;
    }
    .careerContent__para{
        font-size: 14px;
    }
    .careerContent__list li{
        list-style: disc;
        font-family: "Lora", serif;
        font-size: 14px;
    }
}

@media (min-width: 500px) and (max-width: 640px) {
    .backend__bannerTitle span{
        font-size: 36px;
    } 
    .job__applyBtn, .career__ShareBtn{
        width: 166px;
        height: 51px;
        font-size: 18px;
    }
    .non__active, .careerActive{
        font-size: 18px;
    }
    .careerForm__btn button{
        width: 158px;
        height: 56px;
        font-size: 18px;
        font-weight: 600;
    }
    .backend__banner{
        height: 30vh;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .backend__bannerTitle span{
        font-size: 46px;
    } 
    .job__applyBtn, .career__ShareBtn{
        width: 166px;
        height: 51px;
        font-size: 18px;
    }
    .non__active, .careerActive{
        font-size: 20px;
    }
    .careerForm__btn button{
        width: 158px;
        height: 56px;
        font-size: 18px;
        font-weight: 600;
    }
    .backend__banner{
        height: 40vh;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .backend__bannerTitle span{
        font-size: 56px;
    } 
    .job__applyBtn, .career__ShareBtn{
        width: 166px;
        height: 51px;
        font-size: 18px;
    }
    .career__file{
        width: 100%;
        height: 130px;
    }
    .careerForm__btn button{
        width: 158px;
        height: 56px;
        font-size: 18px;
        font-weight: 600;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .backend__bannerTitle span{
        font-size: 76px;
    } 
    .job__applyBtn, .career__ShareBtn{
        width: 200px;
        height: 60px;
        font-size: 20px;
    }
}

@media (min-width: 1280px) and (max-width: 1440px) {
    .backend__bannerTitle span{
        font-size: 86px;
    } 
}
