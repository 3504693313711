.haodapay__certificateBannerTop{
  margin-top: 60px;
}

.Banner_heading {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 56px;
    color: #131319;
    line-height: 60px;
  }

.Banner_para {
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    color: #1d2130;
    line-height: 30px;
}
.About_Banner_image {
    width: 100%;
    height: 55vh;
    padding-left: 30px;
}

.About1_Image{
    background-image: url(../../../public/images/aboutMission__img.png);
    width: 100%;
    height: 80vh;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
  }
  .About2_Image{
    background-image: url(../../../public/images/aboutVision__img.png);
    width: 100%;
    height: 80vh;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
  }
.Sub_heading {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 26px;
    color: #e4e4e7;
}
.about_paragraph {
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    color: #e4e4e7;
    line-height: 30px;   
}
.Banner_second {
  width: 50%;
}
.Banner_first {
    width: 50%;
    padding-left: 70px;
}
.About_Banner_page {
  display: flex;
  justify-content: center;
}

.aboutUs__bannerVideo{
  width: 100%;
  height: 650px;
  position: relative;
}

.aboutUs__bannerVideo video{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aboutUs__bannerTitle{
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
}

.aboutUs__bannerTitle span{
  color: #000;
  font-size: 96px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

@media only screen and (max-width: 640px) {
  .aboutUs__bannerVideo{
    height: 250px;
  }
}

@media (min-width: 640px) and (max-width: 768px) {
  .aboutUs__bannerVideo{
    height: 300px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .aboutUs__bannerVideo{
    height: 400px;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .aboutUs__bannerVideo{
    height: 400px;
  }
}

@media (min-width: 1280px) and (max-width: 1535px) {
  .aboutUs__bannerVideo{
    height: 500px;
  }
}

@media only screen and (max-width: 640px) {
  .aboutUs__bannerTitle span{
    font-size: 50px;
  }
}

@media (min-width: 640px) and (max-width: 768px) {
  .aboutUs__bannerTitle span{
    font-size: 70px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .aboutUs__bannerTitle span{
    font-size: 86px;
  }
}



@media all and (max-width:1510px) {
  .Banner_first {
    width: 50%;
    padding-left: 50px;
}
}
@media all and (max-width:1470px) {
  .Banner_first {
    width: 50%;
    padding-left: 50px;
}
  .Banner_heading {
    font-size: 50px;
  }
}
@media all and (max-width:1320px) {
  .Banner_first {
    width: 50%;
    padding-left: 50px;
}
  .Banner_heading {
    font-size: 45px;
  }
}
@media all and (max-width:1200px) {
  .Banner_first {
    width: 60%;
    padding-left: 50px;
}
  .Banner_heading {
    font-size: 45px;
  }
  .Banner_second {
    width: 40%;
  }
  .About_Banner_image {
    width: 100%;
    height: 43vh;
    padding-left: 5px;
    padding: 10px;
}
}
@media all and (max-width:1000px) {
  .Banner_first {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 0%;
}
  .Banner_heading {
    font-size: 56px;
  }
  .Banner_second {
    width: 100%;
  }
  .Banner_para{
    padding-left: 70px;
    padding-right: 70px;
    text-align: center;
  }
  .About_Banner_image {
    width: 100%;
    height: 43vh;
    padding: 10px;
}
.About_Banner_page {
  display: block;
  justify-content: center;
}
.About_Banner_image {
  width: 100%;
  height: 55vh;
  padding-left: 10px;
  padding-right: 10px;
}
.Banner_heading {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;

}
}
@media all and (max-width:750px) {
  .Banner_heading {
    font-size: 45px;
    line-height: 50px;
  }
  .Banner_para {
    @apply pt-5
  }
  
}

@media all and (max-width:570px) {
  .Banner_heading {
    font-size: 40px;
    line-height: 30px;
  }
  .Banner_para {
    padding-left: 10px;
    padding-right: 10px;
    @apply py-5
  }
  .About_Banner_image {
    width: 100%;
    height: 40vh;
    padding: 10px;
}
  
}
@media all and (max-width:500px) {
  .Banner_heading {
    font-size: 30px;
    line-height: 60px;
  }
  .Banner_para {
    font-size: 14px;
    @apply pt-5
  }
 
}
@media all and (max-width:390px) {
  .Banner_heading {
    font-size: 25px;
    line-height: 35px;
  }
  .Banner_para {
    font-size: 12px;
    @apply pt-3 py-5
  }
  .About_Banner_image {
    width: 100%;
    height: 32vh;
    padding: 5px;
}
}
.About_Image_text {
  @apply px-44
}


@media all and (max-width:1545px) {
  .About_Image_text {
    @apply px-36
  }
} @media all and (max-width:1420px) {
  .About_Image_text {
    @apply px-24 
  }
  .Banner_heading{
    line-height: 50px;
  }
  .Sub_heading {
    @apply py-5
  }
}
@media all and (max-width:1230px) {
  .About_Image_text {
    @apply px-16 
  }
  .About2_Image{
    background-image: url(../../../public/images/aboutMission__img.png);
    width: 100%;
    height: 60vh;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
  }
  .About1_Image{
    background-image: url(../../../public/images/aboutVision__img.png);
    width: 100%;
    height: 60vh;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
  }
}
 @media all and (max-width:1023px) {
  .About_Image_text {
    @apply px-48
  }
}
@media all and (max-width:855px) {
  .About1_Image{
    background-image: url(../../../public/images/aboutVision__img.png);
    width: 100%;
    height: 45vh;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
  }
  .About2_Image{
    background-image: url(../../../public/images/aboutMission__img.png);
    width: 100%;
    height: 45vh;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
  }
}
.Banner_text_page {
  @apply pl-40
}
 @media all and (max-width:1827px) {
    .Banner_text_page {
      @apply pl-32
    }
  } 
  @media all and (max-width:1763px) {
    .Banner_text_page {
      @apply pl-28
    }
  } 
  @media all and (max-width:1731px) {
    .Banner_text_page {
      @apply pl-20
    }
  } 
  @media all and (max-width:1667px) {
    .Banner_text_page {
      @apply pl-16
    }
  } 
  @media all and (max-width:1635px) {
    .Banner_text_page {
      @apply pl-10
    }
  } 
  @media all and (max-width:1588px) {
    .Banner_text_page {
      @apply pl-5
    }
  } 
  @media all and (max-width:1547px) {
    .Banner_text_page {
      @apply pl-0
    }
  } 
@media all and (max-width:800px) {
  .About_Image_text {
    @apply px-36
  }
}
@media all and (max-width:720px) {
  .About_Image_text {
    @apply px-28
  }
}
@media all and (max-width:645px) {
  .About_Image_text {
    @apply px-20
  }
}
@media all and (max-width:580px) {
  .about_paragraph {
    font-size: 12px;
    line-height: 25px;
 }
  .About_Image_text {
    @apply px-5 py-5
  
  }
  
  .About1_Image{
    background-image: url(../../../public/images/aboutVision__img.png);
    width: 100%;
    height: 40vh;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
  }
  .About2_Image{
    background-image: url(../../../public/images/aboutVision__img.png);
    width: 100%;
    height: 40vh;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
  }
}