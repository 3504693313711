@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rajdhani:wght@300;400;500;600;700&family=Teko:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rajdhani:wght@300;400;500;600;700&family=Teko:wght@300..700&display=swap');

body{
    background-color: #F9F9F9;
}

/* Banner section */

.testimonial__FaUser{
    font-size: 32px;
}

.headerBanner__arrow{
    font-size: 14px;
    margin-top: -4px;
}

.main__btn button{
    width: 386px;
    height: 56px;
    border-radius: 4px;
    outline: none;
    background-color: #4E2AAD;
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}

.main__btn button:hover{
    box-shadow: 0px -2.6px 0px 0px #210077 inset; 
}

.headerBanner__arrowContainer{
    width: 20px;
    height: 40px;
    overflow: hidden;
}


.main__btn button:hover .headerBanner__arrow{
    animation: moveArr 0.6s ease;
}

@keyframes moveArr {
    0%{
        transform: translateX(0px);
    }
    30%{
       transform: translateX(20px);
       display: none;
    }
    60%{
        transform: translateX(-10px);
        display: none;
    }
    100%{
        transform: translateX(0px);
    }
}

.home__circle{
    font-size: 6px;
    margin-top: -2px;
    margin-right: 6px;
}

.mainBanner__img{
    width: 100%;
    height: 100%;
    background-image: url(../../../public/images/bannerBg__img.png);
    background-size: 100% 100%;
    background-position: center / cover;
}

.main__heading{
    color: #131319;
    font-size: 56px;
    font-weight: 500;
    line-height: 74px;
    font-family: 'poppins', sans-serif;
}

.main__headingBlue{
    color: #4E2AAD;
    font-size: 56px;
    font-weight: 500;
}

.main__para{
    color: #131319;
    font-size: 18px;
    font-weight: 400;
    font-family: Lora;
}

.dashboard__img img{
    animation: img linear forwards;
    transition: 1s;
}


@keyframes img {
    from{
        transform: scale(0.7);
    }
    to{
        transform: scale(1);
    }
} 

@keyframes imgZoomIn {
    from {
        transform: scale(0.7); /* Start scale */
    }
    to {
        transform: scale(1);   /* End at full scale */
    }
}


@media only screen and (max-width: 640px) {
    .main__btn button{
        width: 300px;
        height: 52px;
        font-size: 12px;
        font-family: 'poppins', sans-serif;
    }
    .main__para{
        font-size: 16px;
    }
}

@media only screen and (max-width: 768px) {
    .main__heading, .main__headingBlue{
        font-size: 30px;
        line-height: 44px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .main__heading, .main__headingBlue{
        font-size: 50px;
    }
}

.single_platform_box {
    width: 380px;
}

@media (min-width: 1280px) and (max-width: 1535px) {
    .single_platform_box {
        width: 320px;
    }
}

@media (min-width: 1023px) and (max-width: 1280px) {
    .single_platform_box {
        width: 300px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .single_platform_box {
        width: 320px;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .single_platform_box {
        width: 280px;
    }
}

@media only screen and (max-width: 640px) {
    .single_platform_box {
        width: 100%;
    }
}

.marquee__container{
    height: 200px;
    filter: grayscale(1);
    overflow: hidden;
}

.marquee__container:hover{
    filter: grayscale(0);
    cursor: pointer;
}

.marquee img{
    width: 160px;
    height: 46px;
    margin: 0px 30px;
    object-fit: contain;
    display: inline-block;
}

@media only screen and (max-width: 768px) {
    .marquee img{
        width: 120px;
        height: 36px;
        margin: 0px 10px;
        object-fit: contain;
        display: inline-block;
    }
}

/* Why choose us style */

.whyChoose__container{
    background-color: #f4f4f4;
}

.whyChoose__containerLook{
    background-color: #fff;
}

.whyChoose__title{
    color: #131319;
    font-size: 48px;
    font-weight: 500;
    font-family: 'poppins', sans-serif;
}

.haoda__title{
    color: #392675;
    font-size: 48px;
    font-weight: 500;
}

.pay__title{
    color: #F4B936;
    font-size: 48px;
    font-weight: 500;
}

.whyChoose__para{
    color: #131319;
    font-size: 18px;
    font-weight: 400;
    font-family: "Lora", serif;
}

.global__title{
    color: #131319;
    font-size: 22px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.global__para{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: "Lora", serif;
}

.global__img{
    width: 56px;
    height: 56px;
    object-fit: contain;
}

.whyChoose__para1{
    color: #131319;
    font-size: 34px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 767px) {
    .whyChoose__title, .haoda__title, .pay__title{
        font-size: 30px;
    }  
    .whyChoose__para, .global__para{
        font-size: 14px;
    } 
    .whyChoose__para1{
        font-size: 22px;
    }
    .global__title{
        font-size: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .whyChoose__title, .haoda__title, .pay__title{
        font-size: 40px;
    }
}

/* Service style */

.service__heading{
    color: #131319;
    font-size: 48px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.service__box{
    background-color: #fff;
}

.service__title{
    background: linear-gradient(54deg, #5F72BD 15.02%, #9B23EA 47.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 34px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 640px) {
    .service__title{
        font-size: 26px;
    }
}

.fa-circle__icon{
    margin-top: -22px;
    display: inline-block !important;
}

.whyChoose__subTitle{
    color: #131319;
    font-size: 16px;
    font-weight: 700;
    font-family: "Lora", serif;
}

.service__para{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: "Lora", serif;
}

.service__btn{
    border-radius: 4px;
    background: #4E2AAD;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.service__btn:hover{
    box-shadow: 0px -2.6px 0px 0px #210077 inset;
}

.home__prodArrowContainer{
    width: 18px;
    overflow: hidden;
}

.headerBanner__arrow{
    font-size: 13px;
    margin-top: 2px;
}

.service__btn:hover .headerBanner__arrow{
    animation: moveArrow 0.4s ease;
}

.collection__payImg img{
    width: 505px;
    height: 505px;
    object-fit: cover;
}

@media only screen and (max-width: 320px) {
    .collection__payImg img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media (min-width: 320px) and (max-width: 640px) {
    .collection__payImg img{
        width: 299px;
        height: 299px;
        object-fit: cover;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .collection__payImg img{
        width: 405px;
        height: 405px;
        object-fit: cover;
    }
}

@media (min-width: 768px) and (max-width: 1535px) {
    .collection__payImg img{
        width: 405px;
        height: 405px;
        object-fit: cover;
    }
}

@keyframes moveArrow {
    0%{
        transform: translateX(0px);
    }
    30%{
       transform: translateX(10px);
       display: none;
    }
    60%{
        transform: translateX(-5px);
        display: none;
    }
    100%{
        transform: translateX(0px);
    }
}

@media only screen and (max-width: 767px) {
    .service__heading, .service__title{
        font-size: 30px;
    }
    .service__title{
        font-size: 24px;
    }
    .service__para{
        font-size: 14px;
    }
    .whyChoose__subTitle{
        font-size: 14px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .service__heading{
        font-size: 38px;
    }
}

/* payout style */

.upi__img{
    width: 580px;
    height: 440px;
    object-fit: contain;
}

.upi__title{
    font-size: 36px;
    font-weight: 500;
    background: linear-gradient(54deg, #5F72BD 15.02%, #9B23EA 47.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.upi__para{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
}

.fa-circle{
    font-size: 8px;
}

.upi__subTitle{
    color: #131319;
    font-size: 16px;
    font-weight: 600;
}

.upi__btn button{
    width: 290px;
    height: 56px;
    border: none;
    outline: none;
    border-radius: 4px;
    background: #4E2AAD;
    box-shadow: 0px -2.6px 0px 0px #210077 inset;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
}

/* Customised Payment Solutions style */

.customisedPayment__title{
    color: #131319;
    font-size: 48px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.customisedPayment__content{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: "Lora", serif;
}

.customisedPayment__box{
    width: 350px;
    height: 200px;
    transition: all .5s ease-in-out;
}

.customisedPayment__box:hover{
    border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(171, 190, 209, 0.40);
    transition: all .5s ease-in-out;
    cursor: pointer;
}

.customisedPayment__retail{
    color: #131319;
    font-size: 34px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 767px) {
    .customisedPayment__title{
        font-size: 32px;
    }
    .customisedPayment__content{
        font-size: 14px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .customisedPayment__title{
        font-size: 36px;
    }
}

@media only screen and (max-width: 768px) {
    .customisedPayment__box{
        width: 150px;
        height: 160px;
    }
    .customisedPayment__retail{
        font-size: 22px;
    }

}

@media (min-width: 769px) and (max-width: 1023px) {
    .customisedPayment__box{
        width: 200px;
        height: 160px;
    }
    .customisedPayment__retail{
        font-size: 22px;
    }
}

@media (min-width: 1023px) and (max-width: 1280px) {
    .customisedPayment__box{
        width: 250px;
        height: 160px;
    }
    .customisedPayment__retail{
        font-size: 22px;
    }
}

/* Account create section */

.accountCreate__img{
    width: 100%;
    height: 440px;
    /* background-image: url(../../../public/images/account__img.png); */
    background-position: center;
    background-size: 100% 100%;
}

.accountCreate__title{
    color: #131319;
    font-size: 24px;
    font-weight: 700;
}

.accountYour__btn{
    width: 400px;
    height: 69px;
    border-radius: 4px;
    background: #4E2AAD;
    color: #FFF;
    font-size: 25px;
    font-weight: 600;
    cursor: none;
}

.accountTeam__btn{
    width: 504px;
    height: 69px;
    border-radius: 4px;
    background: #4E2AAD;
    color: #FFF;
    font-size: 25px;
    font-weight: 600;
    cursor: none;
}

@media only screen and (max-width: 434px) {
    .accountCreate__title{
        font-size: 16px;
    }
    .accountCreate__img{
        height: fit-content;
    }
    .accountYour__btn{
        width: 140px;
        height: 42px;
        font-size: 12px;
        display: block;
    }
    .accountTeam__btn{
        width: 190px;
        height: 42px;
        font-size: 12px;
        display: block;
    }
    .accountCreate__btn{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 8px;
    }
}

@media (min-width: 435px) and (max-width: 539px) {
    .accountCreate__title{
        font-size: 20px;
    }
    .accountCreate__img{
        height: 230px;
    }
    .accountYour__btn{
        width: 140px;
        height: 42px;
        font-size: 12px;
    }
    .accountTeam__btn{
        width: 190px;
        height: 42px;
        font-size: 12px;
    }
}

@media (min-width: 540px) and (max-width: 639px) {
    .accountCreate__title{
        font-size: 20px;
    }
    .accountCreate__img{
        height: 230px;
    }
    .accountYour__btn{
        width: 180px;
        height: 48px;
        font-size: 14px;
    }
    .accountTeam__btn{
        width: 230px;
        height: 48px;
        font-size: 14px;
    }
}

@media (min-width: 640px) and (max-width: 767px ) {
    .accountCreate__title{
        font-size: 22px;
    }
    .accountCreate__img{
        height: 260px;
    }
    .accountYour__btn{
        width: 200px;
        height: 48px;
        font-size: 16px;
    }
    .accountTeam__btn{
        width: 280px;
        height: 48px;
        font-size: 16px;
    }
}

@media (min-width: 768px) and (max-width: 1024px ) {
    .accountCreate__img{
        height: 300px;
    }

    .accountYour__btn{
        width: 260px;
        height: 56px;
        font-size: 18px;
    }
    .accountTeam__btn{
        width: 340px;
        height: 56px;
        font-size: 18px;
    }
}

@media (min-width: 1024px) and (max-width: 1120px ) {
    .accountYour__btn{
        width: 320px;
        height: 62px;
        font-size: 20px;
    }
    .accountTeam__btn{
        width: 400px;
        height: 62px;
        font-size: 20px;
    }
}

/* Financial management style */

.financialManagement__title{
    color: #131319;
    font-size: 48px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.financialManagement__titleBlue{
    color: #210077;
    font-size: 48px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.financialManagement__titleManage{
    color: #F4B936;
    font-size: 48px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.keyBene__title{
    color: #131319;
    font-size: 34px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.financialManagement__box{
    width: 310px;
    transition: all .5s ease-in-out;
}

.financialManagement__box:hover{
    border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 2px 6px 0px rgba(171, 190, 209, 0.40);
    transition: all .5s ease-in-out;
    cursor: pointer;
}

.financialManagement__quick{
    color: #131319;
    font-size: 22px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.financialManagement__onboard{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: "Lora", serif;
}

@media only screen and (max-width: 768px) {
    .financialManagement__title, .financialManagement__titleBlue, .financialManagement__titleManage{
        font-size: 32px;
    }
    .keyBene__title{
        font-size: 26px;
    }
    .financialManagement__quick{
        font-size: 20px;
    }
    .financialManagement__onboard{
        font-size: 14px;
    }
}

@media only screen and (max-width: 1023px) {
    .financialManagement__box{
        width: 250px;
    }
}

/* FAQ style */ 

.faq__ques{
    width: 80px;
    height: 32px;
    border-radius: 8px;
    background: #4E2AAD;
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.12);
}

.faq__quesTitle{
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: "Poppins", sans-serif;
}

.faq__quesIcon{
    color: #FFF;
    font-size: 20px;
    font-weight: bold;
}

.faq__title{
    color: #131319;
    font-size: 48px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.faq__para{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: "Lora", serif;
}

.faq__contentTitle{
    color: #131319;
    font-size: 16px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.faq__container{
    width: 600px;
    border-radius: 14px;
    border: 1px solid rgba(23, 17, 17, 0.08);
    background-color: #fff;
}

.faq__contentPara{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: "Lora", serif;
}

.faq__content{
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
}

.faq__content.show{
    height: auto;
    max-height: 500px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
}

@media only screen and (max-width: 600px) {
    .faq__container{
        width: 100%;
    }
    .faq__contentPara{
        font-size: 14px;
    }
}

@media only screen and (max-width: 768px) {
    .faq__title{
        font-size: 32px;
    }
    .faq__para, .faq__contentTitle, .faq__content{
        font-size: 14px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .faq__title{
        font-size: 40px;
    }
}

/* Testimonial style */

.testimonial__loveContainer{
    width: 165px;
    height: 32px;
    border-radius: 34px;
    background: #FFF;
    box-shadow: 0px 5px 5px -3.5px rgba(0, 0, 0, 0.09), 0px 1.144px 1.144px -2.333px rgba(0, 0, 0, 0.18), 0px 0.301px 0.301px -1.167px rgba(0, 0, 0, 0.20), 0px 0px 0px 1px rgba(0, 0, 0, 0.10);
}

.testimonial__loveTitle{
    color: #3D515C;
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}

.testimonial__loveHeart{
    color: red;
}

.testimonial__title{
    color: #131319;
    font-size: 48px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.testimonial__box{
    width: 489px;
    height: 259px;
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 10px 10px -3.75px rgba(0, 0, 0, 0.06), 0px 2.289px 2.289px -2.5px rgba(0, 0, 0, 0.16), 0px 0.602px 0.602px -1.25px rgba(0, 0, 0, 0.18), 0px 0px 0px 1px rgba(0, 0, 0, 0.10);
}

.testimonial__subtitle{
    color: #F4B936;
    font-size: 22px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.testimonial__subtitlePrime{
    color: #392675;
    font-size: 22px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.testimonial__Img img{
    width: 64px;
    height: 64px;
    border-radius: 34px;
    object-fit: cover;
}

.testimonial__boxContent{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: "Lora", serif;
}

.testimonial__contentName{
    color: #131319;
    font-size: 16px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
}

.testimonial__contentCompany{
    color: #4A6778;
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 640px) {
    .testimonial__box{
        width: 380px;
        height: 230px;
    }
}

@media only screen and (max-width: 768px)  {
    .testimonial__title{
        font-size: 32px;
    }
    .testimonial__subtitle, .testimonial__subtitlePrime{
        font-size: 15px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .testimonial__title{
        font-size: 40px;
    }
    .testimonial__subtitle, .testimonial__subtitlePrime{
        font-size: 18px;
    }
    .testimonial__box{
        width: 460px;
        height: 230px;
    }
}

/* Blog style */

.featured__blogTitle{
    color: #131319;
    font-size: 22px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.blog__content{
    width: 384px;
    border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(171, 190, 209, 0.40);
}

.blogHome__img{
    width: 100%;
    height: 300px;
    overflow: hidden;
}

.blogHome__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.4s ease-in-out;
}

.blogHome__img img:hover{
    transform: scale(1.1);
    transition: 0.4s ease-in-out;
}


.blog__date{
    color: #6941C6;
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.blog__contentTitle{
    color: #1A1A1A;
    font-size: 22px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.blog__contentPara{
    color: #667085;
    font-size: 16px;
    font-weight: 400;
    font-family: "Lora", serif;
}

.featured__blogAll{
    color: #131319;
    font-size: 22px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 400px) {

    .blog__content{
        width: 100%;
    }
}

@media only screen and (max-width: 640px) {
    .blog__contentImg{
        height: 250px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .blog__content{
        width: 320px;
    }

    .blog__contentImg{
        height: 270px;
    }
}

@media only screen and (max-width: 767px) {
    .featured__blogTitle, .featured__blogAll{
        font-size: 16px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .blog__content{
        width: 350px;
    }
}

/* Partners Bank style */

.partnerBank__container{
    background: #fff;
}

.homepartnerBank__title{
    color: #131319;
    font-size: 22px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.sbiBank__img{
    width: 120px;
    height: 38px;
    object-fit: cover;
    display: block;
}

.indianbank__Img{
    width: 140px;
    height: 58px;
    object-fit: cover;
    display: block;
}

.partnerBank__img{
    width: 160px;
    height: 46px;
    object-fit: contain;
    display: block;
}

.cosmosBank__logo{
    width: 220px;
    height: 40px;
    object-fit: fill;
    display: block;
}

@media only screen and (max-width: 64px) {
    .homepartnerBank__title{
        font-size: 20px;
    }
}
