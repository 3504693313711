.housingBanner__img{
    /* background-image: url(../../../public/images/housingBanner__img.png); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.housingSociety__MainTitle{
    color: #131319;
    font-size: 56px;
    font-weight: 500;
    line-height: 74px;
}

.housingSociety__MainPara{
    color: #131319;
    font-size: 18px;
    font-weight: 400;
}

.clubAssociation__BannerPara {
    color: #131319;
    font-family: 'Lora', serif;
    font-size: 18px;
    font-weight: 400;
} 

.housingSociety__MainBtn{
    width: 174px;
    height: 56px;
    border-radius: 4px;
    background: #4E2AAD;
    box-shadow: 0px -2.6px 0px 0px #4E2AAD inset;
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
}

.housingSociety__MainBtn:hover{
    box-shadow: 0px -2.6px 0px 0px #210077 inset;
}

.housingSociety__MainImg img{
    width: 505px;
    height: 505px;
    object-fit: cover;
    border-radius: 14px;
}

@media only screen and (max-width: 430px) {
    .housingSociety__MainImg img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 14px;
    }
    .housingSociety__MainTitle{
        font-size: 32px;
        line-height: 44px;
    }
    .housingSociety__MainPara{
        font-size: 14px;
    }
    .housingSociety__MainBtn{
        width: 124px;
        height: 38px;
        font-size: 13px;
        font-weight: 600;
    }
}

@media (min-width: 430px) and (max-width: 640px) {
    .housingSociety__MainImg img{
        width: 355px;
        height: 345px;
        object-fit: cover;
        border-radius: 14px;
    }
    .housingSociety__MainTitle{
        font-size: 32px;
        line-height: 48px;
    }
    .housingSociety__MainPara{
        font-size: 14px;
    }
    .housingSociety__MainBtn{
        width: 144px;
        height: 42px;
        font-size: 14px;
        font-weight: 600;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .housingSociety__MainImg img{
        width: 355px;
        height: 345px;
        object-fit: cover;
        border-radius: 14px;
    }
    .housingSociety__MainTitle{
        font-size: 38px;
        line-height: 54px;
    }
    .housingSociety__MainBtn{
        width: 144px;
        height: 42px;
        font-size: 14px;
        font-weight: 600;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .housingSociety__MainImg img{
        width: 405px;
        height: 405px;
        object-fit: cover;
        border-radius: 14px;
    }
    .housingSociety__MainTitle{
        font-size: 44px;
        line-height: 64px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .housingSociety__MainImg img{
        width: 405px;
        height: 405px;
        object-fit: cover;
        border-radius: 14px;
    }
    .housingSociety__MainTitle{
        font-size: 48px;
        line-height: 64px;
    }
}



/* Welcome Section style */

.welCome__title{
    color: #131319;
    font-size: 40px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.welCome__titleHaoda{
    color: #392675;
    font-size: 40px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.welCome__titlePay{
    color: #F4B936;
    font-size: 40px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

/* .welcomeBox1 img{
    width: 100%;
    height: 198px;
    object-fit: cover;
    border-radius: 12px;
} */

.welcomeBox1__img{
    width: 100%;
    height: 198px;
    border-radius: 12px;
    overflow: hidden;
    transition: 0.6s ease-in-out;
}

.welcomeBox1__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    overflow: hidden;
    transition: 0.6s ease-in-out;
    cursor: pointer;
}

.welcomeBox1__img img:hover{
    transform: scale(1.1);
    transition: 0.6s ease-in-out;
}
.welcomeBoxTitle{
    color: #131319;
    font-size: 22px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.welcomeBoxPara{
    color: #131319;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Lora', serif;
}

.welcomeBox1{
    width: 300px;
    border-radius: 12px;
    background: #F2EDFF;
    box-shadow: 4px 6px 13px 0px rgba(215, 215, 215, 0.25);
}

.welcomeBox2{
    width: 300px;
    border-radius: 12px;
    background: #FFEDF8;
    box-shadow: 4px 6px 13px 0px rgba(215, 215, 215, 0.25);
}

.welcomeBox3{
    background: #EDF6FF;
    width: 300px;
    border-radius: 12px;
    box-shadow: 4px 6px 13px 0px rgba(215, 215, 215, 0.25);
}

.welcomeBox4{
    background: #F3FFED;
    width: 300px;
    border-radius: 12px;
    box-shadow: 4px 6px 13px 0px rgba(215, 215, 215, 0.25);
}

@media only screen and (max-width: 640px) {
    .welCome__title, .welCome__titleHaoda, .welCome__titlePay{
        font-size: 30px;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .welCome__title, .welCome__titleHaoda, .welCome__titlePay{
        font-size: 38px;
    }
}

/* Housing Society style */

.housingSociety__housing{
    width: 100%;
    background: #F4B936;
}

.housingSociety__housingTitle{
    color: #131319;
    font-size: 32px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.housingSociety__housingBtn{
    width: 174px;
    height: 56px;
    border-radius: 4px;
    background: #4E2AAD;
    box-shadow: 0px -2.6px 0px 0px #4E2AAD inset;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    border: none;
    outline: none;
    font-family: 'Poppins', sans-serif;
}


.housingSociety__housingContainer{
    position: relative;
}

.housingSociety__housingImg img{
    width: 271px;
    height: 201px;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    right: 10%;
}

@media only screen and (max-width:640px) {
    
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .housingSociety__housingImg img{
        right: 5%;
    }
}

@media (min-width: 1280px) and (max-width: 1535px) {
    .housingSociety__housingImg img{
        right: 12%;
    }
}

/* Payments section style */

.paymentsLeft img{
    width: 500px;
    height: 500px;
    object-fit: cover;
}

.paymentsLeft__content{
    border-radius: 14px;
    background: #EEE9FF;
}

.paymentsLeft__contentTitle{
    color: #131319;
    font-size: 24px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.paymentsRight__contentTitle{
    color: #131319;
    font-size: 22px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.paymentsRight__contentPara{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Lora', serif;
}

@media only screen and (max-width: 640px) {
    .paymentsLeft img{
        width: 300px;
        height: 300px;
        object-fit: cover;
    }
    .paymentsLeft__contentTitle{
        font-size: 18px;
    }
    .welCome__title {
        font-size: 26px;
    }
    .paymentsRight__contentTitle {
        font-size: 18px;
    }

    .welcomeBoxTitle {
        font-size: 20px;
    }
}

/* Benefit Section styel */

.housingBenefit__heading{
    color: #131319;
    font-size: 40px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.housingBenefit__title{
    color: #131319;
    font-size: 22px;
    font-weight: 500;
    width: 225px;
    font-family: 'Poppins', sans-serif;
}

.housingBenefit__headingHaoda{
    color: #392675;
    font-size: 40px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.housingBenefit__headingPay{
    color: #F4B936;
    font-size: 40px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.housingBenefit__para{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    width: 378px;
    font-family: 'Lora', serif;
}

.housingBenefit__img{
    width: 505px;
    height: 505px;
    border-radius: 14px;
    overflow: hidden;
    cursor: pointer;
}

.housingBenefit__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 14px;
    transition: 0.6s ease-in-out;
}

.housingBenefit__img img:hover{
    transform: scale(1.1);
    transition: 0.6s ease-in-out;
}

@media only screen and (max-width: 360px) {
    .housingBenefit__title{
        width: 100%;
        margin: 12px 0px;
    }
    .housingBenefit__para{
        width: 100%;
    }
    .housingBenefit__img{
        width: 100%;
        height: 100%;
    }
    .housingSociety__housingTitle{
        font-size: 22px;
    }
}

@media (min-width: 360px) and (max-width: 640px) {
    .housingBenefit__title{
        width: 100%;
        margin: 12px 0px;
    }
    .housingBenefit__para{
        width: 100%;
    }
    .housingBenefit__img{
        width: 355px;
        height: 355px;
    }
    .housingSociety__housingTitle{
        font-size: 22px;
    }
    .housingSociety__housingTitle{
        font-size: 26px;
    }


}

@media (min-width: 640px) and (max-width: 768px) {
    .housingBenefit__img {
        width: 355px;
        height: 355px;
    }
}

@media (min-width: 640px) and (max-width: 720px) {
    .housingBenefit__title{
        width: 150px;
        text-align: center;
    }
}

@media only screen and (max-width: 640px) {
    .housingBenefit__heading, .housingBenefit__headingHaoda, .housingBenefit__headingPay{
        font-size: 32px;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .housingBenefit__heading, .housingBenefit__headingHaoda, .housingBenefit__headingPay{
        font-size: 36px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .housingBenefit__para{
        width: 348px;
    }
    .housingBenefit__img{
        width: 405px;
        height: 405px;
    }    
}

@media (min-width: 768px) and (max-width: 1024px) {
    .housingBenefit__img{
        width: 405px;
        height: 405px;
    }
}


