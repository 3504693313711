@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.haodapay__certificateBanner{
    background-image: url(../../../public/images/Certificates\ Banner.png);
    background-position: center / cover;
    background-repeat: no-repeat;
    background-size: cover;
}

.certificateTitile{
    color: #FFF;
      font-size: 96px;
    font-weight: 500;
}

.certificate__content{
    width: 401px;
    border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(171, 190, 209, 0.40);
}

.certificate__content img{
    width: 100%;
    height: 300px;
    object-fit: contain;
}

.certificate__date{
    color: #6941C6;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.certificateContent__title{
    color: #1A1A1A;
    font-size: 22px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 640px) {
    .certificateTitile{
        font-size: 32px;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .certificateTitile{
        font-size: 36px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .certificateTitile{
        font-size: 48px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .certificateTitile{
        font-size: 70px;
    }
}