@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.privacy_head2 {
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
    font-weight: 500; 
    font-style: normal;
}

.privacy_para {
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    color: #1d2130;
}

.privacy_paraHead {
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    color: #1d2130;
}

.style__bgImage{
    /* background-image: url("..\src\Banner 1.jpg"); */
    width: 100%;
    height: 70vh;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
  }

  .privacy_head1 {
    font-family: "Poppins", sans-serif;
    font-weight:500;
    font-style: normal;
    font-size: 96px;
    color: #4e2aad;
  }

 @media all and (max-width:750px) {
    .privacy_head2 {
        font-size: 20px;
    }
 }

 @media all and (max-width: 770px) {
    .privacy_head1 {
        font-size: 68px;
    }
  }

  @media all and (max-width:450px) {
    .privacy_para {
        font-family: 'Lora', serif;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        color: #1d2130;
    }
    .privacy_head2 {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 500; 
        font-style: normal;
    }
}
@media all and (max-width: 770px) {
    .style__bgImage {
        width: 100%;
        height: 50vh;
    }
 }

 @media all and (max-width: 500px) {
    .style__bgImage {
        width: 100%;
        height: 40vh;
    }
 }
