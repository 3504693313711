.partner__bannerBg{
    position: relative;
    width: 100%;
    height: 650px;
}

.Partner_banner_bgImage{
    /* background-image: url(../../../public/images/term__bannerImg.png); */
    width: 100%;
    background-size: 100% 100%;
    background-position: cover;
    background-position: center;
  }

.Partner_banner_heading {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 56px;
    color: #000;
    line-height: 70px;
  }
  .Partner_body_heading {

        font-family: "Poppins", serif;
        font-weight:500;
        font-style: normal;
        font-size: 48px;
        color: #131319;
        line-height: 70px;
  }

.PartnerBanner_para_text{
    font-family: "Lora", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #000;
}

@media (min-width: 1023px) and (max-width: 1280px) {
    .partner__bannerBg{
        position: relative;
        width: 100%;
        height: 530px;
    }
}


@media (min-width: 767px) and (max-width: 1023px) {
    .partner__bannerBg{
        position: relative;
        width: 100%;
        height: 830px;
    }
}
@media  (min-width: 640px) and (max-width: 767px) {
    .partner__bannerBg{
        position: relative;
        height: 760px;
    }
    
}

@media  (min-width: 450px)  and (max-width: 640px) {
    .partner__bannerBg{
        position: relative;
        height: 800px;
    }
}

@media  only screen  and (max-width: 450px) {
    .partner__bannerBg{
        position: relative;
        height: 780px;
    }
}

@media only screen and (max-width: 768px) {
    .PartnerBanner_para_text{
        font-size: 14px;
    }
}

.Partner_para_text {
    font-family: "Lora", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #000;
  }

.partner_Banner_image {
    width: 405px;
    height: 405px;
    object-fit: cover;
}

.partner_bannerBtn button{
    width: 219px;
    height: 56px;
     font-family: "Poppins", serif;
      font-weight:500;
      font-size: 22px;
      color: #e4e4e7;
      border-radius: 4px;
      background: #4E2AAD;
}

.partner_bannerBtn button:hover{
    box-shadow: 0px -2.6px 0px 0px #4E2AAD inset;
}



.Partner_sub_heading {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 26px;
    color: #131319;
}

.Partner_sub_heading1 {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 26px;
    color: #131319;
}

.Partner_heading {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 48px;
    color: #131319;
}

.benefit_card_Oppertunity {
    background-color: #f2edff;
    width: 360px;
    height: 550px;
}

.benefit_card_integration {
    background-color: #ffedf8;
    width: 360px;
    height: 550px;
}

.benefit_card_solutions {
    background-color: #edf6ff;
    width: 360px;
    height: 550px;
}

.benefit_card_support {
    background-color: #f3ffed;
    width: 360px;
    height: 550px;
}

.Haoda_style {
    color: #392675;
}
.Pay_style {
    color: #F4B936;
}

.Company_success {
    width: 250px;
    height: 250px;
}

.Company_success:hover {
    border-radius: 3px;
background:  #FFF;
box-shadow: 0px 4px 8px 0px rgba(171, 190, 209, 0.40);
}

.Payment_method_img {
    width: 305px;
    height: 305px;
    object-fit: cover;
}

.Partner_haodapay {
    background: linear-gradient(54deg, #5F72BD 15.02%, #9B23EA 47.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.careerPage__applyNow{
    color: #131319;
    font-size: 22px;
    font-weight: 500;
}

.career__form input::placeholder{
    color: #B3B3B3;
    font-size: 16px;
    font-weight: 500;
}
.career__file{
    width: 100%;
    height: 180px;
    border-radius: 14px;
    border: 0.2px solid #4E2AAD;
    background: #F0F0F0;
}
.career__form input{
    width: 100%;
    height: 56px;
    border: none;
    outline: none;
    border-radius: 14px;
    background: #F0F0F0;
}

.careerPage__img img{
    width: 400px;
    height: 400px;
    object-fit: cover;
}

.content{
    display: none;
}

.careerForm__btn button{
    width: 198px;
    height: 69px;
    border-radius: 4px;
    background: #4E2AAD;
    color: #FFF;
    font-size: 25px;
    font-weight: 600;
}

.Partner_Testimonial_card {
    width: 550px;
    height: 250px;
}

.Partner_testinial_name {
    font-family: "Poppins", serif;
    font-weight:500;
    font-style: normal;
    font-size: 16px;
    color: #131319;
}

@media all and (max-width:1071px) {
    .partner_Banner_image {
        width: 100%;
        height: 35vh;
    }
    .Partner_banner_heading {
        font-family: "Poppins", serif;
        font-weight:500;
        font-style: normal;
        font-size: 50px;
        color: #000;
        line-height: 60px;
     }
}

@media all and (max-width:1023px) {
    .partner_Banner_image {
        width: 50%;
        height: 35vh;
    }
    .Partner_banner_heading {
        font-family: "Poppins", serif;
        font-weight:500;
        font-style: normal;
        font-size: 50px;
        color: #000;
        line-height: 60px;
     }
     .Banner_head {
        @apply px-20
     }
     .banner-text {
        @apply px-20
     }
     
}

@media all and (max-width:772px) {
    .Banner_head {
        @apply px-10
     }
     .partner_Banner_image {
        width: 70%;
        height: 40vh;
    }
    .Partner_body_heading {
        font-size: 40px;
  }
}

@media all and (max-width:679px) {
    .Banner_head {
        @apply px-5
     }
}

@media all and (max-width:639px) {
    .Banner_head {
        @apply px-0
     }
     .banner-text {
        @apply px-10
     }
     .Partner_banner_heading {
        font-family: "Poppins", serif;
        font-weight:500;
        font-style: normal;
        font-size: 45px;
        color: #000;
        line-height: 60px;
     }
} 
@media all and (max-width:617px) {
    .Partner_body_heading {
        font-family: "Poppins", serif;
        font-weight:500;
        font-style: normal;
        font-size: 36px;
        color: #131319;
        line-height: 55px;
  }
}

@media all and (max-width:547px) {
    .Partner_banner_heading{
        font-size: 35px;
        line-height: 45px;
    }
    .Partner_banner_bgImage {
        @apply px-3 py-5
    }
    .banner-text {
        @apply px-5
     }
     .partner_Banner_image {
        width: 70%;
        height: 35vh;
    }
    .Partner_sub_heading1 {
        font-size: 20px;
    }
}

@media all and (max-width:387px) {
    .Partner_banner_heading{
        font-size: 30px;
        line-height: 36px;
    }
    .partner_bannerBtn 
     {
        font-size: 16px;
        @apply w-48
    }
    .partner_Banner_image {
        width: 80%;
        height: 30vh;
    }
}

@media all and (max-width:1536px) {
    .benefit_card_Oppertunity {
        background-color: #f2edff;
        width: 410px;
        height: 550px;
    }
    
    .benefit_card_integration {
        background-color: #ffedf8;
        width: 410px;
        height: 550px;
    }
    
    .benefit_card_solutions {
        background-color: #edf6ff;
        width: 410px;
        height: 550px;
    }
    
    .benefit_card_support {
        background-color: #f3ffed;
        width: 410px;
        height: 550px;
    }
    .card {
        @apply px-20
    }
}

@media all and (max-width:1024px) {
    .card {
        @apply px-0
    }
    .benefit_card_Oppertunity {
        background-color: #f2edff;
        width: 300px;
        height: 450px;
    }
    
    .benefit_card_integration {
        background-color: #ffedf8;
        width: 300px;
        height: 450px;
    }
    
    .benefit_card_solutions {
        background-color: #edf6ff;
        width: 300px;
        height: 450px;
    }
    
    .benefit_card_support {
        background-color: #f3ffed;
        width: 300px;
        height: 450px;
    }
    .Partner_sub_heading {
        font-size: 20px;
    }
    .Partner_para_text {
        font-size: 14px;
    }
}
@media all and (max-width:632px) {
    .benefit_card_Oppertunity {
        background-color: #f2edff;
        width: 360px;
        height: 550px;
    }
    
    .benefit_card_integration {
        background-color: #ffedf8;
        width: 360px;
        height: 550px;
    }
    
    .benefit_card_solutions {
        background-color: #edf6ff;
        width: 360px;
        height: 550px;
    }
    
    .benefit_card_support {
        background-color: #f3ffed;
        width: 360px;
        height: 550px;
    }
    .Partner_sub_heading {
        font-size: 26px;
    }
    .Partner_para_text {
        font-size: 16px;
    }
}

@media all and (max-width:390px) {
    .card {
        @apply px-0
    }
    .benefit_card_Oppertunity {
        background-color: #f2edff;
        width: 300px;
        height: 450px;
    }
    
    .benefit_card_integration {
        background-color: #ffedf8;
        width: 300px;
        height: 450px;
    }
    
    .benefit_card_solutions {
        background-color: #edf6ff;
        width: 300px;
        height: 450px;
    }
    
    .benefit_card_support {
        background-color: #f3ffed;
        width: 300px;
        height: 450px;
    }
    .Partner_sub_heading {
        font-size: 20px;
    }
    .Partner_para_text {
        font-size: 14px;
    }
}
@media all and (max-width:320px) {
    .card {
        @apply px-0
    }
    .benefit_card_Oppertunity {
        background-color: #f2edff;
        width: 270px;
        height: 400px;
    }
    
    .benefit_card_integration {
        background-color: #ffedf8;
        width: 270px;
        height: 400px;
    }
    
    .benefit_card_solutions {
        background-color: #edf6ff;
        width: 270px;
        height: 400px;
    }
    
    .benefit_card_support {
        background-color: #f3ffed;
        width: 270px;
        height: 400px;
    }
    .Partner_sub_heading {
        font-size: 18px;
    }
    .Partner_para_text {
        font-size: 14px;
    }
    .Partner_heading {
        font-size: 26px;
        @apply text-center
    }
}

@media all and (max-width:770px) {
    .Partner_heading {
        font-size: 40px;
        @apply text-center
    }
}

@media all and (max-width:560px) {
    .Partner_heading {
        font-size: 30px;
        @apply text-center pb-5
    }
}

@media all and (max-width:1536px) {
    .comapny_width {
        @apply w-full
    }
}

@media all and (max-width:526px) {
    .Partner_body_heading {
        font-family: "Poppins", serif;
        font-weight:500;
        font-style: normal;
        font-size: 28px;
        color: #131319;
        line-height: 40px;
  }
}

@media all and (max-width:1280px) {
    .partmership_image {
        @apply w-2/4 pr-10 pl-0 flex justify-start
    }
    .partmership_text {
        @apply w-2/4 pl-0 pr-0
    }
}

@media all and (max-width:768px) {
    .Partnership_content {
        @apply block
    }
    .partmership_image {
        @apply w-full pr-0 flex justify-center order-2 pl-0
    }
    .partmership_text {
        @apply w-full pr-0 text-center pt-5 pl-0 order-1
    }
}


@media all and (max-width:1280px) {
    .partmership_text1 {
        @apply w-2/4 pl-0 flex justify-start
    }
    .partmership_image1 {
        @apply w-2/4 flex justify-end pl-0
    }
}

@media all and (max-width:768px) {
    .Partnership_content1 {
        @apply block
    }
    .partmership_image1 {
        @apply w-full pr-0 pl-0 flex justify-center  pt-5 order-1
    }
    .partmership_text1 {
        @apply w-full pr-0 text-center pl-0 flex order-2
    }
}

@media all and (max-width:579px) {
    .Company_success {
        width: 200px;
        height: 200px;
    }
}

@media only screen and (max-width: 360px) {
    .partner_Banner_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media (min-width: 360px) and (max-width: 640px) {
    .partner_Banner_image {
        width: 355px;
        height: 355px;
        object-fit: cover;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .partner_Banner_image {
        width: 355px;
        height: 355px;
        object-fit: cover;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .partner_Banner_image {
        width: 405px;
        height: 405px;
        object-fit: cover;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .partner_Banner_image {
        width: 405px;
        height: 405px;
        object-fit: cover;
    }
}