.newsBanner__bgImg{
    width: 100%;
    height: 80vh;
    /* background-image: url(../../../public/images/newsBanner__img.png); */
    background-size: 100% 100%;
    background-position: center / cover;
    background-repeat: no-repeat;
}

.newsLeft__container{
    border-right: 1px solid #131319;
}

.newsBanner__Title{
    color: #FFF;
    font-size: 96px;
    font-weight: 500;
}

.newsContent__img img{
    width: 364px;
    height: 239px;
    object-fit: cover;
}

.newContent__date{
    color: #6941C6;
    font-size: 14px;
    font-weight: 500;
}

.newContent__subTitle{
    color: #131319;
    font-size: 22px;
    font-weight: 500;
    width: 324px;
}

.newContent__subPara{
    color: #667085;
    font-size: 15px;
    font-weight: 400;
}

@media (min-width: 1280px) and (max-width: 1535px) {
    .newContent__subTitle{
        width: 284px;
    }
}

/* News right content style */

.newsRight__heading{
    color: #131319;
    font-size: 32px;
    font-weight: 500;
}

.newsright__img img{
    width: 149px;
    height: 172px;
    object-fit: cover;
}

.newContentRight__subTitle{
    color: #131319;
    font-size: 22px;
    font-weight: 500;
    width: 152px;
}