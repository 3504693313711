.UPIpayments__heading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 56px;
    font-weight: 500;
    line-height: 110%;
}

.UPIpayments__bodyText {
    color: #131319;
    font-family: 'Lora';
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
}

.UPIpayments__banner video{
    width: 100%;
    border: none;
}

.UPIpayments__bannerVideo{
    position: relative;
    height: 650px;
}

@media (min-width:768px) and (max-width:1023px) {
    .UPIpayments__bannerVideo{
        position: relative;
        height: 900px;
    }
    
}

@media (min-width:640px) and (max-width:768px) {
    .UPIpayments__bannerVideo{
        position: relative;
        height: 900px;
    }
}

@media (min-width:581px) and (max-width:640px) {
    .UPIpayments__bannerVideo{
        position: relative;
        height: 970px;
    }
}

@media all and (max-width:581px) {
    .UPIpayments__bannerVideo{
        position: relative;
        height: 880px;
    }
}


.UPIpayments__bannerVideo video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.UPIpayments__bannerTitle{
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.EducationBoy {
    overflow: hidden;
    border-radius: 14px;
    width: 505px;
    height: 505px;

}

.EducationBoy img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 1.6s ease-in-out;
    border-radius: 14px;
}

.EducationBoy img:hover {
    transition: 1.6s ease-in-out;
    transform:scale(1.2);
    cursor: pointer;
}


.UPIpayments__banner__background {
    padding: 0px 124px;
    gap: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width:1279px) and (max-width:1534px) {
    .UPIpayments__banner__background {
        padding: 0px 40px;
    }
    .UPIpayments__heading {
        margin-right: 0px;
        font-size: 46px;
    }
    .EducationBoy {
        height: 405px;
        width: 405px;
    }
    .UPIpayments__bodyText {
        margin-right: 0px;
    }
}

@media (min-width:1024px) and (max-width:1279px) {
    .UPIpayments__banner__background {
        padding: 0px 10px;
    }
    .UPIpayments__heading {
        margin-right: 0px;
        font-size: 46px;
    }
    .EducationBoy {
        height: 350px;
        width: 350px;
    }
    .UPIpayments__bodyText {
        font-size: 14px;
    }
    .UPIpayments__bannerVideo{
        position: relative;
        height: 500px;
    }
}

@media (min-width:640px) and (max-width:1023px) {
    .UPIpayments__banner__background {
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-self: center;
        justify-items: center;
    }
    .UPIpayments__bodyText {
        width: 630px;
        margin-right: 0px;
    }
    .UPIpayments__bannerVideo{
        position: relative;
        height: 1000px;
    }
    .UPIpayments__heading {
        margin-right: 0px;
        font-size: 52px;
    }
}

@media (min-width:375px) and (max-width:640px) {
    .UPIpayments__banner__background {
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-self: center;
        justify-items: center;
    }
    .UPIpayments__bodyText {
        width: 100%;
        margin-right: 0px;
        font-size: 14px;
    }
    .UPIpayments__bannerVideo{
        position: relative;
        height: 900px;
    }
    .EducationBoy {
        height: 350px;
        width: 350px;
    }
    .UPIpayments__heading {
        font-size: 40px;
    }
}

@media only screen and (max-width:375px) {
    .UPIpayments__banner__background {
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-self: center;
        justify-items: center;
    }
    .UPIpayments__bodyText {
        width: 100%;
        margin-right: 0px;
        font-size: 12px;
    }
    .UPIpayments__bannerVideo{
        position: relative;
        height: 750px;
    }
    .EducationBoy {
        height: 250px;
        width: 250px;
    }
    .UPIpayments__heading {
        font-size: 36px;
    }
    .contact__btn button {
        border-radius: 4px;
        background: #4E2AAD;
        color: #FFF;
        font-size: 10px;
        width: 190px;
    
    }
}






  /* UPI Payments2 */

.startedHaodapay__heading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: 500;
}

.startedHaodapay__page {
    padding: 0px 124px;
}

.startedHaodapay_img {
    width: 100%;
    height: 405px;
    border-radius: 14px;
}

.startedHaodapay__subHeading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 500;
}

.startedHaodapay__text {
    color: #131319;
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 400;
}

@media (min-width:1280px) and (max-width:1535px) {
    .startedHaodapay__page {
        padding: 0px 50px;
    }
}

@media (min-width:1024px) and (max-width:1280px) {
    .startedHaodapay__page {
        padding: 0px 20px;
    }
    .startedHaodapay__subHeading {
        font-size: 22px;
    }
    
    .startedHaodapay__text {
        font-size: 14px;
    }
    .startedHaodapay_img {
        height: 350px;
    }
    .startedHaodapay__heading {
        font-size: 30px;
    }
}

@media (min-width:768px) and (max-width:1023px) {
    .startedHaodapay__page {
        padding: 0px 50px;
    }
    .startedHaodapay_img {
        width: 50%;
        height: 405px;
    }
}

@media (min-width:640px) and (max-width:1023px) {
    .startedHaodapay__page {
        padding: 0px 50px;
    }
    .startedHaodapay_img {
        width: 100%;
        height: 380px;
        margin-top: 20px;
    }
}

@media all and (max-width:640px) {
    .startedHaodapay__page {
        padding: 0px 10px;
    }
    .startedHaodapay__subHeading {
        font-size: 18px;
    }
    
    .startedHaodapay__text {
        font-size: 14px;
    }
    .startedHaodapay_img {
        height: 250px;
        width: 100%;
    }
    .startedHaodapay__heading {
        font-size: 32px;
    }
}

.whyChoose__haodapay__bigHeading {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    width: 618px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 14px;
    background: #392675;
}

.whyChoose__haodapay_page {
    margin: 0px 124px;
}

.whyChooseHaodapay__Img img{
    width: 300px;
    height: 300px;
    object-fit: cover;
    margin-top: 40px;
}

.whyChoose__haodapay__subHeading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 500;
}

.whyChoose__haodapay__text {
    color: #131319;
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 400;
}

.whyChoose__haodapay__firsthalf {
    width: 620px;
}

.whyChoose__haodapay__secondhalf {
    width: 410px;
}

@media (min-width:1024px) and (max-width:1280px) {
    .whyChoose__haodapay__firsthalf {
        width: 60%;
    }
    
    .whyChoose__haodapay__secondhalf {
        width: 40%;
    }
    .whyChoose__haodapay__bigHeading { 
        font-size: 18px;
        width: 100%;
    }
    .whyChoose__haodapay_page {
        margin: 0px 10px;
    }
}

@media (min-width:768px) and (max-width:1024px) {
    .whyChoose__haodapay__firsthalf {
        width: 100%;
    }
    
    .whyChoose__haodapay__secondhalf {
        width: 70%;
    }
    .whyChoose__haodapay__bigHeading { 
        font-size: 20px;
        width: 618px;
    }
    .whyChoose__haodapay_page {
        margin: 0px 50px;
    }
}

@media (min-width:639px) and (max-width:768px) {
    .whyChoose__haodapay__firsthalf {
        width: 100%;
    }
    
    .whyChoose__haodapay__secondhalf {
        width: 80%;
        display: flex;
        justify-content: center;
    }
    .whyChoose__haodapay__bigHeading { 
        font-size: 20px;
        width: 618px;
    }
    .whyChoose__haodapay_page {
        margin: 0px 20px;
    }
}

@media (min-width:410px) and (max-width:639px) {
    .whyChoose__haodapay__secondhalf {
        display: flex;
        justify-self: center;
    }
    .whyChoose__haodapay__bigHeading {
        font-size: 18px;
        width: 400px;
    }
    .whyChoose__haodapay__firsthalf {
        width: 100%;
    }
}

@media all and (max-width:410px) {
    .whyChoose__haodapay__firsthalf {
        width: 100%;
    }
    
    .whyChoose__haodapay__secondhalf {
        width: 100%;
        display: flex;
        justify-self: center;
    }
    .whyChoose__haodapay__bigHeading { 
        width: 100%;
        font-size: 16px;
        margin: 0px 10px;
    }
    .whyChoose__haodapay_page {
        margin: 0px 10px;
    }
    .whyChoose__haodapay__subHeading {
        color: #131319;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 500;
    }
    
    .whyChoose__haodapay__text {
        color: #131319;
        font-family: 'Lora', serif;
        font-size: 14px;
        font-weight: 400;
    }
}

.joinHaodapay__revoluiton__bgColor {
    background: linear-gradient(180deg, #FFF 0%, #FFFBAB 100%);
}

.joinHaodapay__revoluiton__page {
    margin: 0px 124px;
    position: relative;
}

.joinHaodapay__revoluiton__heading {
    color: #131319;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: 500;
}

.joinHaodapay__revoluiton__text {
    color: #131319;
    font-family: 'Lora', serif;
    font-size: 16px;
    width: 711px;
    font-weight: 400;
}

.joinHaodapay__revoluiton__img {
    position: absolute;
    bottom: 0%;
}
.joinHaodapay__revoluiton img {
    width: 250px;
}
/* 
.joinHaodapay__revoluiton {
    width: 100px;
} */

.joinHaodapay__revoluiton__btn {
    display: flex;
    padding: 16px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius:  4px;
    background: #4E2AAD;
    box-shadow: 0px -2.6px 0px 0px #4E2AAD inset;
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}


.joinHaodapay__revoluiton__page1 {
    width: 791px;
}
.joinHaodapay__revoluiton__page2 {
    width: 360px;
}
.joinHaodapay__revoluiton__img {
    bottom: 0%;
}

@media (min-width:1023px) and (max-width:1280px) {
    .joinHaodapay__revoluiton__page1 {
        width: 700px;
    }
    .joinHaodapay__revoluiton__page2 {
        width: 320px;
    }
    .joinHaodapay__revoluiton__heading {
        font-size: 26px;
    }
    .joinHaodapay__revoluiton__btn {
        display: flex;
        padding: 16px 40px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius:  4px;
        background: #4E2AAD;
        box-shadow: 0px -2.6px 0px 0px #4E2AAD inset;
        color: #FFF;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
    }
    .joinHaodapay__revoluiton {
        width: 250px;
    }
    .joinHaodapay__revoluiton__page {
        margin: 0px 20px;
    }
}

@media (min-width:768px) and (max-width:1023px) {
    .joinHaodapay__revoluiton__page {
        margin: 0px 20px;
    }
    .joinHaodapay__revoluiton__heading {
        font-size: 30px;
        text-align: center;
    }
    .joinHaodapay__revoluiton__page1 {
        width: 700px;
    }
    .joinHaodapay__revoluiton__text {
        text-align: center;
    }
}

@media (min-width:640px) and (max-width:768px) {
    .joinHaodapay__revoluiton__page {
        margin: 0px 10px;
    }
    .joinHaodapay__revoluiton__heading {
        font-size: 30px;
        text-align: center;
    }
    .joinHaodapay__revoluiton__page1 {
        width: 100%;
    }
    .joinHaodapay__revoluiton__text {
        width: 100%;
    }
}

@media (min-width:200px) and (max-width:640px) {
    .joinHaodapay__revoluiton__page {
        margin: 0px 10px;
    }
    .joinHaodapay__revoluiton__heading {
        font-size: 22px;
        text-align: center;
    }
    .joinHaodapay__revoluiton__page1 {
        width: 100%;
    }
    .joinHaodapay__revoluiton__page2 {
        width: 100%;
    }
    .joinHaodapay__revoluiton__btn {
        font-size: 10px;
        padding: 12px 30px;
    }
    .joinHaodapay__revoluiton__text {
        width: 100%;
    }
}
.create__paymentLink__btn {
    overflow: hidden;
}
.create__paymentLink__btn:hover {
    box-shadow: 0px -2.6px 0px 0px #4E2AAD inset;
    .right__arrow {
        animation-duration: 0.5s;
        animation-timing-function: ease-in , ease-out;
        animation-delay: 0s;
        animation-name: right;
        overflow: hidden;
    }
    .contact__btn button {
        font-size: 14px;
    }
}


.joinHaodapay__revoluiton__btn:hover {
    box-shadow: 0px -2.6px 0px 0px #4E2AAD inset;
    overflow: hidden;
    .right__arrow {
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        animation-delay: 0s;
        animation-name: right;
        /* overflow: hidden; */
    }
}

@keyframes right {
    0%{
        transform: translateX(0px);
    }
    30%{
       transform: translateX(20px);
       display: none;
    }
    60%{
        transform: translateX(-15px);
        display: none;
    }
    100%{
        transform: translateX(0px);
    }
} 

.Join__btn button{
    border-radius: 4px;
    background: #4E2AAD;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    font-family: 'poppins', sans-serif;
}

.Join__btn button:hover{
    box-shadow: 0px -2.6px 0px 0px #210077 inset; 
}


.join__arrow{
    width: 30px;
    height: 28px;
    position: relative;
    overflow: hidden;
}

.right__arrow {
    width: 20px;
    display: block;
    font-size: 22px;
    font-family: 'poppins', sans-serif;
}

.right__arrow{
    display: block;
    font-size: 12px;
    position: absolute;
}

.Join__btn button:hover .right__arrow{
    animation: Arrow 0.6s ease;
}

@keyframes Arrow {
    0%{
        transform: translateX(0px);
    }
    30%{
       transform: translateX(20px);
       display: none;
    }
    60%{
        transform: translateX(-15px);
        display: none;
    }
    100%{
        transform: translateX(0px);
    }
}



.contact__btn button{
    border-radius: 4px;
    background: #4E2AAD;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    font-family: 'poppins', sans-serif;

}

.contact__btn button:hover{
    box-shadow: 0px -2.6px 0px 0px #210077 inset; 
}


.arrow__contact{
    width: 30px;
    height: 28px;
    position: relative;
    overflow: hidden;
}

.move__arrow {
    width: 20px;
    display: block;
    font-size: 14px;
}

.move__arrow{
    display: block;
    font-size: 14px;
    position: absolute;
}

.contact__btn button:hover .move__arrow{
    animation: Arrow 0.6s ease;
}

@keyframes Arrow {
    0%{
        transform: translateX(0px);
    }
    30%{
       transform: translateX(20px);
       display: none;
    }
    60%{
        transform: translateX(-15px);
        display: none;
    }
    100%{
        transform: translateX(0px);
    }
}