.footer__title{
    color: #131319;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.footer__link{
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Lora', serif;
}

.footer_para {
    color: #131319;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Lora', serif;
}

.footer__link:hover{
    width: fit-content;
    text-decoration: underline;
    text-decoration-color: #210077;
}

.footer__email{
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background: #F7F7F7;
}

.footer__email input{
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    outline: none;
}

.footer__btn{
    width: 160px;
    height: 40px;
    border-radius: 4px;
    background: #4E2AAD;
    box-shadow: 0px -2.4px 0px 0px #210077 inset;
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
}

.footer__logo img{
    width: 184px;
    height: 35px;
    object-fit: cover;
}

.footer__line{
    width: 30%;
    height: 1px;
    background: #131319;
}



.FaYoutube{
    color: #FF0000;
    font-size: 24px;
}

.secureImg img{
    width: 220px;
    height: 54px;
    object-fit: cover;
}

.secureImg__container{
    border-top: 1px solid #dedbdb;
}

.footerImg__container__iso1 img{
    width: 84px;
    height: 54px;
    object-fit: cover;
}

.footerImg__container__iso2 img{
    width: 94px;
    height: 54px;
    object-fit: cover;
}

.footerImg__container__iso img{
    width: 54px;
    height: 54px;
    object-fit: cover;
}

.footerImg__container__iso27001 img{
    width: 94px;
    height: 64px;
    object-fit: cover;
}

.footerImg__container__start img{
    width: 205px;
    height: 54px;
    object-fit: cover;
}

.footerImg__container__digital img{
    width: 124px;
    height: 44px;
    object-fit: contain;
}

@media only screen and (max-width:550px) {
    .footer__line{
        width: 0%;
    }
    .footerRights{
        font-size: 16px;
    }
}

@media (min-width: 550px) and (max-width: 640px) {
    .footer__line{
        width: 12%;
    }
    .footerRights{
        font-size: 12px;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .footer__line{
        width: 12%;
    }
    .footerRights{
        font-size: 14px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .footer__line{
        width: 14%;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .footer__line{
        width: 23%;
    }
}

@media (min-width: 1280px) and (max-width: 1535px) {
    .footer__line{
        width: 27%;
    }
}
