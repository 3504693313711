@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.term__container{
    /* background-image: url(../../../public/images/term__bannerImg.png); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.term__heading{
    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-size: 96px;
    font-weight: 500;
}

@media only screen and (max-width: 640px) {
    .term__heading{
        font-size: 32px;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .term__heading{
        font-size: 36px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .term__heading{
        font-size: 48px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .term__heading{
        font-size: 70px;
    }
}

.term__section{
    color: #1D2130;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-weight: 600;
}

.termContent__title{
    color: #1D2130;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-weight: 500;
}

.term__content{
    height: 100%;
    top: 40px;
}

@media only screen and (max-width: 1024px) {
    .term__content{
        position: relative;
    }
}

@media only screen and (max-width: 640px) {
    .term__title {
        font-size: 14px;
    }
    .term__title li {
        font-size: 14px;
    }
    .ternContent__para li {
        font-size: 14px;
    }
    .ternContent__right {
        font-size: 14px;
    }
}


.term__title{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.term__title li{
    color:#1D2130;
    font-family: "Lora", serif;
    font-size: 16px;
    font-weight: 400;
    list-style: disc;
}

.ternContent__para{
    list-style: disc;
}

.ternContent__para li{
    color: #1D2130;
    font-family: "Lora", serif;
    font-size: 16px;
    font-weight: 400;
}

.term__para {
    color: #1D2130;
    font-family: "Lora", serif;
    font-size: 16px;
    font-weight: 400;
}

.ternContent__right{
    color: #1D2130;
    font-family: "Lora", serif;
    font-size: 16px;
    font-weight: 600;
}